import { getClientDetails } from 'helpers/auth';
import get from 'lodash/get';
import { Dispatch } from 'redux';
import { doClientDetailsBelongToFanAIUser } from 'utils/user';
import { ANALYTICS } from '../constants';
import { analyticsKeys, analyticsTypes } from '../constants/analytics';
import { LOGIN_USER_SUCCESS_SET_ANALYTICS } from './types';

const {
  CLIENT_ID,
  CLIENT_NAME,
  CLIENT_TYPE,
  IS_EMPLOYEE,
  USER_ID,
} = analyticsKeys;

const { CUSTOM_EVENT } = analyticsTypes;

export const track = (data: any, eventType: string = CUSTOM_EVENT) => (
  dispatch: Dispatch<any>,
): void => {
  dispatch({
    payload: {
      eventType,
      ...data,
    },
    type: ANALYTICS,
  });
};

/**
 *
 * Custom Dimension Mapping values:
 * clientName = dimension 1
 * clientType = dimension 2
 * isEmployee = dimension 3
 * userId = dimension 4
 * clientId = dimension 5
 *
 * For more information: https://app.nuclino.com/FanAI/Tech/Analytics-5e983b4e-18e4-4c2b-92f1-7957dd134838
 */
export const loginUserSuccessSetAnalytics = user => {
  return (dispatch: Dispatch<any>) => {
    // Since the user has logged in successfully, we want to update google analytics.
    // We need to gather the users client data from firebase
    return new Promise(resolve => {
      getClientDetails(user.uid)
        .then(clientDetails => {
          dispatch({
            payload: {
              eventAction: LOGIN_USER_SUCCESS_SET_ANALYTICS,
              eventData: {
                [CLIENT_NAME]: get(clientDetails, ['name']),
                [CLIENT_TYPE]: get(clientDetails, ['type']),
                [IS_EMPLOYEE]: doClientDetailsBelongToFanAIUser(
                  clientDetails,
                ).toString(),
                [USER_ID]: user.uid,
                [CLIENT_ID]: get(clientDetails, ['entityId']),
              },
              eventType: CUSTOM_EVENT,
            },
            type: ANALYTICS,
          });
        })
        .then(resolve);
    });
  };
};
