import React, { useCallback, useEffect, useMemo } from 'react'
import isValid from 'date-fns/isValid'
import startOfYear from 'date-fns/startOfYear'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import toDate from 'date-fns/toDate'
import { startOfDayUTC, startOfTodayUTC, subTzOffset } from 'utils/time'
import { Nullable, PresetDateRange } from 'types'
import { useHistory, useLocation } from 'react-router'

type Return = [Date, Date, (start: Date, end: Date) => void]

function getStartDate(endDate: Date, timeLength: PresetDateRange) {
  switch (timeLength) {
    case 'day':
      return endDate
    case 'week':
      return subDays(endDate, 7)
    case 'two-weeks':
      return subDays(endDate, 14)
    case 'month':
      return subMonths(endDate, 1)
    case 'sixty-days':
      return subDays(endDate, 60)
    case 'quarter':
      return subMonths(endDate, 3)
    case 'year':
      return subMonths(endDate, 12)
    case 'ytd':
      return subTzOffset(startOfYear(endDate))
    default:
      return subMonths(endDate, 1)
  }
}

function buildStorageKey(pathname: string) {
  return `${pathname}:date-range`
}

/**
 * Loads the preferred date range for a given page
 *
 * This function will attempt to load a preset date range from sessionStorage
 * before falling back to localStorage.
 *
 * @param {string} pathname - The pathname of the current window location
 * @returns {PresetDateRange|null} - The stored preset date range, if any
 */
function getStoredDateRange(pathname: string): Nullable<PresetDateRange> {
  const key = buildStorageKey(pathname)
  let item = window.sessionStorage.getItem(key)

  if (!item) {
    item = window.localStorage.getItem(key)
  }
  return item as Nullable<PresetDateRange>
}
/**
 * Loads the preferred exact dates for a give page
 *
 * This function will attempt to load the preferred exact dates from sessionStorage
 * before falling back to localStorage.
 *
 * @param {string} pathname - The pathname of the current window location
 * @returns [string,string] - The stored start and end dates
 */
function getStoredDates(pathname: string) {
  const key = buildStorageKey(pathname)
  let startDate = window.sessionStorage.getItem(`${pathname}:startDate`)
  let endDate = window.sessionStorage.getItem(`${pathname}:endDate`)

  if (!startDate || !endDate) {
    startDate = window.localStorage.getItem(`${pathname}:startDate`)
    endDate = window.localStorage.getItem(`${pathname}:endDate`)
  }

  return [startDate, endDate]
}

/**
 * Stores the preferred date range for a given page
 *
 * This function will store a preferred date range for a given page within
 * both sessionStorage and localStorage.
 *
 * @param {string} pathname - The pathname of the current window location
 * @param {PresetDateRange|null} presetRange - The range to store
 */
function storeDateRange(
  pathname: string,
  presetRange: Nullable<PresetDateRange>
): void {
  const key = buildStorageKey(pathname)

  if (presetRange) {
    window.sessionStorage.setItem(key, presetRange)
    window.localStorage.setItem(key, presetRange)
  }
}

/**
 * Stores the exact dates for a given page
 *
 * This function will store the exact dates for a given page within
 * both sessionStorage and localStorage.
 *
 * @param {string} pathname - The pathname of the current window location
 * @param {Date} startDate
 * @param {Date} endDate
 */
function storeExactDates(
  pathname: string,
  startDate: Date,
  endDate: Date
): void {
  if (startDate && endDate) {
    window.sessionStorage.setItem(
      `${pathname}:startDate`,
      `${startDate.getTime()}`
    )
    window.sessionStorage.setItem(`${pathname}:endDate`, `${endDate.getTime()}`)
  }
}

/**
 * Syncs a given date range with the URL search params
 *
 * This hook attempts to use the start_date and end_date params from the
 * window's current search params. If the params are missing or invalid,
 * the hook will instead set the params using a start date based on the
 * given "defaultRange" argument and an end date of today.
 *
 * @param {PresetDateRange} defaultRange - The default duration
 * @return {Return} - The current date range and an onChange callback
 *
 * @example
 * const [startDate, endDate, onChangeRange] = useDateRangeFromToday('year');
 */
export default function useDateRangeFromToday(
  defaultRange: PresetDateRange
): Return {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const params = new URLSearchParams(search)

  const startDateText = params.get('start_date')
  const endDateText = params.get('end_date')

  // Extracts the current start_date and end_date URL params
  const [startDate, endDate] = useMemo(() => {
    let startDate = new Date(parseInt(startDateText))
    let endDate = new Date(parseInt(endDateText))

    if (!isValid(startDate) || !isValid(endDate)) {
      const [storedStartDate, storedEndDate] = getStoredDates(pathname)
      if (storedStartDate && storedEndDate) {
        startDate = new Date(+storedStartDate)
        endDate = new Date(+storedEndDate)
      } else {
        endDate = startOfTodayUTC()
        const dateRange = getStoredDateRange(pathname) || defaultRange
        startDate = getStartDate(endDate, dateRange)
      }
    }

    return [startDate, endDate]
  }, [startDateText, endDateText, pathname, defaultRange])

  // Set the current start_date and end_date URL params
  useEffect(() => {
    const params = new URLSearchParams(search)
    params.set('start_date', `${startDate.getTime()}`)
    params.set('end_date', `${endDate.getTime()}`)
    history.replace({
      search: params.toString()
    })
  }, [history, search, startDate, endDate])

  // Updates the start_date and end_date URL params
  const onChange = useCallback(
    (start: Date, end: Date, presetRange?: PresetDateRange) => {
      const newStartDate = startOfDayUTC(start)
      const newEndDate = startOfDayUTC(end)
      params.set('start_date', `${newStartDate.getTime()}`)
      params.set('end_date', `${newEndDate.getTime()}`)
      history.push({
        search: params.toString()
      })

      if (presetRange) {
        storeDateRange(pathname, presetRange)
      } else {
        storeExactDates(pathname, newStartDate, newEndDate)
      }
    },

    [history, params, pathname]
  )

  return [startDate, endDate, onChange]
}
