import appConfig from 'config/appConfig';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

export const ascendingSortFn = (a, b) => (a < b ? -1 : 1);
export const descendingSortFn = (a, b) => (a > b ? -1 : 1);

export const orderFilters = (filters = [], order: string[]) => {
  return filters.length
    ? order.map(key => {
        return filters.find(f => f.name === key).name;
      })
    : [];
};

export const sortAlphabetically = (propA: string, propB?: string) => (a, b) => {
  const aValue = get(a, propA).toLowerCase();
  const bValue = get(b, propB || propA).toLowerCase();

  if (aValue && bValue) {
    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
  }
  return a < b ? -1 : a > b ? 1 : 0;
};
/**
 * function used to destructure key/values from the twitter objects and return the values we want in app state
 *
 * @param twitterArr
 */
const removeTwitter = twitterArr =>
  twitterArr.map(
    ({
      followers_count,
      screen_name,
      handle,
      followers,
      profile_image_url,
      ...rest
    }) => ({
      followers_count,
      followers,
      handle,
      profile_image_url,
      screen_name,
    }),
  );

export const sortEntityList = (list, precedenceRules, key, category) => {
  // remove unused data from each entity
  const newList = (list || []).map(item => {
    // remove unused values
    delete item.created_at;
    delete item.updated_at;
    const twitter = get(item, ['twitter'], []);
    if (twitter.length) {
      // removes some un-used twitter values, not the twitter object entirely
      item.twitter = removeTwitter(item.twitter);

      // add twitter handles to aliases (for tooltip)
      const handles = twitter.map(x => '@' + x.handle);
      handles.forEach(x => item.aliases.unshift(x));
    }

    const relatedTypes = get(item, ['related_types', 'outbound'], []).map(
      x => x + 's',
    );

    // add related paths
    if (
      relatedTypes.length &&
      category &&
      (category === 'games' || category === 'roster_owners')
    ) {
      const structure = cloneDeep(
        get(appConfig, ['entityTypes', category, 'structure']),
      ).map(x => x.type);
      const remainingTypes = structure.filter(x => relatedTypes.includes(x));
      // if 'roster_members' change to 'roster members'
      if (remainingTypes.includes('roster_members')) {
        const index = remainingTypes.findIndex(x => x === 'roster_members');
        remainingTypes[index] = 'roster members';
      }
      item.jumpToPaths = remainingTypes;
    }
    return item;
  });

  return newList;
};

export const sortByDate = (
  array: any[],
  keyPath: string[],
  sortOrder: string = 'desc',
) => {
  if (sortOrder !== 'asc' && sortOrder !== 'desc') {
    return array;
  }

  return array.sort((a, b) => {
    let aValue = a;
    let bValue = b;

    if (keyPath) {
      aValue = get(a, keyPath, 0);
      bValue = get(b, keyPath, 0);
    }

    const aComparison =
      new Date(aValue).toString() !== 'Invalid Date'
        ? new Date(aValue)
        : new Date(0);
    const bComparison =
      new Date(bValue).toString() !== 'Invalid Date'
        ? new Date(bValue)
        : new Date(0);

    if (sortOrder === 'asc') {
      return aComparison.getTime() < bComparison.getTime()
        ? -1
        : aComparison.getTime() > bComparison.getTime()
        ? 1
        : 0;
    } else {
      return aComparison.getTime() > bComparison.getTime()
        ? -1
        : aComparison.getTime() < bComparison.getTime()
        ? 1
        : 0;
    }
  });
};

/**
 * utility function used to take an array of items, and return a new array based off the current page number and number of items per page.
 *
 * @param {*} items
 * @param {*} currentPage
 * @param {*} perPage
 */
export const getPaginatedItems = (items, currentPage, perPage) =>
  items && items.length
    ? items.slice(
        (currentPage - 1) * perPage,
        (currentPage - 1) * perPage + perPage,
      )
    : [];
