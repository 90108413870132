import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EventEmitter } from 'events';

/**
 * Configures axios to use an existing, pending response for duplicate requests
 *
 * This function configures axios interceptors that prevent outgoing requests
 * if a previous, duplicate outgoing request is still pending.
 *
 * @param {AxiosInstance} axios - An axios network client
 */
export function interceptPendingRequests(axios: AxiosInstance): void {
  class ResponseEmitter extends EventEmitter {}
  const responseEmitter = new ResponseEmitter();

  // Increase the max listener count from a default of 10 to prevent
  // a "potential memory leak" warning in the console.
  responseEmitter.setMaxListeners(1000);

  const pendingRequests: Record<string, boolean> = {};

  // Builds a unique key based on each request config
  const buildKey = (config: AxiosRequestConfig) => {
    const { method, url, params } = config;
    let paramsArray = sortBy(toPairs(params), '[0]').join(';');
    return `${method} ${url} ${paramsArray}`;
  };

  // Subscribes to a pending response, if available
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const method = config.method.toLowerCase();
    const isCacheable = method === 'get';
    if (isCacheable) {
      const key = buildKey(config);
      const hasNoPendingRequest = pendingRequests[key] === undefined;
      if (hasNoPendingRequest) {
        pendingRequests[key] = true;
      } else {
        config.adapter = () =>
          new Promise((resolve, reject) => {
            responseEmitter.once(key, (err, res) => {
              if (err) {
                return reject(err);
              } else {
                return resolve(res);
              }
            });
          });
      }
    } else {
      const needToClear = ['delete', 'patch', 'post', 'put'].includes(method);
      if (needToClear) {
        const { url } = config;
        const regex = new RegExp(`^${method} ${url} .*`);
        const keys = Object.keys(pendingRequests);
        const keysToRemove = keys.filter(key => key.match(regex));
        keysToRemove.forEach(key => delete pendingRequests[key]);
      }
    }
    return config;
  }, Promise.reject);

  // Emits an event for each response before forwarding the response
  axios.interceptors.response.use(
    (res: AxiosResponse) => {
      const err = null;
      const key = buildKey(res?.config);
      delete pendingRequests[key];
      responseEmitter.emit(key, err, res);
      return res;
    },
    (err: any) => {
      const res = null;
      const key = buildKey(err?.config);
      delete pendingRequests[key];
      responseEmitter.emit(key, err, res);
      return Promise.reject(err);
    },
  );
}
