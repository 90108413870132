import API from 'config/api';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { Nullable, PropertyTracking, TaskState } from 'types';

type Return = [
  Nullable<PropertyTracking>,
  TaskState,
  (tracking: PropertyTracking) => void,
  () => void,
];

/**
 * Requests and manages state for a single property tracking
 *
 * @params {string} id - A property tracking ID
 * @return {Return} - A tracking, load state, setter and refresh function
 *
 * @example
 * const [tracking, loadState, setter, refresh] = usePropertyTracking(id);
 */
export default function usePropertyTracking(id: string): Return {
  const [propertyTracking, setPropertyTracking] = useState<
    Nullable<PropertyTracking>
  >(undefined);
  const [taskState, setTaskState] = useState<TaskState>(TaskState.Initial);
  const load = useCallback(() => {
    (async () => {
      try {
        if (id) {
          setTaskState(TaskState.Pending);
          const res = await axios.get(`${API.piiPropertyTrackings}/${id}`);
          setPropertyTracking(res?.data?.data);
          setTaskState(TaskState.Success);
        }
      } catch (e) {
        setTaskState(TaskState.Error);
      }
    })();
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  return [propertyTracking, taskState, setPropertyTracking, load];
}
