import Button from 'deprecated/atoms/button';
import classNames from 'utils/classNames';
import Icon from 'atoms/icon';
import noop from 'utils/noop';
import PulseLoader from 'atoms/pulseLoader';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { analyticsTypes } from '../../../../constants/analytics';
import { AnimatePresence, motion } from 'framer-motion';
import { ModalPropsInterface } from './index.d';
import { withGoogleAnalyticsMount } from 'components/hocs';
import './modal.css';

const { MODAL_VIEW } = analyticsTypes;

const defaultRoot = document.getElementById('modal-root');

// Every time a modal is shown, we send to Google Analytics the current pathname
// per: https://app.nuclino.com/FanAI/Tech/Tech-Spec-Analytics-9e2a51bc-96ae-46f1-ac3b-735ecff4e93b
const TrackedMotionDiv = withGoogleAnalyticsMount(motion.div, MODAL_VIEW);

/**
 * @deprecated
 */
const Modal = (props: ModalPropsInterface) => {
  const el = useRef(null);
  const {
    cancelCallback,
    cancelLabel,
    children,
    className,
    ctaChildren,
    ctaContainerStyle = {},
    description,
    fullScreen = true,
    headerIcon,
    initializing,
    outsideClickCallback,
    root,
    show,
    successButtonLoading,
    successCallback,
    successDisabled,
    successLabel,
    title,
    titleStyle = {},
    topImage,
    type,
  } = props;
  const [isShown, setModalIsShown] = useState(show);
  const [loading, setLoading] = useState(false);
  const [container, setContainer] = useState(el.current);

  useEffect(() => {
    if (show) {
      setModalIsShown(true);
    } else {
      setModalIsShown(false);
    }
  }, [show]);

  const variants = {
    exit: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
    visible: {
      display: 'grid',
      opacity: 1,
    },
  };

  const modalBodyVariants = {
    exit: {
      opacity: 0,
      y: 30,
    },
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  useEffect(() => {
    if (fullScreen && !el.current) {
      el.current = document.createElement('div');
      setContainer(el.current);
      (root || defaultRoot).appendChild(el.current);

      return () => {
        (root || defaultRoot).removeChild(el.current);
        el.current = null;
      };
    }
    // eslint-disable-next-line
  }, []);

  /**
   *  If outsideClickCallback is passed, when the user clicks outside the modal, this callback will be called.
   * If not passed, we default to cancelCallback.
   * Of no cancelCallback, we fallback to just closing the modal.
   *
   * @param {*} event
   */
  const handleMouseClick = event => {
    if (
      event.target &&
      event.target.className &&
      event.target.className === 'fullScreen modalContainer' &&
      isShown
    ) {
      setLoading(false);
      setModalIsShown(false);

      if (outsideClickCallback) {
        outsideClickCallback();
      }

      if (!outsideClickCallback && cancelCallback) {
        cancelCallback();
      }
    }
  };
  const handleClose = (cb: () => any) => () => {
    Promise.resolve()
      .then(() => {
        setLoading(true);
        setModalIsShown(true);
        return cb ? cb() : noop();
      })
      .then(() => {
        // trigger closing animation
        setLoading(false);
        setModalIsShown(false);
      })
      .catch(() => {
        // in case callback rejects
        setLoading(false);
      });
  };

  const handleKeyPress = e => {
    if (e.which === 13) {
      handleClose(successCallback);
    }
  };

  const renderModal = () => {
    return (
      <AnimatePresence>
        {isShown && (
          <TrackedMotionDiv
            key={'modal'}
            initial="hidden"
            animate={isShown ? 'visible' : 'hidden'}
            exit="exit"
            variants={variants}
            className={`${classNames({
              fullScreen,
              modalContainer: true,
              [className]: true,
            })}`}
            onKeyPress={handleKeyPress}
            onClick={e => handleMouseClick(e)}
          >
            <motion.div
              variants={modalBodyVariants}
              transition={{
                delay: isShown ? 0 : 0.2,
                y: { type: 'spring', stiffness: 300, damping: 200 },
              }}
              className="modalBod"
            >
              {loading || initializing ? (
                <PulseLoader
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              ) : null}
              {topImage && topImage}
              {title && (
                <header
                  style={{
                    gridTemplateColumns: headerIcon
                      ? `min-content 1fr`
                      : 'null',
                  }}
                >
                  {headerIcon && <Icon glyph={headerIcon} />}
                  <h2 style={{ gridColumn: headerIcon ? 2 : 1, ...titleStyle }}>
                    {title}
                  </h2>
                  {description && (
                    <p className="modalDescription">{description}</p>
                  )}
                </header>
              )}
              <div className="modalContent">{children}</div>
              <div
                className="ctas"
                style={{
                  gridTemplateColumns:
                    (ctaChildren || cancelCallback) && successCallback
                      ? '1fr auto'
                      : 'auto',
                  marginTop: cancelCallback || successCallback ? 24 : 0,
                  ...ctaContainerStyle,
                }}
              >
                {ctaChildren && ctaChildren}
                {cancelCallback && (
                  <Button
                    className={classNames({
                      secondary: true,
                    })}
                    label={cancelLabel || 'Cancel'}
                    onClick={handleClose(cancelCallback)}
                  />
                )}
                {successCallback && (
                  <Button
                    className={classNames({
                      primary: true,
                      warning: type,
                    })}
                    loading={successButtonLoading || false}
                    label={successLabel || 'Ok'}
                    onClick={handleClose(successCallback)}
                    disabled={
                      successDisabled || successButtonLoading || loading
                    }
                  />
                )}
              </div>
            </motion.div>
          </TrackedMotionDiv>
        )}
      </AnimatePresence>
    );
  };

  return fullScreen
    ? container
      ? ReactDOM.createPortal(renderModal(), container)
      : null
    : renderModal();
};

export default Modal;
