import * as React from 'react'
import { Entity } from 'types'

interface GlobalContextInterface {
  contentRef?: any
  brand?: Entity
  brands?: Entity[]
}

const defaultContext: GlobalContextInterface = {
  contentRef: { current: null }
}

const GlobalContext = React.createContext(defaultContext)

export default GlobalContext
