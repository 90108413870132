import StringUtility from 'utils/stringReplacement';

const copy = new StringUtility({
  errors: {
    cases: [408, 404, 500, 503, 401, 504],
    defaultValue: {
      description:
        'This is unexpected and we’ve logged the error. We’re looking into it, but you may also want to try again in a few minutes.',
      reason: 'This is an unexpected error. This error has been reported.',
      title: 'Something went wrong',
    },
    strings: [
      {
        description:
          'The request that was made took too long to complete. This is typically resolved in a few minutes, so please do try again shortly.',
        title: 'Request Timeout',
      },
      {
        description:
          'The request that was made was possibly looking for something that no longer exists in our system. If you believe this to be incorrect please do contact us.',
        title: 'Result not found',
      },
      {
        description:
          'This is unexpected and we’ve logged the error. We’re looking into it, but you may also want to try again in a few minutes.',
        title: 'Something went wrong',
      },
      {
        description:
          'This is unexpected and we’ve logged the error. We’re looking into it, but you may also want to try again in a few minutes.',
        title: 'Service is Temporarily Unavailable',
      },
      {
        description: 'Please log back in',
        title: 'Your session has expired',
      },
      {
        description:
          'This is unexpected and we’ve logged the error. We’re looking into it, but you may also want to try again in a few minutes.',
        title: 'Something went wrong',
      },
    ],
  },
  pollingToast: `The data for this page is taking longer than expected to load. Feel free to come back and check again later.`,
  sessionExpiring: {
    body:
      'Please choose to stay signed in or to log out. Otherwise, you will be logged out automatically.',
    cancelLabel: 'Stay signed in',
    description: 'You will be timed out due to inactivity.',
    successLabel: 'Log out',
    title: 'Your Session is Expiring Soon',
  },
});

export default copy;
