import React, { useEffect } from 'react';
import useSelector from './useSelector';
import axios from 'utils/axios';
import { User } from 'types';

/**
 * Updates the axios default headers to include the Authorization token
 */
export default (): void => {
  const user = useSelector<User | undefined>(state => state?.userState?.user);
  const token = user?.token;
  useEffect(() => {
    const value = token ? `Bearer ${token}` : undefined;
    axios.defaults.headers.common.Authorization = value;
  }, [token]);
};
