import assert from 'assert';
import {
  DefaultLoggerConfig,
  Logger,
  LogLevel,
  LogLevelString,
  Message,
} from './index.d';

/**
 * A named logger with level-based filtering
 */
export default class DefaultLogger implements Logger {
  private readonly format: DefaultLoggerConfig['format'];
  private readonly level: DefaultLoggerConfig['level'];
  private readonly name: DefaultLoggerConfig['name'];
  private readonly stream: DefaultLoggerConfig['stream'];

  private formatMessage(level: LogLevelString, ...args: any[]) {
    if (
      args.length === 0 ||
      !(typeof args[0] === 'string' || args[0] instanceof String)
    ) {
      args.unshift('');
    }
    const message = {
      timestamp: Date.now(),
      level,
      name: this.name,
      text: args[0],
    };
    args[0] = this.format(message);
    return args;
  }

  /**
   * Constructs a DefaultLogger
   * @param {DefaultLoggerConfig} config - The logger settings
   */
  constructor(config: DefaultLoggerConfig) {
    assert(config.format);
    assert(config.name);
    assert(config.stream);
    this.format = config.format;
    this.level = config.level;
    this.name = config.name;
    this.stream = config.stream;
  }

  /**
   * Logs a message at the "trace" level
   * @param {any[]} args - The text or objects to log
   */
  public trace(...args: any[]) {
    if (this.level <= LogLevel.TRACE) {
      args = this.formatMessage('TRACE', ...args);
      this.stream.debug(...args);
    }
  }

  /**
   * Logs a message at the "debug" level
   * @param {any[]} args - The text or objects to log
   */
  public debug(...args: any[]) {
    if (this.level <= LogLevel.DEBUG) {
      args = this.formatMessage('DEBUG', ...args);
      this.stream.debug(...args);
    }
  }

  /**
   * Logs a message at the "info" level
   * @param {any[]} args - The text or objects to log
   */
  public info(...args: any[]) {
    if (this.level <= LogLevel.INFO) {
      args = this.formatMessage('INFO', ...args);
      this.stream.info(...args);
    }
  }

  /**
   * Logs a message at the "info" level
   * @param {any[]} args - The text or objects to log
   */
  public log(...args: any[]) {
    if (this.level <= LogLevel.INFO) {
      args = this.formatMessage('INFO', ...args);
      this.stream.info(...args);
    }
  }

  /**
   * Logs a message at the "warn" level
   * @param {any[]} args - The text or objects to log
   */
  public warn(...args: any[]) {
    if (this.level <= LogLevel.WARN) {
      args = this.formatMessage('WARN', ...args);
      this.stream.warn(...args);
    }
  }

  /**
   * Logs a message at the "error" level
   * @param {any[]} args - The text or objects to log
   */
  public error(...args: any[]) {
    if (this.level <= LogLevel.ERROR) {
      args = this.formatMessage('ERROR', ...args);
      this.stream.error(...args);
    }
  }

  /**
   * Logs a message at the "fatal" level
   * @param {any[]} args - The text or objects to log
   */
  public fatal(...args: any[]) {
    if (this.level <= LogLevel.FATAL) {
      args = this.formatMessage('FATAL', ...args);
      this.stream.error(...args);
    }
  }
}
