import GlobalContext from 'context/globalContext'
import React from 'react'
import { createLogger } from 'logging'
import { useContext } from 'react'

const logger = createLogger({ name: 'brandSwitcher' })

export const useBrand = () => {
  const { brand, brands } = useContext(GlobalContext)

  const selectedBrand = brand ? brand : brands ? brands[0] : null

  // logger.log('useBrand', { brand, selectedBrand, brands })

  return selectedBrand
}
