import * as React from 'react';
import { Entity, User } from 'types';

interface AuthContextInterface {
  user: User | null;
}

const defaultContext: AuthContextInterface = {
  user: null,
};

const AuthContext = React.createContext(defaultContext);

export default AuthContext;
