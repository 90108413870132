export default (el, selector) => {
  if (Element.prototype.closest) {
    return el.closest(selector);
  } else {
    if (!document.documentElement.contains(el)) {
      return null;
    }
    while (el !== null || !el.matches(selector)) {
      el = el.parentNode || el.parentElement || null;
    }
  }
};
