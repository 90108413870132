import IntervalTree from 'node-interval-tree';
import { DateRange } from 'types';

export const formatFullDateInCurrentLocale = (dateString = '') => {
  return new Date(dateString).toLocaleString();
};

export const format = (dateString = '') => {
  const date = dateString.replace(/'/g, '');

  if (!dateString || new Date(date).toString() === 'Invalid Date') {
    return '';
  }

  const d = new Date(date);

  if (d.getUTCFullYear() === 1) {
    return '';
  }

  return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
};

export const isToday = dateString => {
  if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
    return false;
  }

  const today = new Date();
  return today.toDateString() === new Date(dateString).toDateString();
};

export const isOlderThan = (timestamp: number, timeInMs: number): boolean => {
  return timestamp < Date.now() - timeInMs;
};

/**
 * Returns a set of objects with overlapping date ranges
 *
 * @param {Object[]} ranges - An array of date range objects
 * @returns - A subset of objects with overlapping date ranges
 */
export function getOverlappingDateRanges<T extends DateRange>(
  ranges: Array<T>,
): Set<T> {
  const tree = new IntervalTree();

  // Insert all date ranges into the interval tree
  ranges.forEach(range => {
    const { startDate, endDate } = range;
    const low = startDate.getTime();
    const high = endDate.getTime();
    tree.insert(low, high, range);
  });

  // Build a set of overlapping date ranges
  const overlappingRanges = new Set<T>();
  ranges.forEach(range => {
    const { startDate, endDate } = range;
    const low = startDate.getTime();
    const high = endDate.getTime();
    const results = tree.search(low, high) as T[];
    // Ignore the current range
    const filtered = results.filter(item => item !== range);
    filtered.forEach(result => overlappingRanges.add(result));
  });

  return overlappingRanges;
}
