import API from 'config/api'
import React, { useContext, useMemo, useState } from 'react'
import axios from 'utils/axios'
import get from 'lodash/get'
import has from 'lodash/has'
import set from 'lodash/set'
import toHttps from 'utils/toHttps'
import { SavedEntity, TaskState } from 'types'
import { createLogger } from 'logging'
import { useDeepCompareEffect } from 'react-use'
import AuthContext from 'context/authContext'

interface QueryParams {
  ids: string[]
  date: string
  skip: number
  sort_by: string
  limit: number
}

export type EntityType =
  | 'brands'
  | 'categories'
  | 'collections'
  | 'entities'
  | 'influencers'
  | 'rosters'
  | 'roster_owners'

type Return = [SavedEntity[], TaskState]

const logger = createLogger({ name: 'useEntities' })

export default function useEntities(
  params: Partial<QueryParams> = {},
  type: EntityType = 'entities'
): Return {
  const [entities, setEntities] = useState<SavedEntity[]>([])
  const [taskState, setTaskState] = useState(TaskState.Initial)
  const { user } = useContext(AuthContext)
  useDeepCompareEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      try {
        if (Object.keys(params).length > 0) {
          const adjustedParams = {
            ...params,
            ids: params?.ids ? params?.ids.join(',') : params?.ids
          }
          if (params?.ids === undefined || params?.ids?.length > 0) {
            setTaskState(TaskState.Pending)
            const res = await axios.get(
              `${API.publicEntitiesDefault}/${type}`,
              {
                params: adjustedParams,
                headers: {
                  Authorization: `Bearer ${user.token}`
                }
              }
            )
            setEntities(res?.data?.[type])
          }
          setTaskState(TaskState.Success)
        } else {
          setTaskState(TaskState.Initial)
          setEntities([])
        }
      } catch (e) {
        logger.error(e)
        setTaskState(TaskState.Error)
      }
    })()
  }, [params, type, user])

  // Convert URLs to HTTPs
  const result = useMemo(() => {
    return entities.map(entity => {
      const urlPath = 'profile_image_url'
      if (has(entity, urlPath)) {
        const httpsUrl = toHttps(get(entity, urlPath))
        set(entity, urlPath, httpsUrl)
      }
      return { ...entity }
    })
  }, [entities])

  return [result, taskState]
}
