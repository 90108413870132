// These must be named like 'dimensionN' in order to be accepted by Google Analytics
export const analyticsKeys = {
  CLIENT_ID: 'dimension5',
  CLIENT_NAME: 'dimension1',
  CLIENT_TYPE: 'dimension2',
  IS_EMPLOYEE: 'dimension3',
  PAGE: 'dimension6',
  USER_ID: 'dimension4',
};

export const analyticsTypes = {
  BUTTON_CLICK: 'BUTTON_CLICK',
  CUSTOM_EVENT: 'CUSTOM_EVENT',
  INIT: 'INIT',
  LINK_CLICK: 'LINK_CLICK',
  MODAL_VIEW: 'MODAL_VIEW',
  PAGE_VIEW: 'PAGE_VIEW',
};
