import StringUtility from 'utils/stringReplacement';

const LanguagesCopy = new StringUtility({
  ar: 'Arabic',
  de: 'Deutsch',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  iw: 'Hebrew',
  ja: 'Japanese',
  ko: 'Korean',
  nl: 'Dutch',
  pt: 'Portuguese',
  ru: 'Russian',
  th: 'Thai',
  tr: 'Turkish',
  zh: 'Chinese',
});

export default LanguagesCopy;
