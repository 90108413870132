import to from 'await-to-js'
import { fb } from 'config/firebase'
import get from 'lodash/get'
import { ClientDetails, InitialUserObject, User } from 'types'
import { createLogger } from 'logging'

const logger = createLogger({ name: 'helpers/auth' })

type InitCallback = (user: InitialUserObject | null) => Promise<void>

export async function init(onStateChangeCallback: InitCallback) {
  try {
    const firebase = await fb()
    firebase.init()
    firebase.auth().onAuthStateChanged(async user => {
      let u: InitialUserObject | null = null
      if (user) {
        const forceRefresh = true
        const token = await user.getIdToken(forceRefresh)
        u = {
          lastLogin: user.metadata.lastSignInTime,
          name: user.email,
          token,
          uid: user.uid
        }
        logger.info('signed in', u)
      } else {
        logger.info('signed out')
      }
      return await onStateChangeCallback(u)
    })
  } catch (e) {
    logger.error('failed to initialize firebase auth', e)
    throw e
  }
}

/**
 * Create new user account
 *
 * @export                    - auth
 * @param {string} username   - user-defined email address
 * @param {string} password   - user-defined password
 * @returns Promise<firebase.auth.UserCredential> - Firebase user credentials
 */
export async function auth(username: string, password: string): Promise<any> {
  try {
    const firebase = await fb()
    return await firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
  } catch (e) {
    logger.error(`failed to create new user "${username}"`, e)
    throw e
  }
}

/**
 * Signs out the current user
 *
 * @export              - logout
 * @returns             - non-null promise containing void
 */
export async function logout(): Promise<void> {
  try {
    const firebase = await fb()
    await firebase.auth().signOut()
  } catch (e) {
    logger.error('failed to sign out', e)
    throw e
  }
}

/**
 * Signs in using an email and password
 *
 * @export                    - login
 * @param {string} username   - user predefined email address
 * @param {string} password   - user predefined password
 * @returns                   - promise containing non-null firebase.
 */
export async function login(username: string, password: string): Promise<any> {
  try {
    const firebase = await fb()
    return await firebase.auth().signInWithEmailAndPassword(username, password)
  } catch (e) {
    logger.warn(`failed to sign in as "${username}"`, e)
    throw e
  }
}

/**
 * Sends a password reset email to the given email address
 *
 * @export                    - resetPassword
 * @param {string} username   - user predefined email address
 * @returns                   - promise containing void
 */
export async function requestPasswordReset(username: string): Promise<any> {
  logger.log('requestPasswordReset', { username })
  try {
    const firebase = await fb()
    return await firebase.auth().sendPasswordResetEmail(username)
  } catch (e) {
    logger.error(`failed to reset password for user "${username}"`, e)
    throw e
  }
}

/**
 * Sends a password reset email to the given email address
 *
 * @export                    - resetPassword
 * @param {string} username   - user predefined email address
 * @returns                   - promise containing void
 */
export async function resetPassword(password: string): Promise<any> {
  try {
    const firebase = await fb()
    return await firebase.auth().sendPasswordResetEmail(password)
  } catch (e) {
    logger.error('failed to reset password', e)
    throw e
  }
}

/**
 * Verifies that the password reset link is not expired, malformed, or previously used
 *
 * @export                    - verifyPasswordReset
 * @param {string} actionCode - oobCode taken from query param formed by Firebase Auth api template
 * @returns                   - promise containing void
 */
export async function verifyPasswordReset(actionCode: string): Promise<any> {
  try {
    const firebase = await fb()
    return await firebase.auth().verifyPasswordResetCode(actionCode)
  } catch (e) {
    logger.error(
      `password reset verification failed (actionCode=${actionCode})`,
      e
    )
    throw e
  }
}

/**
 * Verifies that the password reset was successful
 *
 * @export                    - confirmPasswordReset
 * @param {string} actionCode - oobCode taken from query param formed by Firebase Auth api template
 * @param {string} password   - user supplied new password used for reset
 * @returns                   - promise containing void
 */
export async function confirmPasswordReset(
  actionCode: string,
  password: string
) {
  try {
    const firebase = await fb()
    return await firebase.auth().confirmPasswordReset(actionCode, password)
  } catch (e) {
    logger.error(
      'failed to confirm password reset (actionCode=${actionCode})',
      e
    )
    throw e
  }
}

/**
 * Fetches an entire collection based on a path in the db or a subset based on the provided query
 *
 * @export                   - getDocuments
 * @param {string} reference - reference path to the data required
 * @param {array} query      - used to define the filter set returned eg, ['condition', '==', 'value']
 * @returns                  - promise containing void
 */
export async function getDocuments(
  reference: string,
  normalize?: (doc) => any,
  query?: any[]
): Promise<any> {
  try {
    const firebase = await fb()
    const db = firebase.database()
    const documents = db.collection(reference)
    const querySnapshot = await documents.get()
    const docs = []
    querySnapshot.forEach(doc =>
      docs.push(normalize ? normalize(doc) : doc.data())
    )
    return docs
  } catch (e) {
    logger.error(`failed to get document (ref=${reference})`, query, e)
    throw e
  }
}

/**
 * Fetches an entire collection from a subcollection of a document
 *
 * @export                   - getDocuments
 * @param {string} reference - reference path to the data required
 * @param {array} query      - used to define the filter set returned eg, ['condition', '==', 'value']
 * @returns                  - promise containing void
 */
export async function getDocumentsFromSubcollection(
  collectionRef: string,
  docRef: string,
  subcollectionRef: string,
  query?: any[]
): Promise<any> {
  try {
    const firebase = await fb()
    const documents = firebase
      .database()
      .collection(collectionRef)
      .doc(docRef)
      .collection(subcollectionRef)

    const querySnapshot = await documents.get()
    const docs = {}
    querySnapshot.forEach(doc => (docs[doc.id] = { ...doc.data() }))
    return docs
  } catch (e) {
    logger.error(
      `failed to get document (collection=${collectionRef}, subcollection=${subcollectionRef}, doc=${docRef})`,
      query,
      e
    )
    throw e
  }
}

/**
 * Fetches a single record from the db
 *
 * @export                      - getDocument
 * @param {string} reference    - reference path to the data required
 * @param {string} docReference - unique identifier of the document to be retrieved from the collection
 * @returns                     - promise containing void
 */
export async function getDocument(
  reference: string,
  docReference: string
): Promise<any> {
  try {
    const firebase = await fb()
    const db = firebase.database()
    const documents = db.collection(reference).doc(docReference)
    const doc = await documents.get()
    return doc.exists ? doc.data() : null
  } catch (e) {
    logger.error(
      `failed to get document (collection=${reference}, doc=${docReference})`,
      e
    )
    throw e
  }
}

/**
 * Wrapper function around the firestore timestamp function
 *
 * @export  - updateServerTimestamp
 * @returns - firebase.firestore.FieldValue
 */
export async function updateServerTimestamp(): Promise<any> {
  const firebase = await fb()
  return firebase.database.FieldValue.serverTimestamp()
}

/**
 * Updates the user collection with new data
 *
 * @export                      - updateFavoriteTile
 * @param {string} docReference - unique identifier of the document to be retrieved from the collection
 * @param {data} any            - the data to be inserted into the collection for a given doc
 * @returns                     - promise containing void
 */
export async function updateFavoriteTile(
  userId: string,
  mutations: any,
  docId?: string
): Promise<any> {
  try {
    const firebase = await fb()
    const { data } = mutations
    const visualizations = firebase
      .database()
      .collection('users')
      .doc(userId)
      .collection('visualizations')

    if (docId) {
      // update existing document
      await visualizations.doc(docId).update(data)
      return { ...data }
    } else {
      // add new document
      const docRef = await visualizations.add(data)
      return docRef.id
    }
  } catch (e) {
    logger.error(
      `failed to update favorite tile (userId=${userId}, docId=${docId})`,
      e
    )
    throw e
  }
}

/**
 * Helper function that takes a docReference (UID), retrieves the users documents (collections)
 * Then will perform an update, to either update a field, or create a field
 *
 * @export
 * @param {string} docReference
 * @param {*} mutations
 * @returns {Promise<any>}
 */
export async function updateUserField(
  docReference: string,
  mutations
): Promise<any> {
  try {
    const firebase = await fb()
    const { path, data } = mutations
    const documents = firebase
      .database()
      .collection('users')
      .doc(docReference)

    await documents.update({ [path]: data })
    return { ...data }
  } catch (e) {
    logger.error(`failed to update user field (doc=${docReference})`, e)
    throw e
  }
}

/**
 * function that checks the user session in localStorage and uses that information
 * to determine the logged in state of the user
 * @export
 * @param {string} userId - the currently authenticated user's uid
 * @returns {Promise<ClientDetails|null>} - The user's client info or null
 */
export const getClientDetails = async (
  userId: string
): Promise<ClientDetails | null> => {
  try {
    const [userError, user] = await to(getDocument('users', userId))

    if (userError) {
      throw new Error('unable to retrieve user')
    } else {
      // user has no associated client
      if (!user.client) {
        return null
      }

      const [permissionError, clientDoc] = await to(
        getDocument('clients', user.client)
      )

      const [securityGroupPermissionError, securityGroupsDoc] = await to(
        getDocumentsFromSubcollection('clients', user.client, 'security_groups')
      )

      if (permissionError || securityGroupPermissionError) {
        throw new Error('unable to retrieve permissions')
      }

      return {
        ...clientDoc,
        permissions: Object.keys(clientDoc.permissions).map(k => ({
          id: k,
          ...clientDoc.permissions[k]
        })),
        securityGroups: Object.keys(securityGroupsDoc),
        lastViewedBrand: user.lastViewedBrand
      }
    }
  } catch (e) {
    logger.error('failed to get client details', e)
    throw e
  }
}

export const getSecurityGroups = async (userId: string): Promise<any> => {
  try {
    const firebase = await fb()
    const db = firebase.database()
    const securityGroups = db
      .collection('users')
      .doc(userId)
      .collection('security_groups')
    const querySnapshot = await securityGroups.get()
    const docs = []
    querySnapshot.forEach(doc => docs.push(doc.id))
    return docs
  } catch (e) {
    logger.error('failed to get security groups', e)
    throw e
  }
}

export const getAdminClaim = async (): Promise<boolean> => {
  try {
    const firebase = await fb()
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
    return !!idTokenResult.claims.admin
  } catch (e) {
    logger.error('failed to get admin claim', e)
    throw e
  }
}

export const updateInteractionCount = (
  path: string | string[],
  type: string,
  property: []
): Promise<any> => {
  return new Promise((resolve, reject) => {
    return fb()
      .then(firebase => {
        const db = firebase.database()

        const doc = getDocReference(db, path)

        return db
          .runTransaction(transaction =>
            // This code may get re-run multiple times if there are conflicts.
            transaction
              .get(doc)
              .then(document => {
                if (!document.exists) {
                  return reject(new Error('Document does not exist!'))
                }
                // bump the interaction count by one
                const newInteractionCount =
                  get(document.data(), property, 0) + 1
                // if this is a saved visualization then update the interaction_count property
                // as well as the last_used timestamp. For templates we just update the count
                // on the key of the template being used
                transaction.update(
                  doc,
                  type === 'favorite'
                    ? {
                        interaction_count: newInteractionCount,
                        last_used: new firebase.database.Timestamp.now()
                      }
                    : {
                        templates: {
                          ...document.data().templates,
                          [property.slice(-1).toString()]: newInteractionCount
                        }
                      }
                )
              })
              .catch(reject)
          )
          .then(resolve)
          .catch(reject)
      })
      .catch(reject)
  })
}

/**
 * function that performs a document reference lookup on either collection -> subcollection -> doc
 * relationship or a simple collection -> document lookup
 * @export
 * @param {any} db - firestore db instance
 * @param {string | array} path - string deliminated by / or a string array used to perform the doc lookup
 * @returns {any} docReference - pointer to document
 */
const getDocReference = (db: any, path: string | string[]) => {
  let pathLookup = []
  let docRef

  if (typeof path === 'string') {
    pathLookup = path.split('/')
  }

  for (let i = 0; i < pathLookup.length; i++) {
    docRef =
      i === 0
        ? db.collection(pathLookup[i])
        : i % 2 === 0
        ? docRef.collection(pathLookup[i])
        : docRef.doc(pathLookup[i])
  }

  return docRef
}
