export const LOGIN_REQUESTED: string = 'LOGIN_REQUESTED';
export const LOGIN_USER_FAIL: string = 'LOGIN_USER_FAIL';
export const LOGIN_USER_SUCCESS: string = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_SUCCESS_SET_ANALYTICS: string =
  'LOGIN_USER_SUCCESS_SET_ANALYTICS';
export const LOGGED_OUT: string = 'LOGGED_OUT';
export const REQUEST_IN_PROGRESS: string = 'REQUEST_IN_PROGRESS';
export const FORM_VALUES_UPDATED: string = 'FORM_VALUES_UPDATED';
export const REQUEST_COMPLETE: string = 'REQUEST_COMPLETE';
export const PASSWORD_RESET_REQUEST_COMPLETE: string =
  'PASSWORD_RESET_REQUEST_COMPLETE';
export const PASSWORD_RESET_COMPLETE: string = 'PASSWORD_RESET_COMPLETE';
export const INITIALIZE_LOGIN: string = 'INITIALIZE_LOGIN';
export const INITIALIZE_PASSWORD_RECOVERY: string =
  'INITIALIZE_PASSWORD_RECOVERY';
export const PASSWORD_RESET_INVALID_CODE_FAILURE: string =
  'PASSWORD_RESET_INVALID_CODE_FAILURE';
export const STORE_PASSWORD_RESET_EMAIL: string = 'STORE_PASSWORD_RESET_EMAIL';
export const UPDATE_URL_PATH: string = 'UPDATE_URL_PATH';
export const UPDATE_SESSION_INFO: string = 'UPDATE_SESSION_INFO';
export const CANCELLED_PENDING_REQUESTS: string = 'CANCELLED_PENDING_REQUESTS';
export const REQUEST_CANCELLED: string = 'REQUEST_CANCELLED';
export const UPDATE_CLIENT_DOC: string = 'UPDATE_CLIENT_DOC';
export const FANAI_USER_TYPE_UPDATE: string = 'FANAI_USER_TYPE_UPDATE';
export const FANAI_USER_TYPE_ADDED: string = 'FANAI_USER_TYPE_ADDED';

/* DataLayer Constants */
export const constants = {
  CACHE_FAVORITE_TILE_DATA: 'CACHE_FAVORITE_TILE_DATA',
  CATEGORIES_LIST_PATHS_UPDATED: 'CATEGORIES_LIST_PATHS_UPDATED',
  CLEAR_ERROR: 'CLEAR_ERROR',
  COMPARISON_DATA_RECEIVED: 'COMPARISON_DATA_RECEIVED',
  GENERATED_TILE_DELETED: 'GENERATED_TILE_DELETED',
  MENU_ITEMS_AVAILABLE: 'MENU_ITEMS_AVAILABLE',
  NEW_GENERATED_TILE_CREATED: 'NEW_GENERATED_TILE_CREATED',
  REQUEST_ERROR: 'REQUEST_ERROR',
  REQUEST_IN_PROGRESS: 'REQUEST_IN_PROGRESS',
  ROUTE_CHANGE: 'ROUTE_CHANGE',
  SD_TEMPLATE_UPDATED: 'SD_TEMPLATE_UPDATED',
  SET_ACTIVE_VISUALIZATION: 'SET_ACTIVE_VISUALIZATION',
  SET_DASHBOARD_HAS_FILTER_PANE: 'SET_DASHBOARD_HAS_FILTER_PANE',
  SET_DASHBOARD_REQUEST_COUNT: 'SET_DASHBOARD_REQUEST_COUNT',
  SET_FTU_MODAL: 'SET_FTU_MODAL',
  SET_PARENT_ENTITY: 'SET_PARENT_ENTITY',
  SET_POLLING_TOAST: 'SET_POLLING_TOAST',
  SET_SELECTED_ENTITY: 'SET_SELECTED_ENTITY',
  SET_SELECTED_VISUALIZATION: 'SET_SELECTED_VISUALIZATION',
  TEMPLATE_COUNT_UPDATE_FAILURE: 'TEMPLATE_COUNT_UPDATE_FAILURE',
  TOGGLE_GLOBAL_FILTER_PANE: 'TOGGLE_GLOBAL_FILTER_PANE',
  TOGGLE_GLOBAL_SIDEBAR: 'TOGGLE_GLOBAL_SIDEBAR',
  UNIVERSE_COMPONENT_PATHS_UPDATED: 'UNIVERSE_COMPONENT_PATHS_UPDATED',
  UPDATE_HISTORICAL_VALUES: 'UPDATE_HISTORICAL_VALUES',
  UPDATE_SELECTED_ENTITIES: 'UPDATE_SELECTED_ENTITIES',
  UPDATE_SELECTED_ENTITY: 'UPDATE_SELECTED_ENTITY',
  UPDATE_URL_PATH: 'UPDATE_URL_PATH',
};

export const compare2Things = {
  ADDITIONAL_ENTITIES_RECEIVED: 'ADDITIONAL_ENTITIES_RECEIVED',
  COMPARISON_DATA_RECEIVED: 'COMPARISON_DATA_RECEIVED',
  ENTITIES: 'ENTITIES',
  ENTITIES_ALL: 'ENTITIES_ALL',
  ENTITIES_INDEPENDENT_META_DATA_RECEIVED:
    'ENTITIES_INDEPENDENT_META_DATA_RECEIVED',
  ENTITIES_INDEPENDENT_TWITTER_DATA_RECEIVED:
    'ENTITIES_INDEPENDENT_TWITTER_DATA_RECEIVED',
  ENTITIES_RECEIVED: 'ENTITIES_RECEIVED',
  ENTITY_LIST_IN_PROGRESS: 'ENTITY_LIST_IN_PROGRESS',
  HISTORICAL_ENTITIES_DATA_RECEIVED: 'HISTORICAL_ENTITIES_DATA_RECEIVED',
  HISTORICAL_ENTITIES_OVERLAP_DATA_RECEIVED:
    'HISTORICAL_ENTITIES_OVERLAP_DATA_RECEIVED',
  HISTORICAL_ENTITY_DATA_RECEIVED: 'HISTORICAL_ENTITY_DATA_RECEIVED',
  ORG_ENTITIES_RECEIVED: 'ORG_ENTITIES_RECEIVED',
  RELATED_ENTITIES_RECEIVED: 'RELATED_ENTITIES_RECEIVED',
  RELATED_ORG_ENTITIES_RECEIVED: 'RELATED_ORG_ENTITIES_RECEIVED',
  RESET_SINGLE_ENTITY: 'RESET_SINGLE_ENTITY',
  RESET_UNIVERSE_ENTITIES: 'RESET_UNIVERSE_ENTITIES',
  SWAP_ENTITIES: 'SWAP_ENTITIES',
  TOOLTIP_INFO_TWITTER_SUCCESS: 'TOOLTIP_INFO_TWITTER_SUCCESS',
  TOOLTIP_INFO_UNIVERSE_SUCCESS: 'TOOLTIP_INFO_UNIVERSE_SUCCESS',
  UNIVERSE_ENTITIES_LOADING: 'UNIVERSE_ENTITIES_LOADING',
  UNIVERSE_ENTITIES_RECEIVED: 'UNIVERSE_ENTITIES_RECEIVED',
  UPDATE_HISTORICAL_DATE_RANGE: 'UPDATE_HISTORICAL_DATE_RANGE',
  UPDATE_SELECTED_ENTITIES: 'UPDATE_SELECTED_ENTITIES',
  UPDATE_SELECTED_ENTITY: 'UPDATE_SELECTED_ENTITY',
};

export const spendBehavior = {
  RANKING_VIEW_CHANGE: 'RANKING_VIEW_CHANGE',
  RESET_CATEGORIES: 'RESET_CATEGORIES',
  SET_RANKING_VIEW: 'SET_RANKING_VIEW',
  SPEND_BEHAVIOR_FILTER_CHANGE: 'SPEND_BEHAVIOR_FILTER_CHANGE',
  SPEND_CATEGORIES_AVAILABLE: 'SPEND_CATEGORIES_AVAILABLE',
  SPEND_TYPE_BINS_AVAILABLE: 'SPEND_TYPE_BINS_AVAILABLE',
};

export const slideoutCategories = {
  BRAND_BY_CATEGORY_ERROR: 'BRAND_BY_CATEGORY_ERROR',
  BRAND_BY_CATEGORY_LIST_PATHS_UPDATED: 'BRAND_BY_CATEGORY_LIST_PATHS_UPDATED',
  BRAND_BY_CATEGORY_LOADING: 'BRAND_BY_CATEGORY_LOADING',
  BRAND_BY_CATEGORY_SUB_CATEGORIES_RECEIVED:
    'BRAND_BY_CATEGORY_SUB_CATEGORIES_RECEIVED',
  CATEGORIES_ERROR: 'CATEGORIES_ERROR',
  CATEGORIES_LOADING: 'CATEGORIES_LOADING',
  SUB_CATEGORIES_RECEIVED: 'SUB_CATEGORIES_RECEIVED',
  TOP_LEVEL_BRAND_BY_CATEGORY_RECEIVED: 'TOP_LEVEL_BRAND_BY_CATEGORY_RECEIVED',
  TOP_LEVEL_CATEGORIES_RECEIVED: 'TOP_LEVEL_CATEGORIES_RECEIVED',
};
