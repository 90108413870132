import React, { useCallback } from 'react';
import { createGlobalState } from 'react-use';
import { ToastInterface } from 'types';

type Type = ToastInterface['type'];

interface Return {
  toast: (type: Type, message: string) => void;
  pop: () => ToastInterface;
}

const useGlobalState = createGlobalState<ToastInterface[]>([]);

/**
 * Provides functions for adding or removing toast messages from a queue
 *
 * @example
 * const {toast} = useToast();
 * return (
 *   <div onClick={() => toast('info', 'Click detected.')} />
 * );
 *
 * @see src/components/organisms/toaster/index.tsx
 */
export default function useToast() {
  const [queue, setQueue] = useGlobalState();
  const toast = useCallback(
    (type: Type, message: string) => {
      const newQueue = [...queue, { type, message }];
      setQueue(newQueue);
    },
    [setQueue, queue],
  );
  const pop = useCallback(() => {
    const next = queue[0];
    if (next) {
      setQueue([...queue.slice(1)]);
    }
    return next;
  }, [setQueue, queue]);
  return { toast, pop };
}
