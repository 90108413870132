import React, { Component } from 'react';
import { track } from 'actions/analyticsActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useEffectOnce } from 'react-use';

/**
 * An HOC that emits a given Google Analytics event upon mount
 * @param {Component} Wrapper - The component that triggers an event
 * @param {string} eventType - A Google Analtyics event type
 */
export default function withGoogleAnalyticsMount<T>(
  Wrapped: React.ComponentType<T>,
  eventType: string,
) {
  return (props: any) => {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffectOnce(() => {
      dispatch(track({ event: location.pathname, eventType }));
    });
    // eslint-disable-next-line
    return <Wrapped {...props} />;
  };
}
