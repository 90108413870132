import React, { ChangeEvent, useCallback, useState } from 'react';

/**
 * Tracks input values and provides an onChange event handler
 */
export default function useTextInput(initValue: string = '') {
  const [value, setValue] = useState<string>(initValue);
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );
  return [value, onChange, setValue] as [
    string,
    typeof onChange,
    typeof setValue,
  ];
}
