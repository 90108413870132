import get from 'lodash/get';
/**
 * Function take an arbitrary template string and injects the replacements at the same
 * position as the string array
 *
 * @param {Array.<string[]>} - stringParts the template string to perform replacements on
 * @param {Array.<function[]>} - the default operations to perform on the individual replacement strings
 * @returns function - receives the replacements
 */
export function parse(
  stringParts: TemplateStringsArray,
  ...mutators: Array<(arg: any) => string>
): (replacements: Array<string | number> | string) => string {
  return (replacements: Array<string | number> | string): string => {
    // filter out empty strings
    const filteredStringParts = [...stringParts];
    // flatten the replacements as this argument can be either a list of parameters or an array
    const stringReplacements = Array.isArray(replacements)
      ? [...replacements]
      : Array(filteredStringParts.length).fill(replacements);
    // replace non function mutators with noop's
    const fns = mutators.map(f => (typeof f === 'function' ? f : args => args));

    return filteredStringParts
      .reduce((current, next, index) => {
        if (stringReplacements[index]) {
          return [
            ...current.slice(0, index + index + 1),
            fns[index] && fns[index](stringReplacements[index]),
            ...current.slice(index + index + 1),
          ];
        }

        return current;
      }, filteredStringParts)
      .join('')
      .replace(/\s\s/g, ' ')
      .trim();
  };
}

/**
 * store string for later manipulation
 *
 *
 * Usage example:
 *
 * {
 *  type: {
 *    subtype: {
 *      cases: [0.1, 0.5],
 *      strings: [replacements => parse`foo ${null} baz.`(replacements)]
 *    }
 *  }
 * }
 *
 *
 * let newString1 = s.getString("type.subtype", v => v >= 0.5, ["bar"]); // produces "foo bar baz"
 */
const StringUtility = function(strings) {
  this.strings = strings;
};

type formatter = (value: Array<string | number> | string) => string;

interface CopyConfiguration {
  cases: Array<string | number>;
  strings: formatter[] | string[];
  [prop: string]: any;
}

/**
 * Function takes a property key lookup for referencing a copy configuration map and additional options
 * for performing conditional lookup and replacements on a given string
 * @name getString
 * @function
 *
 * @param {(string\|string[])} keypath - the lookup property path to find a given string configuration
 * @param {object} options -
 *  condition - function run on each index in the configured cases for optionally returning a string conditionally
 *  replacements - the values to fill in a found string
 *  defaultValue - a string to render should no condition return true, or a function that returns a string
 * @returns string
 */
StringUtility.prototype.getString = function(
  keyPath: string | string[],
  options?: {
    condition?: (value: any) => boolean;
    replacements?: Array<string | number>;
    defaultValue?: string | formatter;
  },
): string {
  const copyConfig = this.get(keyPath, {});
  const { condition = null, replacements = null, defaultValue = '' } =
    options || {};
  const { cases, strings, defaultValue: dv } = copyConfig;

  if (typeof copyConfig === 'function') {
    return copyConfig(replacements);
  } else if (typeof copyConfig === 'string') {
    return copyConfig;
  }

  const index =
    !copyConfig.cases || !cases.length || !condition
      ? -1
      : cases.findIndex(condition);
  const fallbackDefault = defaultValue || dv || '';
  return index > -1
    ? typeof strings[index] === 'function'
      ? strings[index](replacements)
      : strings[index]
    : typeof fallbackDefault === 'function'
    ? fallbackDefault(replacements)
    : fallbackDefault;
};

/**
 * Function takes a property key lookup for referencing a copy configuration map and additional options
 * for performing conditional lookup and replacements on a given string
 * @name get
 * @function
 *
 * @param {(string\|string[])} keypath - the lookup property path to find a given string configuration
 * @param {(string\|function)} defaultValue - string or a function that returns a string.
 * @returns object | string | function
 */
StringUtility.prototype.get = function(
  keyPath: string | string[],
  defaultValue?: string | formatter,
): CopyConfiguration | string | formatter {
  return get(this.strings, keyPath, defaultValue || '');
};

export default StringUtility;
