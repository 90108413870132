import DefaultLogger from './defaultLogger'
import format from './format'
import { Config, Logger, LogLevel } from './index.d'

function getLogLevel(text: string): LogLevel {
  switch (text.toLowerCase()) {
    case 'trace':
      return LogLevel.TRACE
    case 'debug':
      return LogLevel.DEBUG
    case 'info':
      return LogLevel.INFO
    case 'warn':
      return LogLevel.WARN
    case 'error':
      return LogLevel.ERROR
    case 'fatal':
      return LogLevel.FATAL
    case 'silent':
      return LogLevel.SILENT
    default:
      return LogLevel.WARN
  }
}

/**
 * Returns a named logger
 * @param {Config} config - The logger config
 * @return {Logger} - A logger instance
 */
export default function createLogger(config: Config): Logger {
  let logger: Logger = new DefaultLogger({
    format,
    name: config.name,
    level: getLogLevel(process.env.LOG_LEVEL || ''),
    stream: console
  })
  return logger
}
