export const fb = async () =>
  import(/* webpackChunkName: "firebase" */ './firebaseImport').then(fb => {
    let conf = {
      apiKey: '',
      authDomain: '',
      projectId: '',
    };

    if (process.env.FIREBASE_ENV === 'fanai-development') {
      conf = {
        apiKey: 'AIzaSyDteL1Jfc0BXs04V-9ORxcDRD39-b6oPHI',
        authDomain: 'fanai-development.firebaseapp.com',
        projectId: 'fanai-development',
      };
    }

    if (
      process.env.FIREBASE_ENV === 'fanai-staging' ||
      process.env.API_ENV === 'stage'
    ) {
      conf = {
        apiKey: 'AIzaSyC30C2g3HyhhdOKVBouQlNer2HT6AH_8Z0',
        authDomain: 'fanai-staging.firebaseapp.com',
        projectId: 'fanai-staging',
      };
    }

    if (process.env.FIREBASE_ENV === 'fanai-production') {
      conf = {
        apiKey: 'AIzaSyAt2A4CuaA0VkJX9ojb6wlj_xXeUz1gGQY',
        authDomain: 'fanai-production.firebaseapp.com',
        projectId: 'fanai-production',
      };
    }

    const firebase = fb.default;

    return {
      auth: firebase.auth,
      config: conf,
      database: firebase.firestore,
      functions: firebase.functions,
      init: () => {
        firebase.initializeApp(conf);
      },
    };
  });
