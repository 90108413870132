import { ToastProps } from 'hooks/requests.d'
import { RouteComponentProps } from 'react-router'

export type tags =
  | 'SUGGESTED'
  | 'NEW'
  | 'TEMPLATE'
  | 'FEEDBACK'
  | 'LIVE'
  | 'MANAGER'
export type actions = 'DISMISS' | 'TAKE' | 'VIEW' | 'TRY' | 'USE'
export type layout = 'VERTICAL' | 'FULLWIDTH' | 'WIDE' | 'NARROW'
export type pointerDirection = 'left' | 'right' | 'top' | 'bottom'
export type views = 'universe' | null
export type viewsQueryParam = 'universe' | 'null'
export type namingPrecedence = 'name' | 'nickname' | 'twitter.handle'
export type entityHierarchy =
  | 'roster_owners'
  | 'teams'
  | 'players'
  | 'games'
  | 'leagues'
export type audienceDistributionCategories =
  | 'VERY LOW'
  | 'LOW'
  | 'MEDIUM'
  | 'HIGH'
  | 'VERY HIGH'
  | 'EXTREMELY HIGH'

export type permissionTypes = 'create' | 'read' | 'write' | 'delete'
export type Role = 'admin' | 'creator' | 'observer'
export type securityGroups = 'fanai_user_admin' | 'fanai_user'
export type PollingToastOperation = 'ADD' | 'SUBTRACT' | 'RESET'
export type FanAIUserType = 'fanai_user' | 'fanai_user_admin'
export type AggregateByType =
  | 'everything'
  | 'property_tracking'
  | 'overlapping_days'
  | undefined

export interface Action {
  disabled?: boolean
  dropdownItems?: any[]
  name: string
  type: string
}

export interface SDTile {
  actions: Action[] | string[]
  interaction_count?: number
  layout?: layout[]
  name: string
  tag: number | string
  type: string
  uid: string
}

export interface UserTile {
  filters: null | string[]
  inherits_from: string
  interaction_count: number
  last_used: string
  name: string
}

export interface Tag {
  name: string
  theme: string
  uid: string
}

export interface Tiles {
  sdTiles: SDTile[]
  selected?: boolean
  tags: Tag[]
  templates: {}
  tiles: any[]
  userTiles: UserTile[]
}

export interface Tile {
  actions?: Action[]
  description?: string
  entities?: EntitiesList
  filters?: null | string[]
  inherits_from?: string
  interaction_count?: number
  last_used?: string
  layout?: layout
  name: string
  selectedDistributionCategories?: SavedDistributionCategories | {}
  subheader?: string
  tag: Tag
  title: string
  type?: string
  uid?: string
  dashboardType?: string
  dashboardSubType?: string
  category?: {
    [prop: string]: any
  }
}

export interface Breadcrumb {
  name: string
  url: string
}

export interface TwitterData {
  id: string
  handle?: string
  followers?: number
  created_at: string
  description: string
  followers_count: number
  friends_count: number
  inserted_at: string
  lang: string
  listed_count: number
  location: string
  name: string
  profile_image_url: string
  protected: boolean
  screen_name: string
  statuses_count: number
  updated_at: string
  url: string
  verified: boolean
}

export interface HistoricalDate {
  date_counts: any[]
  date_errors: any[]
  entity: string
  platform: string
  view: string
}
export interface Entity {
  brands?: Entity
  childGroupType?: string
  children?: Entity[]
  domain?: string
  games?: Entity
  grouping?: Entity[]
  id: string
  leagues?: Entity
  name?: string
  nickname?: string
  players?: Entity
  roster_owners?: Entity
  teams?: Entity
  title?: string
  profile_image_url?: string
  type?: string
  views?: views[]
  universe?: boolean | null
}
export interface EntitiesList {
  entity1?: SavedEntity
  entity2?: SavedEntity
}

export interface RelatedTypes {
  inbound: string[]
  outbound: string[]
}
export interface SavedEntity {
  /**
   * list of strings of aliases
   */
  aliases?: string[]
  /**
   * String or number as the category ID
   */
  category?: string
  /**
   * array of objects containing category associated with the entity
   */
  categories?: EntityCategories[] | string[] | []
  /**
   * Follower count derived from comparison venn API, exclusive_count
   */
  followers?: number | null
  /**
   * Object of results from historical API response
   */
  historical?: HistoricalDate
  /**
   * Unique identifier for each item
   */
  id?: string
  index?: number
  /**
   * value of entity name
   */
  name: string
  /**
   * optional nickname value
   */
  nickname?: string
  /**
   * object containing inbound or outbound relationship types to this entity
   */
  related_types?: RelatedTypes | string[]
  /**
   * array of objects representing each child/path
   */
  pathIndices?: Array<{ DropdownItem?; index: number; path: string | number }>
  /**
   * Array of related categories, used in Brand by Category and Category slideouts
   */
  subCategories?: SavedEntity[]
  /**
   * object of twitter data
   */
  twitter?: TwitterData[] | TwitterData

  /**
   * url string for profile image
   */
  profile_image_url?: string
  /**
   * url string for banner image
   */
  domain?: string
  /**
   * optional value for type of entity
   */
  type?: string
  /**
   * array of types that this entity falls under
   */
  types?: string[]
  /**
   * boolean value if there is a universe associated with this dropdownItem
   */
  universe?: boolean
  /**
   * number value for followers only of this entity
   */
  uniqueFollowers?: number
  /**
   * array of different options
   */
  views?: Array<'universe' | 'null'>
  roster_owners?: SavedEntity[]
  rosters?: SavedEntity[]
  roster_members?: SavedEntity[]
  version_id?: string
  updated_at?: string
  created_at?: string
  isParent?: boolean
}

export interface Category {
  name: string
  id: string
  preferred_alias?: string
}

export interface SelectedEntitiesListItems {
  /**
   * String or number as the category ID
   */
  category?: string
  /**
   * Unique identifier for each item
   */
  id?: number | string
  /**
   * value of entity
   */
  value?: string
  /**
   * boolean value if there is a universe associated with this dropdownItem
   */
  universe?: boolean | null
}
export interface SelectedEntitiesList {
  entity1?: SelectedEntitiesListItems
  entity2?: SelectedEntitiesListItems
}

export interface DropdownItem {
  error?: boolean
  id?: number | string
  name: string
  isGrouped?: boolean
  childGroupType?: string
  related_types?: RelatedTypes | string[]
  value?: any
}

export interface EntityTypeProperties {
  namePrecedence?: namingPrecedence[] | string[]
  structure?: EntityStructure[]
}

export interface EntityTypes {
  brands?: EntityTypeProperties
  default: EntityTypeProperties
  games?: EntityTypeProperties
  influencers?: EntityTypeProperties
  leagues?: EntityTypeProperties
  players?: EntityTypeProperties
  roster_owners?: EntityTypeProperties
  teams?: EntityTypeProperties
}

export interface EntityStructure {
  groupBy?: string
  type?: string
  title?: string
}

export interface EntityCountInstance {
  entity_id: string
  view: string | null
}
export interface EntityCount {
  entity_objs: EntityCountInstance[]
  exclusive_count: number
  intersection_count: number
  union_count: number
}

export interface Request {
  endpoint?: string
  body?: any
  headers?: any
  method?: string
  mode?: string
  credentials?: string
}

export interface RequestInterface {
  allowConcurrent?: boolean
  call: (() => Promise<any>) | string
  errors?: Map<
    number[],
    {
      type: string
      payload: any | ((response: any, action: any, state: any) => any)
    }
  >
  lifecycle: any
  requestType?: string
  options?: Request
}

// TODO merge this with above interface once fully migrated
export interface NewRequestInterface {
  concurrent?: boolean
  statusCodes?: Map<
    number[],
    | {
        type: string
        payload?: any
      }
    | {
        payload?: (response: any, action: any, state: any) => any
        type: string
      }
  >
  lifecycle?: any
  options?: any
  namespace?: any
  poll?: any
}

export interface Permission {
  allowed_actions: permissionTypes[]
  id: string
  desc: string
}

export interface ClientDetails {
  permissions?: {
    [prop: string]: Permission
  }
  securityGroups?: string[]
  id?: string
  type: ClientType
  name: string
  ravenDomainIds?: string[]
  ravenFolder?: number | string
  entityId?: string
  parentEntity?: SavedEntity
  lastViewedBrand?: string | null
}

export type ClientType = 'rightsholder' | 'manufacturer'

interface InputField {
  valid: boolean
  value: string
  message?: string
}

interface EmailForm {
  email: InputField
}

interface PasswordForm {
  password: InputField
  confirmPassword: InputField
}

interface Form {
  fields: EmailForm | PasswordForm
  valid: boolean
  errorMessage?: string
}

/* Initial information we take from the user object returned by the call to firebase auth on login */
export interface InitialUserObject {
  lastLogin: string
  name: string
  token: string
  uid: string
}

export interface User {
  client: ClientDetails
  role: Role | string
  lastViewedBrand?: string | null
  uid: string | null
  login: {
    fields: {
      username: InputField
      password: InputField
    }
  }
  ftuModals?: any
  name: string
  passwordRecovery: Form
  passwordReset: Form
  permissions: {
    [key: string]: Permission
  }
  securityGroups: securityGroups[]
  lastLogin: string | null
  token: string
}

export interface MeterLabel {
  /*
   * optional position for each meter bar, eg start | center | end
   */
  position?: string
  /*
   * any specific style overrides for a specific meter bar instance
   */
  style?: any
  /*
   * optional meter bar title
   */
  title?: string
}

export interface SpendCategory {
  id: string
  name: string
  hasBinData?: boolean
  populationSize?: number
  matchedSize?: number
  spendTypes: {
    [prop: string]: SpendType
  }
}
export interface SpendSubcategory {
  audienceId: number
  averageRank: number
  bins?: number[] // DEPRECATED by https://app.clubhouse.io/fanai/story/13173/custom-audience-spend-behavior-show-spend-behavior-results-for-sub-categories-with-ranking-view
  breakdownByQuartile: number[]
  hasAppliedFilter?: boolean
  id: string
  index: number
  name: string
  aboveAverage: number
  topQuartile: number
}
export interface SpendType {
  id: string
  name: string
  rankingView?: string
  subcategories: {
    [prop: string]: SpendSubcategory
  }
}

export interface SpendFilter {
  categoryId: number
  spendTypeId: number
  subcategoryIds: number[] | string[]
}

export interface SpendCopy {
  headline: string
  takeAway1: string
  takeAway2: string
}
export interface SavedDistributionCategories {
  category1: EntityCategories
  category2: EntityCategories
}

export interface EntityCategories {
  created_at?: string
  description?: string
  id: string
  name: string
  updated_at?: string
}

export enum NotificationType {
  EDIT = 'EDIT',
  SAVE = 'SAVE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE'
}

export type ViewOptions = 'grid' | 'list'
export type Error = null | ApiStandardError | StandardError

export interface StandardError {
  description?: string
  title: string
}

export interface ApiStandardError {
  error: Array<{
    message: string
    code: number
  }> | null
  status: number
}

export interface DynamicComponentInheritedProps {
  onError: (err: Error) => void
  onInitialized: () => void
  onLoad: () => void
  onLoadComplete: () => void
  hasError: boolean
  toastProps: ToastProps
}

export interface JourneyBreadcrumb {
  path?: string
  title: string
}

export interface DateTimeLabelFormats {
  day: string
  minute: string
  month: string
  week: string
  year: string
}

export interface RelationshipResponse {
  _key: string
  _id: string
  _from: string
  _to: string
  _rev: string
  updated_at: string
}

export interface CategoryResponse {
  name: string
  updated_at: string
  created_at: string
  _key: string
  _id: string
  _rev: string
}

export interface TilesReducer {
  historicalOverlap: any[]
  selectedEntities?: EntitiesList | null
  sharedFollowers?: number | null
}

export interface ToolTilesInterface {
  actions: Action[]
  body?: string
  icon?: HTMLImageElement
  isDisabled?: boolean
  mobileIcon?: HTMLImageElement
  name: string
  svg?: HTMLObjectElement
  title: string
  type?: string
}

export interface ToastInterface {
  action?: string
  message: string
  onAction?: () => any
  onDismiss?: () => any
  timeoutLength?: number
  type: 'default' | 'info' | 'action' | 'destroy'
  styles?: { [prop: string]: string | number }
}

export interface EntitySmall {
  id: string
  name: string
}

export interface AppFrameInterfaceProps extends RouteComponentProps<any> {
  isShownPollingToast: null | boolean
  logout: () => void
  setParentEntity: (entity: SavedEntity) => void
  setUserPreviousPath: (path: null | string) => void
  sidebarIsOpen: boolean
  toggleSidebarState: () => void
  user: User
  userPreviousPath: null | string
}

export interface ApplicationState {
  brandByCategoryActiveIndices: number[]
  brandByCategoryError: string | null
  brandByCategoryLoading: boolean
  breadcrumbs: Breadcrumb[]
  categoriesActiveIndices: number[]
  categoriesError: string | null
  categoriesLoading: boolean
  dashboardHasFilterPane: boolean
  dashboardRequestCount: null | number
  error: any
  filterPaneIsOpen: boolean
  isShownPollingToast: null | boolean
  loggedIn: boolean
  passwordResetComplete: boolean
  passwordResetRequestComplete: boolean
  pendingPermissions: boolean
  requestCache: any
  sideBarIsOpen: boolean
  universeComponentActiveIndices: number[]
  userPreviousPath: null | string
}

export interface AdminState {
  clients: {}
  permissions: {}
}

export interface Compare2ThingsState {
  brandByCategory: SavedEntity[] | DropdownItem[] | []
  brands: SavedEntity[] | DropdownItem[] | [] | {}
  categories: SavedEntity[] | DropdownItem[] | []
  entities: SavedEntity[] | DropdownItem[] | [] | {}
  games: SavedEntity[] | DropdownItem[] | [] | {}
  influencers: SavedEntity[] | DropdownItem[] | [] | {}
  roster_owners: SavedEntity[] | DropdownItem[] | [] | {}
}

export interface RequestState {
  requestCache: any
}

export interface SpendBehaviorState {
  categories: {
    [prop: string]: SpendCategory
  }
  categoriesReceived: boolean
  initialCategories: {
    [prop: string]: SpendCategory
  }
  populationSize: number
}

export interface CombinedReduxState {
  adminState: AdminState
  appState: ApplicationState
  entityState: Compare2ThingsState
  requestState: RequestState
  sdTileState: TilesReducer
  spendBehaviorState: SpendBehaviorState
  userState: User
}

export type SortOrder = 'ASC' | 'DESC'

export interface TableSortOptions {
  ASC: SortOrder
  DESC: SortOrder
}

// Response schema for /public-entities/entities/{id}
export interface EntityResponse {
  entity: SavedEntity
}

export enum TaskState {
  Initial = 'INITIAL',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type Variant = 'action' | 'info' | 'warn' | 'danger'

interface Size {
  width: number
  height: number
}

interface Range<T> {
  min: T
  max: T
}

interface SizeConstraints {
  desktop: Range<Size>
  // If not provided, desktop constraints will be used
  tablet?: Range<Size>
  // If not provided, tablet constraints will be used
  mobile?: Range<Size>
}

interface WidgetDefinition {
  sizeConstraints: SizeConstraints
}

export type WidgetDefinitionMap = Record<string, WidgetDefinition>

export interface WidgetConfig {
  widgetType: string
  size: Size
  props?: any
}

export enum PropertyTrackingStatus {
  SPONSORED = 'sponsored',
  UNSPONSORED = 'unsponsored'
}

export interface PropertyTracking {
  account_id: string
  brand_id: string
  created_epoch: number
  deleted_epoch: number
  id: string
  is_processing: boolean
  tracked_id: string // The entity ID
  tracked_name: string
  updated_epoch: number
  status: PropertyTrackingStatus
}

// Response schema for /pii/property-trackings
export interface PiiPropertyTrackingsResponse {
  data: Array<PropertyTracking>
}

export type Nullable<T> = T | null | undefined

export enum WidgetState {
  Initial = 'INITIAL',
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Done = 'DONE',
  Error = 'ERROR'
}

export interface WidgetProps {
  onStateChange: (state: WidgetState) => void
}

export interface PiiAudiencesResponse {
  account_id: string
  created_epoch: number
  id: string
  is_system: boolean
  name: string
  object_url: string
  sample_size: number
  search_rank: number
  signed_url: string
  updated_epoch: number
  version: number
}

export type PresetDateRange =
  | 'day'
  | 'week'
  | 'two-weeks'
  | 'month'
  | 'sixty-days'
  | 'quarter'
  | 'year'
  | 'ytd'

export interface DateRange {
  startDate: Date
  endDate: Date
}

export interface Activation {
  id: string
  property_tracking_id: string
  name: string
  dates: Array<DateRange>
}

type UpliftActual = {
  uplift?: number
  actual?: number
}
type PercentageActual = {
  percentage?: number
  actual?: number
}
type SpendTime = {
  start_hour: number
  actual?: number
  end_hour: number
  percentage?: number
}

export type CreateUpliftData = {
  assisted_sales: { total: UpliftActual; per_customer: UpliftActual }
  assisted_txns: { per_customer: UpliftActual; avg_txn_size: UpliftActual }
  consented: boolean
  property_tracking_id: string
  matches: { customer: number; fan: number }
  start_epoch: number
  end_epoch: number
  media_channel: MediaChannel
}

export enum MediaChannel {
  DIGITAL = 'digital',
  ONSITE = 'onsite',
  RADIO = 'radio',
  SOCIAL = 'social',
  TV = 'tv'
}
