import { compare2Things, constants, LOGGED_OUT } from 'actions/types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { EntitiesList, SavedEntity, TilesReducer } from 'types';

import {
  getAlternateEntity,
  getFollowerCounts,
  setEntityType,
  sumFollowers,
} from 'utils/entities';

import { convertDateStringToUTC } from 'utils/numberFormatter';
import { sortAlphabetically } from 'utils/sorting';
import {
  INIT_TILE_STATE,
  RESET_SELECTED_ENTITIES,
  SET_ENTITY_UNIVERSE,
} from '../../constants';

const {
  COMPARISON_DATA_RECEIVED,
  SET_SELECTED_ENTITY,
  SET_SELECTED_VISUALIZATION,
  UPDATE_HISTORICAL_VALUES,
  UPDATE_SELECTED_ENTITIES,
  UPDATE_SELECTED_ENTITY,
} = constants;

const {
  HISTORICAL_ENTITIES_DATA_RECEIVED,
  SWAP_ENTITIES,
  ENTITIES_INDEPENDENT_META_DATA_RECEIVED,
  ENTITIES_INDEPENDENT_TWITTER_DATA_RECEIVED,
  RESET_SINGLE_ENTITY,
} = compare2Things;

export const initialState = {
  historicalOverlap: [],
  selectedEntities: null,
  sharedFollowers: null,
};

export default (
  state: TilesReducer = initialState,
  action: { type: string; payload: any } = { type: '', payload: {} },
) => {
  switch (action.type) {
    case INIT_TILE_STATE:
      return {
        ...state,
        selectedEntities: {},
        sharedFollowers: null,
      };

    case UPDATE_SELECTED_ENTITY: {
      const currentEntities = {
        ...state.selectedEntities,
        [action.payload.type]: { ...action.payload.entity },
      };

      if (action.payload.entity.id) {
        const cats = get(action, 'payload.entity.categories', []);
        // sort the categories alphabetically before saving and resetting
        const updatedCategories = cats.sort(sortAlphabetically('name'));

        // set the category to either first alphabetical category, or
        // if no categories set it "Not Available" and add  Category [entity type] to description
        currentEntities[action.payload.type] = {
          ...action.payload.entity,
          categories: updatedCategories,
        };
      } else {
        // if the entity that was just updated is cleared and there is another entity currently selected,
        // then reset the other entities uniqueFollower count to their totalFollowers
        const type = getAlternateEntity(action.payload.type);
        if (get(currentEntities[type], ['uniqueFollowers'])) {
          currentEntities[type].uniqueFollowers = get(currentEntities[type], [
            'followers',
          ]);
        }
      }

      return {
        ...state,
        selectedEntities: currentEntities,
        sharedFollowers: null,
      };
    }

    case UPDATE_SELECTED_ENTITIES: {
      const { entity1 = {}, entity2 = {} } = state.selectedEntities || {};
      const {
        entity1: defaultEntity1,
        entity2: defaultEntity2,
      } = action.payload.entities;
      return {
        ...state,
        selectedEntities: {
          entity1: {
            ...entity1,
            ...(defaultEntity1
              ? {
                  ...defaultEntity1,
                  category: 'entities',
                  pathIndices: [
                    { title: 'entities', type: 'entities', active: true },
                  ],
                  type: 'entities',
                  universe: get(defaultEntity1, ['universe']),
                }
              : {}),
          },
          entity2: {
            ...entity2,
            ...(defaultEntity1
              ? {
                  ...defaultEntity2,
                  category: 'entities',
                  pathIndices: [
                    { title: 'entities', type: 'entities', active: true },
                  ],
                  type: 'entities',
                  universe: get(defaultEntity2, ['universe']),
                }
              : {}),
          },
        },
      };
    }

    case SET_SELECTED_ENTITY: {
      const entityTypes = Object.keys(action.payload);
      const newEntities = {};

      entityTypes.forEach(type => {
        if (
          state.selectedEntities &&
          get(state.selectedEntities, [type]) &&
          get(state.selectedEntities, [type, 'id']) ===
            get(action.payload, [type, 'id'])
        ) {
          // if setting a new entity and that entity has the same id
          // as the entity in state then merge them together
          if (
            get(state.selectedEntities, [type, 'id']) ===
            get(action.payload, [type, 'id'])
          ) {
            newEntities[type] = {
              ...get(state.selectedEntities, [type]),
              ...get(action.payload, [type]),
            };
          }
        } else {
          // else create new entity type on the selected entity
          newEntities[type] = {
            ...get(action.payload, [type]),
          };
        }
      });

      return {
        ...state,
        selectedEntities: !isEmpty(action.payload)
          ? {
              ...state.selectedEntities,
              ...newEntities,
            }
          : {},
      };
    }

    case SET_SELECTED_VISUALIZATION: {
      return {
        ...state,
      };
    }

    case LOGGED_OUT:
      return {
        ...state,
        ...initialState,
      };

    case COMPARISON_DATA_RECEIVED:
      return {
        ...state,
        ...getFollowerCounts(state.selectedEntities, action.payload.counts),
      };

    case RESET_SELECTED_ENTITIES:
      return {
        ...state,
        selectedEntities: {},
      };

    case HISTORICAL_ENTITIES_DATA_RECEIVED:
      const res = action.payload;
      let entity1 = { ...state.selectedEntities.entity1 };
      let entity2 = { ...state.selectedEntities.entity2 };

      // if just one entity was returned
      if (res.length === 1) {
        // check if current entity in state matches id of entity from response
        const isEntity1 =
          entity1 && entity1.id && entity1.id === res[0].entities[0];
        // convert the entity's date_count array to UTC dates
        const historical = {
          ...res[0],
          // convert date_counts to delta counts. First value is total value, then 2nd value is delta
          date_counts: res[0].date_counts.map((x, i, arr) => [
            convertDateStringToUTC(x[0]),
            i === 0 ? null : x[1] - arr[i - 1][1],
            x[1],
          ]),
        };

        return {
          ...state,
          selectedEntities: {
            ...state.selectedEntities,
            [isEntity1 ? 'entity1' : 'entity2']: {
              ...state.selectedEntities[isEntity1 ? 'entity1' : 'entity2'],
              historical,
            },
          },
        };
      } else {
        // else if both entities were returned
        // add action.payload.res to the matching entity ID
        entity1 = {
          ...entity1,
          historical: entity1.id === res[0].entities[0] ? res[0] : res[1],
        };
        entity2 = {
          ...entity2,
          historical: entity2.id === res[0].entities[0] ? res[0] : res[1],
        };
        // convert both entity date_count arrays to UTC dates
        const entity1DateCounts = entity1.historical.date_counts.map(
          (x, i, arr) => [
            convertDateStringToUTC(x[0]),
            // convert date_counts to delta counts. First value is total value, then 2nd value is delta
            i === 0 ? null : x[1] - arr[i - 1][1],
            x[1],
          ],
        );
        const entity2DateCounts = entity2.historical.date_counts.map(
          (x, i, arr) => [
            convertDateStringToUTC(x[0]),
            // convert date_counts to delta counts. First value is total value, then 2nd value is delta
            i === 0 ? null : x[1] - arr[i - 1][1],
            x[1],
          ],
        );
        entity1 = {
          ...entity1,
          historical: {
            ...entity1.historical,
            date_counts: entity1DateCounts,
          },
        };
        entity2 = {
          ...entity2,
          historical: { ...entity2.historical, date_counts: entity2DateCounts },
        };
        return {
          ...state,
          selectedEntities: {
            entity1,
            entity2,
          },
          ...(res.length === 3
            ? {
                historicalOverlap: res[2].date_counts.map((x, i, arr) => [
                  convertDateStringToUTC(x[0]),
                  // convert date_counts to delta counts. First value is total value, then 2nd value is delta
                  i === 0 ? null : x[1] - arr[i - 1][1],
                  x[1],
                ]),
              }
            : {}),
        };
      }

    case SWAP_ENTITIES:
      const { selectedEntities } = action.payload;

      return {
        ...state,
        selectedEntities,
      };

    case ENTITIES_INDEPENDENT_META_DATA_RECEIVED:
      const entity = get(action.payload.entity, [0, 'entity']);
      const entityType = setEntityType(entity);

      const { related_types, name, id, views, twitter, types } = entity;
      return {
        ...state,
        selectedEntities: {
          ...state.selectedEntities,
          entity1: {
            id,
            name,
            related_types,
            twitter,
            type: entityType,
            types,
            views,
          },
        },
      };

    case ENTITIES_INDEPENDENT_TWITTER_DATA_RECEIVED:
      const twitterArr = get(
        action.payload,
        ['entity', 0, 'twitter_users'],
        [],
      );
      const count = sumFollowers(twitterArr);
      return {
        ...state,
        selectedEntities: {
          entity1: {
            ...get(state.selectedEntities, ['entity1'], {}),
            followerCount: count,
          },
        },
      };

    case RESET_SINGLE_ENTITY:
      return {
        ...state,
        selectedEntities: {
          ...state.selectedEntities,
          [action.payload]: {},
        },
      };

    case SET_ENTITY_UNIVERSE:
      const targetEntity = get(state.selectedEntities, action.payload.entity);
      targetEntity.universe = action.payload.universe;

      return {
        ...state,
        selectedEntities: {
          ...state.selectedEntities,
        },
      };

    case UPDATE_HISTORICAL_VALUES:
      return {
        ...state,
        selectedEntities: {
          ...state.selectedEntities,
          [action.payload.keyPath]: {
            ...state.selectedEntities[action.payload.keyPath],
            historical: action.payload.dateValues,
          },
        },
      };
    default:
      return state;
  }
};
