import { RequestState } from 'types';

const initialState = {
  requestCache: {},
};

export default (state: RequestState = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_PENDING':
      const newState = { ...state };
      const { url, request } = action.payload;

      if (!state.requestCache[url]) {
        newState.requestCache = {
          ...state.requestCache,
          [url]: request,
        };
      }

      return {
        ...newState,
      };

    case 'REQUEST_SETTLED':
    case 'REQUEST_CANCELLED':
      const currentCache = {
        ...state.requestCache,
      };

      if (
        action.payload &&
        action.payload.url &&
        currentCache[action.payload.url]
      ) {
        delete currentCache[action.payload.url];
      }

      return {
        ...state,
        requestCache: { ...currentCache },
      };

    default:
      return state;
  }
};
