import {
  Charts as charts,
  Colors as colors,
  CustomMedia as customMedia,
  Fonts as fonts,
  Forms as forms,
  Spacing as spacing,
  Styles as styles,
  ZIndices as zIndices,
} from 'constants/styles';

const theme = {
  charts,
  colors,
  customMedia,
  fonts,
  forms,
  spacing,
  styles,
  zIndices,
};

export type ThemeType = typeof theme;
export default theme;
