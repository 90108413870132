import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export default function i18nInitialize() {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources: {
        'en-US': {
          buttons: require('resources/locales/en-US/buttons.json'),
          common: require('resources/locales/en-US/common.json'),
          errors: require('resources/locales/en-US/errors.json'),
          labels: require('resources/locales/en-US/labels.json'),
          modals: require('resources/locales/en-US/modals.json'),
          screens: require('resources/locales/en-US/screens.json'),
          widgets: require('resources/locales/en-US/widgets.json'),
        },
      },
      defaultNS: 'common',
      fallbackNS: 'common',
      fallbackLng: 'en-US',
      whitelist: ['en-US'],
      keySeparator: '.', // Does not use keys in form of messages.welcome
      interpolation: {
        escapeValue: false,
      },
    });

  return i18n;
}
