import { LOGIN_USER_SUCCESS_SET_ANALYTICS } from 'actions/types';
import Analytics from 'analytics/analyticEvents';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga';
import { ANALYTICS } from '../constants';
import { analyticsTypes } from '../constants/analytics';

const { INIT, LINK_CLICK } = analyticsTypes;

export const formAnalyticsAction = (trackingId: string) => (
  eventType: string,
  eventPayload: any,
) => {
  if (Analytics[eventType]) {
    Analytics[eventType]({ ...eventPayload, trackingId });
  } else {
    throw new Error(`Payload event type not supported: ${eventType}`);
  }
};

export const trackTimeTravel = (payload, eventHistory: any[] = []) => {
  const { timeTravel, eventData } = payload;
  // short circuit since no history has been given
  if (isEmpty(timeTravel)) {
    return payload;
  }

  const { before, after } = timeTravel;
  // short circuit since no history has been given
  if (!before && !after) {
    return payload;
  }
  // in this scenario we either want to know the event that happened before/after or both
  return {
    ...payload,
    eventData: {
      ...eventData,
      label: `
          ${
            before
              ? `[before_event]: ${
                  eventHistory.length >= 2
                    ? eventHistory[eventHistory.length - 2].eventAction ||
                      eventHistory[eventHistory.length - 2].eventType
                    : 'null'
                }`
              : ''
          }
          [event]: ${eventData.label}
          ${
            after
              ? `[after_event]: ${
                  eventHistory.length
                    ? eventHistory[eventHistory.length - 1].eventAction ||
                      eventHistory[eventHistory.length - 2].eventType
                    : null
                }`
              : ''
          }
        `.replace(/\s/g, ''),
    },
  };
};

let analyticsStack = [];
let deferredEvent = {};

const analytics = trackingId => store => next => action => {
  const postAnalyticsAction = formAnalyticsAction(trackingId);

  if (action.type === ANALYTICS) {
    const { eventType, timeTravel, eventAction, eventData } = action.payload;
    // push event onto stack for potential use with other custom events types that need
    // time travel capability
    analyticsStack.push({ ...action.payload });

    // we only keep track of up to 50 prev events
    if (analyticsStack.length > 50) {
      analyticsStack = analyticsStack.slice(
        Math.floor(analyticsStack.length / 2),
      );
    }

    // since we know this action is the next and we have a deferred action then we can
    // operate on it now and clear it out since the current action may also be deferred
    if (!isEmpty(deferredEvent)) {
      // before we store the next deferred event we process the previous one
      postAnalyticsAction(
        // @ts-ignore
        deferredEvent.eventType,
        trackTimeTravel(deferredEvent, [
          ...analyticsStack.slice(0, -2),
          ...analyticsStack.slice(-1),
        ]),
      );
      // flush current deferred
      deferredEvent = {};
    }

    if (eventAction === LOGIN_USER_SUCCESS_SET_ANALYTICS) {
      // User successfully logged in. We want to set the user data with Google Analytics
      // see: https://app.nuclino.com/FanAI/Tech/Tech-Spec-Analytics-9e2a51bc-96ae-46f1-ac3b-735ecff4e93b
      ReactGA.set({ ...eventData });
    }

    // handle the given action
    if (eventType === INIT) {
      Analytics.INIT(trackingId);
    } else {
      // if the action supports time travel then we need to either cache the action
      // to send with the next that passes thru the middleware or we need to trigger
      // the current action with the data from the previous action
      if (timeTravel && timeTravel.after) {
        // in this scenario we either want to know the event that happened before/after or both
        // store new action for next time middleware is called
        deferredEvent = { ...action.payload };
        return next(action);
      }
      // handle various analytic events
      postAnalyticsAction(
        eventType,
        // if we only care about the event that happened before this one then we dont need to cache
        // this event to defer its execution we simply track this event with the data from
        // the previous action which is in the analyticsStack
        trackTimeTravel(action.payload, analyticsStack),
      );
    }
  }

  return next(action);
};

export default analytics;
