import get from 'lodash/get';
import ReactGA from 'react-ga';
import { analyticsKeys } from '../constants/analytics';
import { AnalyticsMetaData } from './index';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export default {
  BUTTON_CLICK: action => {
    ReactGA.event({
      action: get(action, 'event', "Couldn't retrieve action"),
      category: 'Button Click',
    });
  },
  CUSTOM_EVENT: ({
    eventData,
    eventAction,
  }: {
    eventData?:
      | { category?: string; label?: string; value?: number }
      | AnalyticsMetaData;
    eventAction: string;
  }): void => {
    // Called when user logs in, will set custom meta data
    if (eventData?.hasOwnProperty(analyticsKeys.CLIENT_NAME)) {
      ReactGA.set({ ...eventData });
    }
  },
  INIT: trackingId => {
    ReactGA.initialize(process.env.GA_TRACKING_ID, { debug: DEBUG_ANALYTICS });
  },
  LINK_CLICK: action => {
    ReactGA.event({
      action: get(action, 'event', "Couldn't retrieve action"),
      category: 'Link Click',
    });
  },
  MODAL_VIEW: action => {
    // event = location.pathname
    ReactGA.modalview(get(action, ['event'], "Couldn't Retrieve action"));
  },
  PAGE_VIEW: ({
    eventData,
    trackingId,
  }: {
    eventData: { page_path: string };
    trackingId: string;
  }) => {
    // for every page change, we want to track the client type, if they are a fanai employee and the route they are going to
    // also, we want to sent the page view change to GA
    ReactGA.set({ ...eventData });
    ReactGA.pageview(eventData[analyticsKeys.PAGE]);
  },
};
