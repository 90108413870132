import Languages from 'copy/languages';

export const trimToLower = (str: string) =>
  str.toLowerCase().replace(/\s/g, '');

export const removeSpecialChars = (str: string) =>
  str.replace(/[^0-9A-Za-z]/g, '');

export const formatNumberValue = (value: string | number) => {
  if (value === null || value === undefined) {
    return '0';
  }
  const v = '' + value;

  return v
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .join(',')
    .split('')
    .reverse()
    .join('');
};

/* Formats todays date to 'MM/DD/YYYY' */
export const getTodaysDate = () =>
  `${new Date().getMonth() +
    1}/${new Date().getDate()}/${new Date().getFullYear()}`;

/* Formats todays date to 'MM/DD/YYYY' */
export const getTodaysDateYearFirst = (): string =>
  `${new Date().getFullYear()}-${prependZero(
    new Date().getMonth() + 1,
  )}-${new Date().getDate()}`;

const prependZero = date => (date < 10 ? '0' + date : date);

/**
 * function used to capitalize the first letter of the string that is passed
 *
 * @param {string} [str]
 * @return {string}
 */
export const capitalize = (str?: string): string =>
  str && str.length ? str.charAt(0).toUpperCase() + str.substring(1) : '';

export const toCamelCase = (str: string, delimiter?: string) =>
  str
    .split(delimiter || ' ')
    .map(capitalize)
    .join('');

/**
 * Converts the string into lowercase and replaces the occurences of the delimiter
 * (by default ' ') with '-' char.
 *
 * @param str - string to convert
 * @param delimiter - delimiter char to use for replacement (' ' by default)
 */
export const toKebabCase = (str: string, delimiter?: string) =>
  str
    .split(delimiter || ' ')
    .map(str => str.toLowerCase())
    .join('-');

/**
 * Function that takes the last char os 's' in a string and prepends a "'" char
 *
 * @param {string} str - the string to check the last character against
 * @return {string} - new string with 's at the end if not already in plural form else string + '
 */
export const pluralize = (str: string) =>
  str && str.length
    ? str[str.length - 1] === 's'
      ? `${str}'`
      : `${str}'s`
    : '';
/**
 * Function that looks for the last character in a string
 *
 * @param {string} str - the string to check the last character against
 * @param {string} letter - the last letter to check against
 * @return {boolean} - if contains the last letter, returns true
 */
export const endsWith = (str: string, letter: string): boolean =>
  str && str.length
    ? str.toLowerCase().charAt(str.length - 1) === letter.toLowerCase()
      ? true
      : false
    : false;

/**
 * function that checks if entity has a universe. If it does, adds universe to the end of the string
 * also checks if entity ends with an s
 *
 * @param {string} str
 * @param {boolean} hasUniverse
 * @param {boolean} capitalizeFirstLetter - if true, then will return 'Universe'
 * @return {string}
 */
export const appendWithUniverse = (
  str: string,
  hasUniverse: boolean,
  capitalizeFirstLetter?: boolean,
): string =>
  hasUniverse
    ? `${!endsWith(str, 's') ? str + "'s" : str + "'"} ${
        capitalizeFirstLetter ? 'Universe' : 'universe'
      }`
    : str;

/**
 * function that takes an array of strings or numbers and concatenates them by a given delimeter
 *
 * @param {array} characterArray - the array of strings to be joined
 * @param {string | number} delimeter - the character(s) to join the string by
 * @return {string}
 */
export const joinByDelimeter = (
  characterArray: Array<string | number>,
  delimeter: string | number,
): string => {
  return characterArray.join(String(delimeter));
};

/**
 * function that will take a string in snake case (snake_case), and return it as a regular string (snake case)
 *
 * @param {string} str
 * @returns {string}
 */
export const snakeCaseToString = (str: string): string =>
  str ? str.replace(/_/g, ' ') : '';

/**
 * Function that will take an abbreviation (two letters) for a language, and return the full language name.
 * if we do not have the language, we return the abbreviation in all caps
 * @param {string} abbreviation
 * @returns {string}
 */
export const setLanguageName = (abbreviation: string = ''): string => {
  return Languages.get([abbreviation], abbreviation.toUpperCase());
};
