import axios from 'utils/axios';
import { createLogger } from 'logging';

export const CURRENT_DATE_TIMESTAMP = 1590372959301;

const logger = createLogger({ name: 'mocksModule' });

export default async function initMocksModule() {
  try {
    const { default: initMocks } = await import(
      /* webpackChunkName: "mocks" */ '../mocks'
    );
    initMocks(axios);
  } catch (e) {
    logger.error('Failed to load mocks module');
    logger.error(e);
  }
}
