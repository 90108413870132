import {
  constants,
  FORM_VALUES_UPDATED,
  INITIALIZE_PASSWORD_RECOVERY,
  LOGGED_OUT,
  LOGIN_REQUESTED,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  PASSWORD_RESET_REQUEST_COMPLETE,
  STORE_PASSWORD_RESET_EMAIL,
  UPDATE_CLIENT_DOC,
  UPDATE_SESSION_INFO
} from 'actions/types'
import get from 'lodash/get'
import { User } from 'types'
import { setEntityType } from 'utils/entities'
import { setIsFanaiEmployee } from 'utils/user'
import validate, { isFormValid } from 'utils/validations'
import { USER_PERMISSIONS_RECEIVED } from '../../constants'
import schema from './validationSchema'

const { SET_PARENT_ENTITY } = constants

const initialState = {
  user: {
    client: null,
    lastViewedBrand: null,
    lastLogin: null,
    login: {
      errorMessage: null,
      fields: {
        password: {
          valid: null,
          value: null
        },
        username: {
          valid: null,
          value: null
        }
      },
      valid: false
    },
    name: null,
    passwordRecovery: {
      fields: {
        email: {
          valid: null,
          value: null
        }
      },
      valid: false
    },
    passwordReset: {
      fields: {
        confirmPassword: {
          valid: null,
          value: null
        },
        password: {
          valid: null,
          value: null
        }
      },
      valid: false
    },
    permissions: null,
    role: 'observer',
    securityGroups: null,
    uid: null,
    token: null
  }
}

export default (state: { user: User } = initialState, action) => {
  switch (action.type) {
    case FORM_VALUES_UPDATED:
      // tslint:disable-next-line
      const {
        payload: { values, form }
      } = action

      // update the input field the user has changed
      // run the new value through the appropriate validator
      const fields = {
        ...state.user[form].fields,
        ...{
          [values.type]: {
            valid: validate(values, schema),
            value: values.value
          }
        }
      }

      return {
        ...state,
        user: {
          ...state.user,
          [form]: {
            ...state.user[form],
            ...{ fields, valid: isFormValid(fields) },
            errorMessage: null
          }
        }
      }

    case LOGIN_REQUESTED:
      return {
        ...state,
        user: {
          ...state.user,
          login: {
            ...state.user.login,
            errorMessage: null
          }
        }
      }

    case LOGIN_USER_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          login: {
            ...state.user.login,
            errorMessage: action.payload,
            valid: false
          }
        }
      }

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          lastLogin: action.payload.lastLogin,
          name: get(state, ['user', 'name'])
            ? get(state, ['user', 'name'])
            : get(action, ['payload', 'name']),
          uid: action.payload.uid
        }
      }

    case LOGGED_OUT:
      return {
        ...state,
        ...initialState
      }

    case INITIALIZE_PASSWORD_RECOVERY:
      return {
        ...state,
        user: {
          ...state.user,
          login: {
            ...state.user.login,
            fields: {
              ...state.user.login.fields,
              password: {
                valid: null,
                value: null
              }
            },
            valid: false
          },
          passwordReset: {
            ...state.user.passwordReset,
            errorMessage: null,
            fields: {
              confirmPassword: {
                valid: null,
                value: null
              },
              password: {
                valid: null,
                value: null
              }
            },
            valid: false
          }
        }
      }

    case STORE_PASSWORD_RESET_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          passwordRecovery: {
            fields: {
              email: {
                valid: true,
                value: action.email
              }
            },
            valid: true
          }
        }
      }

    case PASSWORD_RESET_REQUEST_COMPLETE:
      return {
        ...state,
        user: {
          ...state.user,
          passwordRecovery: {
            fields: {
              email: {
                valid: null,
                value: null
              }
            },
            valid: false
          }
        }
      }

    case UPDATE_SESSION_INFO:
      const { lastLogin, username, uid } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          lastLogin,
          login: {
            ...state.user.login,
            fields: {
              ...state.user.login.fields,
              username: {
                valid: true,
                value: username
              }
            },
            valid: true
          },
          name: state.user && state.user.name ? state.user.name : username,
          uid
        }
      }

    case USER_PERMISSIONS_RECEIVED:
      const perms = {}
      action.payload.permissions.forEach(p => (perms[p.id] = { ...p }))
      const updatedState = {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
          client: { ...action.payload.client },
          name: get(state, ['user', 'name'])
            ? get(state, ['user', 'name'])
            : get(action, ['payload', 'name']),
          permissions: {
            ...perms
          },
          role: action.payload.role,
          securityGroups: [...action.payload.securityGroups],
          login: {
            errorMessage: null,
            fields: {
              ...state.user.login.fields,
              ...{
                password: {
                  valid: null,
                  value: null
                }
              }
            },
            valid: true
          },
          passwordRecovery: {
            fields: {
              ...state.user.passwordRecovery.fields
            },
            valid: false
          },
          passwordReset: {
            errorMessage: null,
            fields: {
              confirmPassword: { value: null, valid: null },
              password: { value: null, valid: null }
            },
            valid: false
          },
          lastViewedBrand: action.payload.lastViewedBrand
        }
      }

      if (setIsFanaiEmployee(updatedState.user)) {
        updatedState.user.fanaiId = get(action.payload, ['client', 'id'])
      }

      return updatedState

    case UPDATE_CLIENT_DOC:
      return {
        ...state,
        user: {
          ...state.user,
          client: action.payload
        }
      }

    case SET_PARENT_ENTITY:
      return {
        ...state,
        user: {
          ...state.user,
          client: {
            ...state.user.client,
            parentEntity: {
              ...action.payload,
              type: setEntityType(action.payload)
            }
          }
        }
      }

    default:
      return state
  }
}
