import { RequestReduxMiddleware } from '@fanai/redux-request-middleware'
import rootReducer from 'reducers'
import * as redux from 'redux'
import thunk from 'redux-thunk'
import analytics from '../middleware/analytics'

const { applyMiddleware, compose, createStore } = redux

declare module 'redux' {
  export type GenericStoreEnhancer = redux.StoreEnhancer
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R
    cancelIdleCallback: any
  }
}

export default (preloadedState?: {}) => {
  let store = createStore(
    rootReducer,
    applyMiddleware(
      thunk,
      RequestReduxMiddleware,
      analytics(process.env.GA_TRACKING_ID)
    )
  )

  if (process.env.NODE_ENV !== 'production') {
    const composeEnhancers =
      (typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose
    store = createStore(
      rootReducer,
      composeEnhancers(
        applyMiddleware(
          thunk,
          RequestReduxMiddleware,
          analytics(process.env.GA_TRACKING_ID)
        )
      )
    )

    if (module.hot) {
      module.hot.accept('../reducers/index', () => {
        const newRootReducer = require('../reducers/index').default
        store.replaceReducer(newRootReducer)
      })
    }
  }

  return store
}
