import {
  CLIENT_CREATED,
  CLIENT_UPDATED,
  CLIENTS_AND_USERS_AVAILABLE,
  CLIENTS_DELETED,
  GLOBAL_PERMISSIONS_AVAILABLE,
  RAVEN_FOLDER_CREATED,
  UPDATE_CLIENT_DELETION_STATUS,
  USER_CREATED,
  USER_DELETED,
  USER_EDITED
} from '../../constants'

import {
  FANAI_USER_TYPE_ADDED,
  FANAI_USER_TYPE_UPDATE,
  LOGGED_OUT
} from 'actions/types'

import { AdminState } from 'types'

interface User {
  client: string
  email: string
  firstName: string
  lastName: string
  id: string
  edit: 'edit'
  delete: 'delete'
}

const initialState = {
  clients: {},
  permissions: {}
}

export default (state: AdminState = initialState, action) => {
  switch (action.type) {
    case CLIENTS_AND_USERS_AVAILABLE:
      const clientCache = {}

      action.payload.clients.forEach(client => {
        clientCache[client.id] = { ...client, users: [] }
      })

      action.payload.users.forEach(user => {
        if (user.client) {
          if (clientCache[user.client]) {
            clientCache[user.client].users.push({
              ...user,
              delete: 'delete',
              edit: 'edit'
            })
          }
        }
      })

      return {
        ...state,
        clients: clientCache
      }
    case USER_EDITED:
      const editedUser = action.payload.user
      let newUsers = [...state.clients[editedUser.client].users]

      const index = newUsers.findIndex(u => u.id === editedUser.id)

      const updatedUser = {
        ...newUsers[index],
        ...editedUser
      }

      newUsers = [
        ...newUsers.slice(0, index),
        updatedUser,
        ...newUsers.slice(index + 1)
      ]

      return {
        ...state,
        clients: {
          ...state.clients,
          [editedUser.client]: {
            ...state.clients[editedUser.client],
            users: [...newUsers]
          }
        }
      }

    case USER_CREATED:
      const { user } = action.payload
      const users = [...state.clients[user.client].users]

      users.push({ ...user, delete: 'delete', edit: 'edit' })

      return {
        ...state,
        clients: {
          ...state.clients,
          [user.client]: {
            ...state.clients[user.client],
            users: [...users]
          }
        }
      }

    case USER_DELETED:
      let client = { ...state.clients[action.payload.user.client] }
      const userIndex = client.users.findIndex(
        u => u.id === action.payload.user.id
      )

      client = {
        ...client,
        users: [
          ...client.users.slice(0, userIndex),
          ...client.users.slice(userIndex + 1)
        ]
      }
      return {
        ...state,
        clients: {
          ...state.clients,
          [client.id]: client
        }
      }

    case GLOBAL_PERMISSIONS_AVAILABLE:
      return {
        ...state,
        permissions: action.payload
      }

    case CLIENT_CREATED:
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.client.id]: { ...action.payload.client, users: [] }
        }
      }

    case CLIENT_UPDATED:
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.client.id]: {
            ...action.payload.client,
            users: [...state.clients[action.payload.client.id].users]
          }
        }
      }

    case UPDATE_CLIENT_DELETION_STATUS:
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload]: {
            ...state.clients[action.payload],
            markForDeletion: !state.clients[action.payload].markForDeletion
          }
        }
      }
    case CLIENTS_DELETED:
      const currentClients = { ...state.clients }
      action.payload.clients.forEach(client => {
        delete currentClients[client.id]
      })

      return {
        ...state,
        clients: {
          ...currentClients
        }
      }
    case LOGGED_OUT:
      return {
        ...state,
        clients: {},
        permissions: {}
      }
    case RAVEN_FOLDER_CREATED:
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.clientId]: {
            ...state.clients[action.payload.clientId],
            ravenFolder: action.payload.ravenFolderId
          }
        }
      }
    case FANAI_USER_TYPE_ADDED:
      // get the user that is being updated
      const fanaiUsersClone = state.clients[action.payload.clientId].users
      const usersIndex = fanaiUsersClone.findIndex(
        user => user?.id === action.payload.userId
      )

      if (usersIndex >= 0) {
        // update the user by adding new security group type to their existing securityGroups
        fanaiUsersClone[usersIndex].securityGroups = [
          ...fanaiUsersClone[usersIndex].securityGroups,
          action.payload.fanaiUserType
        ]
      }

      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.clientId]: {
            ...state.clients[action.payload.clientId],
            users: fanaiUsersClone
          }
        }
      }
    case FANAI_USER_TYPE_UPDATE:
      // get the user that is being updated
      const fanaiUsers = state.clients[action.payload.clientId].users
      const userToUpdateIndex = fanaiUsers.findIndex(
        user => user?.id === action.payload.userId
      )
      // get the users list of current security groups
      const usersSecurityGroups = fanaiUsers[userToUpdateIndex]?.securityGroups
      const typeIndexToRemove = usersSecurityGroups.findIndex(
        type => type === action.payload.fanaiUserType
      )

      if (typeIndexToRemove >= 0) {
        // remove the security group that was removed from FB
        usersSecurityGroups.splice(typeIndexToRemove, 1)
        // update users list of security groups
        fanaiUsers[userToUpdateIndex].securityGroups = usersSecurityGroups
      }

      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.clientId]: {
            ...state.clients[action.payload.clientId],
            users: fanaiUsers
          }
        }
      }
    default:
      return state
  }
}
