export default {
  // tslint:disable-next-line
  PERMISSION_DENIED:
    'Sorry but you are not authorized to access this information',
  'auth/invalid-action-code':
    'Sorry but the reset link has expired. Please request a new link.',
  'auth/invalid-email': 'Invalid email. Please try again.',
  'auth/user-not-found': 'Username/Password not found. Please try again.',
  'auth/wrong-password': 'Username/Password not found. Please try again.',
};
