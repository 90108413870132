import * as React from 'react';
import './icon.css';

const Icon: React.FunctionComponent<any> = ({
  glyph,
  ...props
}): JSX.Element => {
  return React.createElement(glyph, { ...props });
};

export default Icon;
