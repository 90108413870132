import { compare2Things, slideoutCategories } from 'actions/types';
import appConfig from 'config/appConfig';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import unionBy from 'lodash/unionBy';
import { Compare2ThingsState, Entity } from 'types';
import { sortEntityList } from 'utils/sorting';

const {
  BRAND_BY_CATEGORY_SUB_CATEGORIES_RECEIVED,
  CATEGORIES_ERROR,
  SUB_CATEGORIES_RECEIVED,
  TOP_LEVEL_BRAND_BY_CATEGORY_RECEIVED,
  TOP_LEVEL_CATEGORIES_RECEIVED,
} = slideoutCategories;

const {
  ADDITIONAL_ENTITIES_RECEIVED,
  ENTITIES_RECEIVED,
  ORG_ENTITIES_RECEIVED,
  RELATED_ENTITIES_RECEIVED,
  RELATED_ORG_ENTITIES_RECEIVED,
  RESET_UNIVERSE_ENTITIES,
  UNIVERSE_ENTITIES_RECEIVED,
} = compare2Things;

const initialState = {
  brandByCategory: [],
  brands: {},
  categories: [],
  entities: {},
  games: {},
  influencers: {},
  roster_owners: {},
};

export default (state: Compare2ThingsState = initialState, action) => {
  switch (action.type) {
    case ENTITIES_RECEIVED: {
      const { entities } = action.payload;
      const newEntities = {};

      Object.keys(entities).forEach(key => {
        newEntities[key] = sortEntityList(
          entities[key],
          appConfig.entityTypes,
          key,
          key,
        );
      });

      return {
        ...state,
        ...newEntities,
      };
    }
    case ADDITIONAL_ENTITIES_RECEIVED: {
      const type = get(action.payload, ['type']);
      const entities = get(action.payload, ['entities', type]);
      const sorted = sortEntityList(
        entities,
        appConfig.entityTypes,
        type,
        type,
      );

      return {
        ...state,
        [type]: [...state[type], ...sorted],
      };
    }
    case RELATED_ENTITIES_RECEIVED:
      const slideoutPaths = get(action.payload, ['paths'], []);
      // if the first path is disabled, then it's a roster of an org or roster_owner coming from a universe component slideout
      const isUniverseSlideout = get(slideoutPaths, [0, 'disabled']);
      // current active path to be updated
      const activeIndex = slideoutPaths.findIndex(x => x.active);
      // create an array of the previous paths and current path types (or id's)
      const slideoutPathsIds = slideoutPaths
        .filter((x, i) => i <= activeIndex)
        .map((x, i) => (i === 0 ? x.type : x.id));
      // create array of ALL paths types
      const slideoutPathsTypes = slideoutPaths.map(x => x.type);
      // current category
      const category = get(action, ['payload', 'category']);
      // current entityState[category]
      let clonedEntities = cloneDeep(get(state, [category]));
      // related entities that were returned from API
      const entitiesList = get(action.payload, ['entities', 0], []);
      // structure of current entityState[category]
      const structureArr = cloneDeep(
        get(appConfig, ['entityTypes', category, 'structure']),
      ).map(x => x.type);

      const nextType = get(action.payload, ['nextType']);
      // will store the sorted list of entities
      const returnedEntities = {};

      // sorts entitiesList, also will add twitter handles to aliases, remove unused twitter values and sets jumpToPaths
      action.payload.entities.forEach(entity =>
        Object.keys(entity).forEach(key => {
          returnedEntities[key] = sortEntityList(
            entity[key],
            appConfig.entityTypes,
            key,
            category,
          );
        }),
      );

      // if no related types were returned, then create a single entity with name and an error message
      if (!entitiesList[nextType] || entitiesList[nextType].length === 0) {
        returnedEntities[nextType] = [
          {
            name: `No related ${get(
              structureArr,
              [slideoutPathsIds.length - 1],
              'entities',
            )} were found.`,
          },
        ];
      }

      if (
        category === 'games' ||
        (category === 'roster_owners' && !isUniverseSlideout)
      ) {
        // eg: games.leagues or roster_owners.rosters
        if (slideoutPathsIds.length === 2) {
          // find index of incoming related entities to add them to
          const incomingIndex = clonedEntities.findIndex(
            x => x.id === slideoutPathsIds[1],
          );

          // add the returned related entities to the active item
          // eg: games[1].leagues = { ...currentItemsProps, ...returnedEntities }
          clonedEntities[incomingIndex] = {
            ...clonedEntities[incomingIndex],
            ...returnedEntities,
          };
          // eg: games.leagues.rosters
        } else if (slideoutPathsIds.length === 3) {
          // find the first child to the parent, store index
          const firstIndex = clonedEntities.findIndex(
            x => x.id === slideoutPathsIds[1],
          );
          // find second child's index
          const incomingIndex = clonedEntities[firstIndex][
            slideoutPathsTypes[1]
          ].findIndex(x => x.id === slideoutPathsIds[2]);

          // add the returned entities to the active item
          // eg: games[1].leagues[12].rosters = { ...currentItemsProps, ...returnedEntities }
          // could be: games[Football].leagues[NFL].rosters = { all the NFL teams }
          clonedEntities[firstIndex][slideoutPathsTypes[1]][incomingIndex] = {
            ...clonedEntities[firstIndex][slideoutPathsTypes[1]][incomingIndex],
            ...returnedEntities,
          };
          // eg: games.leagues.rosters.roster_members
        } else if (slideoutPathsIds.length === 4) {
          // find the first child to the parent, store index
          const firstIndex = state[category].findIndex(
            x => x.id === slideoutPathsIds[1],
          );
          // find the second child to the parent, store index
          const secondIndex = state[category][firstIndex][
            slideoutPathsTypes[1]
          ].findIndex(x => x.id === slideoutPathsIds[2]);
          // find the third child's index (current item), store index
          const incomingIndex = state[category][firstIndex][
            slideoutPathsTypes[1]
          ][secondIndex][slideoutPathsTypes[2]].findIndex(
            x => x.id === slideoutPathsIds[3],
          );

          // add returned entities to active item
          // eg: games[1].leagues[12].rosters[5].roster_members = { ...currentItemsProps, ...returnedEntities}
          // could be: games[Football].leagues[NFL].rosters[Patriots].roster_members = { all patriots players }
          clonedEntities[firstIndex][slideoutPathsTypes[1]][secondIndex][
            slideoutPathsTypes[2]
          ][incomingIndex] = {
            ...clonedEntities[firstIndex][slideoutPathsTypes[1]][secondIndex][
              slideoutPathsTypes[2]
            ][incomingIndex],
            ...returnedEntities,
          };
        }
      } else if (isUniverseSlideout) {
        if (nextType === 'rosters') {
          const newRosters = get(action.payload, ['entities', 0, nextType], []);
          const parent = get(action.payload, ['paths', 0]);
          clonedEntities = [{ ...parent, rosters: newRosters }];
        } else if (nextType === 'roster_members') {
          const members = get(action.payload, ['entities', 0, nextType], []);
          const team = get(action.payload, ['paths', 2]);
          const currIndex = get(clonedEntities, [0, 'rosters']).findIndex(
            x => get(x, ['id']) === get(team, ['id']),
          );
          clonedEntities[0].rosters[currIndex] = {
            ...clonedEntities[0].rosters[currIndex],
            roster_members: members,
          };
        }
      }

      return {
        ...state,
        [category]: clonedEntities
          ? clonedEntities.length
            ? [...clonedEntities]
            : clonedEntities
          : {},
      };
    case ORG_ENTITIES_RECEIVED:
      const orgsIndex = get(action.payload, ['entities'], []).findIndex(x =>
        get(x, ['orgs']),
      );
      const orgs = get(action.payload, ['entities', orgsIndex, 'orgs'], []);
      const rosterOwnersIndex = get(
        action.payload,
        ['entities'],
        [],
      ).findIndex(x => get(x, ['roster_owners']));
      // structure of current entityState[category]
      const strucArr = cloneDeep(
        get(appConfig, ['entityTypes', 'orgs', 'structure']),
      ).map(x => x.type);
      const slidePaths = get(action.payload, ['paths'], []);
      // current active path to be updated
      const activeI = slidePaths.findIndex(x => x.active);
      // create an array of the previous paths and current path types (or id's)
      const slideoutIds = slidePaths
        .filter((x, i) => i <= activeI)
        .map((x, i) => (i === 0 ? x.type : x.id));

      const rosterOwners = get(
        action.payload,
        ['entities', rosterOwnersIndex, 'roster_owners'],
        [],
      );

      let newOrgs;

      if (orgs.length === 0 && rosterOwners.length === 0) {
        newOrgs = [
          {
            name: `No related ${get(
              strucArr,
              [slideoutIds.length - 1],
              'entities',
            )} were found.`,
          },
        ];
      } else if (isEqual(orgs, rosterOwners)) {
        newOrgs = [...orgs];
      } else {
        // join the two arrays and de-dupe
        newOrgs = unionBy(orgs, rosterOwners, 'id');
      }

      const rostersIndex = get(action.payload, ['entities'], []).findIndex(x =>
        get(x, ['rosters']),
      );

      if (rostersIndex !== -1) {
        // means this org owns rosters but skips roster owner (ex: immortals gaming => MiBR)
        const rosters = get(
          action.payload,
          ['entities', rostersIndex, 'rosters'],
          [],
        );

        newOrgs = [...newOrgs, ...rosters].filter(x => get(x, ['id']));
      }

      return {
        ...state,
        roster_owners: newOrgs,
      };
    case RELATED_ORG_ENTITIES_RECEIVED:
      const cat = get(action.payload, ['category']);
      const clonedEnts = get(state, [cat], []);
      const newType = get(action.payload, ['nextType']);
      let rosterEntities = get(action.payload, ['entities', 0, newType], []);
      rosterEntities = rosterEntities.length
        ? rosterEntities
        : [
            {
              name: `No related ${newType} were found.`,
            },
          ];
      // @ts-ignore
      const index = clonedEnts.findIndex(
        x => x.id === get(action.payload, ['id']),
      );
      clonedEnts[index] = { ...clonedEnts[index], [newType]: rosterEntities };
      return {
        ...state,
        [cat]: [...clonedEnts],
      };
    case UNIVERSE_ENTITIES_RECEIVED:
      return {
        ...state,
        roster_owners: get(action.payload, ['entities'], []),
      };
    case RESET_UNIVERSE_ENTITIES:
      return {
        ...state,
        roster_owners: {},
      };
    case TOP_LEVEL_CATEGORIES_RECEIVED:
      return {
        ...state,
        categories: action.payload,
      };
    case SUB_CATEGORIES_RECEIVED:
      return {
        ...state,
        categories: action.payload,
      };
    case TOP_LEVEL_BRAND_BY_CATEGORY_RECEIVED:
      return {
        ...state,
        brandByCategory: action.payload,
      };
    case BRAND_BY_CATEGORY_SUB_CATEGORIES_RECEIVED:
      return {
        ...state,
        brandByCategory: action.payload,
      };
    default:
      return state;
  }
};
