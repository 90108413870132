import React, { useCallback, useEffect, useState } from 'react';
import Toast from 'atoms/toast';
import useToast from 'hooks/useToast';
import { Nullable, ToastInterface } from 'types';

/**
 * Displays toast messages
 *
 * The Toaster component displays messages from the useToast hook's queue
 * on by one.
 *
 * @example
 * return (
 *   <Toaster />
 * );
 */
const Toaster: React.FC = () => {
  const { pop } = useToast();
  const [toast, setToast] = useState<Nullable<ToastInterface>>(null);

  useEffect(() => {
    const nextToast = pop();
    if (nextToast) {
      setToast(nextToast);
    }
  }, [pop, toast]);

  const onDismiss = useCallback(() => {
    setToast(null);
  }, []);

  return <>{toast && <Toast {...toast} onDismiss={onDismiss} />}</>;
};

export default Toaster;
