import axios, { AxiosInstance } from 'axios';
import axiosRetry, { isNetworkError, isRetryableError } from 'axios-retry';
import { interceptPendingRequests } from './interceptors';

/**
 * Default custom axios client to use.
 * Complies to retry policy as per https://app.nuclino.com/FanAI/Tech/Tech-Spec-Retrying-Failures-c22323e6-f710-495a-98fe-11ed27921230
 */
const axiosInstance = axios.create();

axiosRetry(axiosInstance, {
  retries: 4,
  retryDelay: (retryNum: number) => {
    const delay = Math.pow(2, retryNum - 1) * 1000; // exponential delay in ms
    const jitter = (Math.random() - 0.5) * 1000; // random number between -500 and 500
    return delay + jitter;
  },
  retryCondition: error => {
    return isNetworkError(error) || isRetryableError(error); // only retries on network errors or 5xx errors
  },
});
interceptPendingRequests(axiosInstance);

export default axiosInstance;
