import appConfig from 'config/appConfig'
import config from 'config/appConfig'
import { CheckIsItemSelected } from 'pages/sponsorshipSales/index.d'
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import has from 'lodash/has'
import some from 'lodash/some'
import spread from 'lodash/spread'
import { default as _union } from 'lodash/union'
import { default as _unionBy } from 'lodash/unionBy'
import {
  EntitiesList,
  Entity,
  EntityCategories,
  EntityCount,
  EntitySmall,
  EntityTypes,
  namingPrecedence,
  SavedEntity,
  viewsQueryParam
} from 'types'
import { isArray } from 'util'
import { sortAlphabetically } from 'utils/sorting'
import { capitalize } from 'utils/strings'
import { isEmpty } from 'utils/validations'
import endpoints from 'config/api'

/**
 * each column in the flyout can have the following properties
 * 1. a column title
 * 2. children that make up the column
 * 3. if a child is grouped then it will have children as well
 *
 *
 * so for games the columns would look like
 *
 * 1 - title null
 *   - children (games)
 *   - children (leagues)
 *
 * 2 - title Teams
 *   - children (teams)
 *
 * 3 - title Roster
 *   - children (players)
 *
 * for team_owners the columns would look like
 *
 * 1 - title owners
 *   - children (owners)
 *
 * 2 - title Game
 *   - children (games)
 *   - children (teams)
 *
 * 3 - title Roster
 *   - children (players)
 */
export const normalize = (entities, keyMap, index = 0) => {
  const normalizedEntity = { ...keyMap[index] }
  const sortAlpha = sortAlphabetically('name')
  const nameNormalization = normalizeName(appConfig.entityTypes)
  const newEntities = []
  const entitiesCache = {}

  if (isArray(entities)) {
    entities.forEach(entity => {
      if (entity.name === null || entity.name === '') {
        return
      }

      const newEntity = cloneDeep(entity)
      const keyPath = keyMap[index]

      if (!keyPath) {
        return
      }

      const children = get(newEntity, keyPath.groupBy || keyPath.type)

      const entityProp = keyPath.groupBy ? 'grouping' : 'children'

      if (children) {
        if (entityProp === 'children') {
          newEntity.children = children
            .filter(c => c.name !== null && c.name !== '')
            .map(nameNormalization)
            .sort(sortAlpha)

          delete newEntity[keyPath.type]

          newEntities.push({
            newEntity
          })
        } else {
          delete entity[keyPath.groupBy]

          newEntities.push({
            ...entity,
            heading: true,
            type: keyPath.type
          })

          entitiesCache[entity.id] = children
            .filter(c => c.name !== null && c.name !== '')
            .map(c => ({
              isGrouped: true,
              type: keyPath.groupBy,
              ...c
            }))
            .map(nameNormalization)
            .sort(sortAlpha)
        }
      } else {
        if (entity.name !== null && entity.name !== '') {
          newEntities.push({ ...entity, type: keyPath.type })
        }
      }
    })

    normalizedEntity.children = flatten(
      newEntities
        .map(nameNormalization)
        .sort(sortAlpha)
        .map(e => (entitiesCache[e.id] ? [e, ...entitiesCache[e.id]] : e))
    )

    return normalizedEntity
  } else {
    if (entities.name === null || entities.name === '') {
      return
    }

    const newEntity = cloneDeep(entities)
    const keyPath = keyMap[index]
    if (!keyPath) {
      return
    }
    const children = get(newEntity, keyPath.type)
    const entityProp = keyPath.groupBy ? 'grouping' : 'children'
    if (children) {
      if (entityProp === 'children') {
        newEntity[keyPath.type] = {
          ...keyPath,
          children: [...children]
            .filter(c => c.name !== null && c.name !== '')
            .map(c => ({
              ...c,
              type: keyPath.type
            }))
            .map(nameNormalization)
            .sort(sortAlpha)
        }
      } else {
        // if groupBy then loop over all children to form the grouping
        newEntity[keyPath.type] = {
          ...keyPath,
          children: [
            ...formGrouping(
              children.filter(c => c.name !== null && c.name !== ''),
              keyPath
            )
          ]
        }
      }
    }
    return newEntity
  }
}

const formGrouping = (entities, keyPath) => {
  const sortAlpha = sortAlphabetically('name')
  const nameNormalization = normalizeName(appConfig.entityTypes)
  let grouping = []

  entities.forEach(c => {
    grouping.push({
      type: keyPath.type,
      ...c
    })

    let childInstances = get(c, keyPath.groupBy)
      .filter(c => c.name !== null && c.name !== '')
      .map(c => ({
        isGrouped: true,
        type: keyPath.groupBy,
        ...c
      }))
      .sort(nameNormalization)

    childInstances =
      childInstances.length <= 1
        ? childInstances
        : childInstances.sort(sortAlpha)

    grouping = [...grouping, ...childInstances]
  })

  return grouping
}

/**
 * Function that checks if entity has a clearbit domain available to set as profile_image_url. If available
 * returns the img src url in clearbit else will return the original profile_image_url
 */
export const getEntityImgUrl = (entity: Entity | SavedEntity): string => {
  return entity?.domain
    ? `${endpoints.clearbit}/${entity?.domain}`
    : entity?.profile_image_url
}

/**
 * Function that performs a lookup of a given property within a given object and returns that propertie's value.
 *
 * @param {object} item - entity object to perform the property lookup
 * @param {array} rules - array of rules that specify the precedence of each property as the lookup is performed
 * @memberof Flyout
 * @returns {object} - value found at the spcified lookup path on the the given object
 */
export const getName = (item: Entity, rules: namingPrecedence[]) => {
  // find returns as soon as the property is located on the object as the precedence
  // array is iterated over
  let name
  const prop = rules.find(p => {
    name = get(item, p)
    return name
  })
  // return the value located at that keypath
  return name
}

/**
 * Function that normalizes the name property of an object based on the given naming precedence of multiple
 * optional properties the object may have
 *
 * @param {object} entityTypes - entity configuration used to specify the namining precedence of an entity
 * @memberof Flyout
 * @returns {function} - closure that receives the entity to be normalized
 */
export const normalizeName = (entityTypes: EntityTypes) => (
  item: Entity
): Entity => {
  const name = getName(
    item,
    entityTypes[item.type].namePrecedence || entityTypes.default.namePrecedence
  )

  return {
    ...item,
    name
  }
}

export const getFollowerCounts = (entities, counts: EntityCount[]) => {
  const newEntities = cloneDeep(entities)
  const entity1 = findEntity(counts, newEntities.entity1)
  const entity2 = findEntity(counts, newEntities.entity2)
  // If an entity is selected, grab the exclusive_count value and save it to newEntities
  if (entity1) {
    if (!isEmpty(entity1.exclusive_count)) {
      newEntities.entity1.uniqueFollowers = entity1.exclusive_count
    }

    if (!isEmpty(entity1.intersection_count)) {
      newEntities.entity1.followers = entity1.intersection_count
    }
  }

  if (entity2) {
    if (!isEmpty(entity2.exclusive_count)) {
      newEntities.entity2.uniqueFollowers = entity2.exclusive_count
    }

    if (!isEmpty(entity2.intersection_count)) {
      newEntities.entity2.followers = entity2.intersection_count
    }
  }

  let sharedFollowers = null

  if (
    entities.entity1 &&
    entities.entity2 &&
    entities.entity1.id === entities.entity2.id &&
    counts.length === 1
  ) {
    sharedFollowers = counts[0].intersection_count
  } else {
    const sharedCounts = (counts || []).find(c => c.entity_objs.length === 2)
    sharedFollowers =
      sharedCounts && !isEmpty(sharedCounts.intersection_count)
        ? sharedCounts.intersection_count
        : null
  }

  return { selectedEntities: newEntities, sharedFollowers }
}

export const findEntity = (
  counts: EntityCount[],
  entity: Entity
): EntityCount => {
  if (!entity || !counts || !counts.length) {
    return
  }

  // edge case if both entities have the same ID, but one includes universe,
  // and one does not, then return the entity that matches the universe state
  if (entity.universe === true || autoSetUniverse(entity)) {
    return counts.find(
      c =>
        c.entity_objs.length === 1 &&
        c.entity_objs[0].entity_id === entity.id &&
        c.entity_objs[0].view === 'universe'
    )
  }
  return counts.find(
    c =>
      c.entity_objs.length === 1 &&
      c.entity_objs[0].entity_id === entity.id &&
      c.entity_objs[0].view !== 'universe'
  )
}

/**
 * function used to remove unused values that we do not want saved in the db
 *
 * @param {obj} - { entity1, entity2 }      - derived from selectedEntities
 */
export const prepEntityForUpdate = ({ entity1, entity2 }: EntitiesList) => {
  const copyEntity1 = { ...entity1 }
  const copyEntity2 = { ...entity2 }

  delete copyEntity1.followers
  delete copyEntity1.historical
  delete copyEntity1.twitter
  delete copyEntity2.followers
  delete copyEntity2.historical
  delete copyEntity2.twitter

  return { entity1: copyEntity1, entity2: copyEntity2 }
}

/**
 * function used to return boolean if it includes a follower twitter array
 * @param {obj} selectedEntities
 * @param {string } keyPath
 * @return boolean
 */
export const includesTwitter = (
  selectedEntities: EntitiesList,
  keyPath: string
): boolean => {
  const twitter = get(selectedEntities, [keyPath, 'twitter'], false)
  return twitter && twitter !== null && twitter.length
}

/**
 * function used to return boolean if it includes a follower count
 * @param {obj} selectedEntities
 * @param {string} keyPath - 'entity1' or 'entity2'
 * @return {boolean}
 */
export const includesFollowers = (
  selectedEntities: EntitiesList,
  keyPath: string
): boolean => get(selectedEntities, [keyPath, 'followers'], false)

/**
 * function used to return boolean if it includes a universe or views with universe
 * @param {obj} selectedEntities
 * @param {string } keyPath
 * @return boolean
 */
export const includesUniverse = (
  selectedEntities: EntitiesList,
  keyPath: string
): boolean => get(selectedEntities, [keyPath, 'views'], []).includes('universe')

/**
 * function used to return boolean if it includes an entity ID
 * @param {obj} selectedEntities
 * @param {string} keyPath
 * @return {boolean}
 */
export const includesEntityID = (
  selectedEntities: EntitiesList,
  keyPath: string
): boolean =>
  selectedEntities && selectedEntities[keyPath] && selectedEntities[keyPath].id

export const buildEntityNames = (
  entities: any = {},
  relationshipNames = { entity1: '', entity2: '' },
  reversed = false
) => {
  if (entities.entity1 && entities.entity2) {
    if (!reversed) {
      return {
        entity1: entities.entity1.name || '',
        entity2: entities.entity2.name || ''
      }
    } else {
      return {
        entity1: entities.entity2.name || '',
        entity2: entities.entity1.name || ''
      }
    }
  } else if (entities.entity1 && !entities.entity2) {
    if (!reversed) {
      return {
        ...relationshipNames,
        entity1: entities.entity1.name || ''
      }
    } else {
      return {
        ...relationshipNames,
        entity2: entities.entity1.name || ''
      }
    }
  } else if (entities.entity2 && !entities.entity1) {
    if (!reversed) {
      return {
        ...relationshipNames,
        entity2: entities.entity2.name || ''
      }
    } else {
      return {
        ...relationshipNames,
        entity1: entities.entity2.name || ''
      }
    }
  } else {
    return {}
  }
}

export const isReversed = (selectedEntities): boolean => {
  return (
    selectedEntities &&
    selectedEntities.entity2 &&
    selectedEntities.entity2.name &&
    (!selectedEntities.entity1 || !selectedEntities.entity1.name)
  )
}

export const sanitizeEntity = ({
  categories = [],
  id,
  name,
  universe = null,
  type
}: {
  categories: EntityCategories[]
  id: string
  name: string
  type: string
  universe: boolean | null
}) => ({
  categories,
  id,
  name,
  type: type || 'entities',
  universe
})

export const getAlternateEntity = type =>
  type === 'entity1' ? 'entity2' : 'entity1'

export const hasBothEntities = entities => {
  const entity1 = get(entities, ['entity1', 'id'])
  const entity2 = get(entities, ['entity2', 'id'])

  return !!(entity1 && entity2)
}

export const didEntityChange = (entities = {}, prevEntities = {}): boolean => {
  const { entity1, entity2 }: any = entities
  const { entity1: prevEntity1, entity2: prevEntity2 }: any = prevEntities || {}

  return (
    (entity1 && !prevEntity1) ||
    (entity2 && !prevEntity2) ||
    (entity1 && prevEntity1 && entity1.id && entity1.id !== prevEntity1.id) ||
    (entity2 && prevEntity2 && entity2.id && entity2.id !== prevEntity2.id) ||
    (entity1 && prevEntity1 && entity1.universe !== prevEntity1.universe) ||
    (entity2 && prevEntity2 && entity2.universe !== prevEntity2.universe)
  )
}

export const hasEntity = (entities = {}) => {
  const entity1 = get(entities, ['entity1', 'id'])
  const entity2 = get(entities, ['entity2', 'id'])

  return !!(entity1 || entity2)
}

export const whichEntityChanged = (prevEntities, nextEntities) => {
  const { entity1, entity2 }: any = nextEntities
  const { entity1: prevEntity1, entity2: prevEntity2 }: any = prevEntities || {}

  const changedEntities = []
  if (
    entity1 &&
    (entity1.id !== prevEntity1.id || entity1.universe !== prevEntity1.universe)
  ) {
    changedEntities.push({ entity: entity1, type: 'entity1' })
  }

  if (
    entity2 &&
    (entity2.id !== prevEntity2.id || entity2.universe !== prevEntity2.universe)
  ) {
    changedEntities.push({ entity: entity2, type: 'entity2' })
  }

  return changedEntities
}

/**
 * function used to create and array of objects. Each object is a path (level in the category structure)
 * sets the value active to whichever the index is
 * eg: if activeIndex is 2, and user is on category games. second breadcrumb would be { active: true, path: leagues, type: leagues}, ...etc }
 *
 * @param {*} category
 * @param {number} [activeIndex]
 * @returns {[
 *   { id: number; path: string; title: string; type: string; active?: boolean }
 * ]}
 */
export const createEntitiesBreadcrumbs = (
  category: any,
  activeIndex?: number
): [
  { id: string; path: string; title: string; type: string; active?: boolean }
] => {
  const catID = get(category, ['id'])
  const structure = cloneDeep(
    get(appConfig, ['entityTypes', catID, 'structure'])
  )

  if (catID === 'roster_owners' && structure[0].type === 'orgs') {
    // fix bug where coming from Universe Component sometimes had orgs as first path. Here we want to remove that.
    structure.splice(0, 1)
  }
  return structure.map((x, i) => ({
    ...x,
    active: i === activeIndex,
    index: i,
    path: x.type ? x.type : x.title
  }))
}

export const getMetaData = obj => {
  const {
    name,
    version_id,
    updated_at,
    _key,
    created_at,
    _id,
    id,
    collectionType,
    key,
    of,
    till,
    since,
    url,
    _rev,
    types,
    type,
    _from,
    _to,
    started_at,
    ended_at,
    aliases,
    direction,
    ...rest
  } = obj
  return { ...rest }
}

export const buildMetaData = metadata => {
  return Object.keys(metadata).map(k => ({
    [k]: metadata[k]
  }))
}

export const flattenMetaData = metadata => {
  const data = {}
  metadata.forEach(k => {
    const key = Object.keys(k).pop()
    data[key] = k[key]
  })
  return data
}

/**
 * function used to return the name of an entity based on it being a custom group, or universe
 * @param {obj} entity
 * @return string
 */
export const buildTwitterAudienceEntityName = entity => {
  if (includesUniverse(entity, 'views')) {
    return `${entity.name}'s Universe`
  } else {
    return `${entity.name}`
  }
}

export const hasUniverse = (entity): boolean => {
  const views = get(entity, ['views'])

  if (views && views.includes('universe')) {
    return true
  } else if (entity && entity.universe) {
    return true
  }

  return false
}

export const hasTwitter = (entity): boolean => {
  const twitter = get(entity, ['twitter'], false)
  return twitter && twitter !== null && twitter.length
}

export const hasFollowers = (entity): boolean => entity.followers

export const autoSetUniverse = (entity): boolean =>
  entity &&
  hasUniverse(entity) &&
  has(entity, 'twitter') &&
  (entity.twitter === null || !entity.twitter.length)

export const addUniverse = entity => {
  if (autoSetUniverse(entity)) {
    return { ...entity, universe: true }
  }

  return { ...entity }
}

/**
 * function used to find where in the structure the user is and what endpoint they should retrieve
 *
 * @param {*} entityType - can be either a string (would be entities, games, influencers, roster_owners, or brands) or as an array
 * @returns string
 */
export const setEntityEndpointPath = entityType => {
  if (typeof entityType === 'string') {
    return get(
      appConfig.compare2ThingsCategories,
      [entityType, 'endpoint'],
      'publicEntities'
    )
  } else {
    return entityType.map(x =>
      get(appConfig.compare2ThingsCategories, [x, 'endpoint'], 'publicEntities')
    )
  }
}

export const mapCachedEndpoints = cachedEndpoints => {
  const newObj = { ...cachedEndpoints }
  for (const key of Object.keys(cachedEndpoints)) {
    newObj[appConfig.compare2ThingsCategories[key].endpoint] =
      cachedEndpoints[key]
    delete newObj[key]
  }
  return newObj
}

export const hasEntityIds = (selectedEntities = {}) => {
  if (!selectedEntities) return false
  const keys = Object.keys(selectedEntities)
  return keys.every(k => selectedEntities[k].id)
}

export const formEntityQueryString = (entities = {}) => {
  if (!entities) {
    return ''
  }
  const keys = Object.keys(entities)

  return keys.map(k => entities[k].id).join(',')
}

/**
 * function used to take a string, split it at the underscore and capitalize the first letters. If the type is 'org', we set the string to the fill word.
 *
 * @param {string} type
 * @returns {string}
 */
export const setType = (type: string): string =>
  type
    ? type
        .split('_')
        .map(x => (x === 'org' ? capitalize('organization') : capitalize(x)))
        .join(' ')
    : null
/**
 * function used to take the twitter array (from /twitter-audience/twitter_users), and total the follower_counts, incase the entity has multiple twitter accounts or is array of universe accounts
 *
 * @private
 * @memberof ContentPlanningDashboard
 */
export const sumFollowers = twitterArr => {
  return twitterArr.reduce(
    (acc, cur) => acc + get(cur, ['followers_count'], 0),
    0
  )
}

/**
 * function used to find which of the activeListsIndices or array of crumbs, is currently active. Also checks that the indice/crumb is not disabled
 *
 * @private
 * @memberof ComparisonSelection
 */
export const findActiveIndex = arr =>
  arr &&
  arr.length &&
  arr.findIndex(x => get(x, ['active']) && !get(x, ['disabled']))

export const buildHistoricalEndpoint = (
  startDate: string,
  dateSpacing: number,
  endpoint: string,
  includeUniverse?: boolean
) => entities =>
  `${endpoint}?entities=${
    Array.isArray(entities) ? entities.map(e => e.id).join(',') : entities.id
  }&start_date=${startDate}&date_spacing=${dateSpacing}${
    includeUniverse
      ? `&views=` + Array.isArray(entities)
        ? entities
            .map(e =>
              autoSetUniverse(e) || (e && e.universe) ? 'universe' : null
            )
            .join(',')
        : autoSetUniverse(entities) || (entities && entities.universe)
        ? 'universe'
        : null // eslint-disable-line
        ? 'universe'
        : null
      : ''
  }&platform=twitter`

/**
 * Function that takes an entity, finds it's structure from the app config. Returns the top most type in the structure.
 *
 * @param {SavedEntity} entity
 * @returns {string} Type
 */
export const setEntityType = (entity: SavedEntity): string => {
  const structure = cloneDeep(
    get(config, ['entityTypes', 'roster_owners', 'structure'])
  )
  const typesArr = get(entity, ['types'], []).filter(x => x !== 'roster')

  if (entity && typesArr.length) {
    // if this entity has a type ORG, then we need to add org as top level to the structure
    if (typesArr.includes('org')) {
      structure.unshift({ title: 'Org', type: 'orgs' })
      if (typesArr[0] !== 'org') {
        // this fixes the bug where types array would return unsorted or sorted some times. This will check if the entity is an org, it was always put org at the front of typesArr.
        // So when the index is identified (below), we make sure org is set as the type
        typesArr.splice(typesArr.indexOf('org'), 1)
        typesArr.unshift('org')
      }
    }

    // strange case where the user switched client ID's and was coming from an org, it cached the structure with an org
    if (
      structure.filter(x => x.type === 'orgs').length === 1 &&
      !typesArr.includes('org')
    ) {
      structure.shift()
    }

    // find all index', the smallest index is the top most type
    const typeIndex = Math.min(
      ...typesArr
        // @ts-ignore
        .map(x => structure.findIndex((y, i) => y.type === x + 's'))
        .filter(z => z >= 0)
    )

    return typesArr[typeIndex]
  } else {
    return null
  }
}

/**
 * Utility function that will return the children from a given entity. It will search through each entity type (roster_owners, rosters, roster_members) and return the first list it finds
 * if none, will return an empty list
 *
 * @param {SavedEntity} entity
 * @returns {SavedEntity[]}
 */
export const setListOfChildren = (entity: SavedEntity) => {
  return new Promise(resolve => {
    if (get(entity, ['roster_owners'])) {
      return resolve(get(entity, ['roster_owners']))
    } else if (get(entity, ['rosters'])) {
      return resolve(get(entity, ['rosters']))
    } else if (get(entity, ['roster_members'])) {
      return resolve(get(entity, ['roster_members']))
    } else {
      return []
    }
  })
}

export const entityListItemHasChildren = (entity: SavedEntity): boolean =>
  !!(
    get(entity, ['roster_members'], []).length ||
    get(entity, ['roster_owners'], []).length ||
    get(entity, ['rosters'], []).length
  )

/**
 * Function used for the Multi-Select Category Slideouts.
 * Will check if a category is either implicitly selected or explicitly selected
 *
 * Implicitly selected implies that the category is not selected, however, an ancestor is selected
 *
 * Explicitly selected implies that the category is selected
 *
 * @param {{
 *   id: string;
 *   categoriesActiveIndices: number[];
 *   categoriesList: SavedEntity[];
 *   selectedCategories: Array<{ id: string; name: string }>;
 * }} {
 *   id,
 *   categoriesActiveIndices = [],
 *   categoriesList = [],
 *   selectedCategories = [],
 * }
 * @returns {CheckIsItemSelected}
 */
export const setIsCategorySelected = ({
  categoriesActiveIndices = [],
  categoriesList = [],
  id = '',
  selectedCategories = []
}: {
  categoriesActiveIndices: number[]
  categoriesList: SavedEntity[]
  id: string
  selectedCategories: EntitySmall[]
}): CheckIsItemSelected => {
  const isExplicitlySelected = setIsExplicitlySelected({
    id,
    selectedCategories
  })

  if (isExplicitlySelected) {
    // If I'm selected, end here
    return {
      isAncestorSelected: false,
      isDescendantSelected: false,
      isExplicitlySelected,
      totalSelectedChildren: 0
    }
  }

  // Since I'm not selected, we'll first check if an ancestor is selected
  const isAncestorSelected = setIsAncestorSelected({
    categoriesActiveIndices,
    categoriesList,
    selectedCategories
  })

  if (isAncestorSelected) {
    return {
      isAncestorSelected,
      isDescendantSelected: false,
      isExplicitlySelected: false,
      totalSelectedChildren: 0
    }
  }

  // finally we'll check if a descendant is selected
  const { currentEntityInLoop, isDescendantSelected } = setIsDescendantSelected(
    {
      categoriesActiveIndices,
      categoriesList,
      id,
      selectedCategories
    }
  )

  const totalSelectedChildren = isDescendantSelected
    ? setNumberOfSelectedChildren({
        currentEntityInLoop,
        selectedCategories
      })
    : 0

  return {
    isAncestorSelected,
    isDescendantSelected,
    isExplicitlySelected,
    totalSelectedChildren
  }
}

/**
 * Function that will verify if the given ID is included in the selectedCategories list.
 *
 * @param {{ selectedCategories: EntitySmall[]; id: string }} { selectedCategories = [], id = '' } { selectedCategories, id }
 * @returns {boolean}
 */
export const setIsExplicitlySelected = ({
  selectedCategories = [],
  id = ''
}: {
  selectedCategories: EntitySmall[]
  id: string
}): boolean => some(selectedCategories, item => get(item, ['id']) === id)

/**
 *  Function that will check if any ancestors (parent, grand parent, great grand parent, ...) are included in the selected categories list
 *
 * @param {*} {
 *   categoriesActiveIndices,
 *   categoriesList,
 *   selectedCategories,
 * }
 * @returns {boolean}
 */
export const setIsAncestorSelected = ({
  categoriesActiveIndices,
  categoriesList,
  selectedCategories
}): boolean => {
  const selectedIds = []
  if (categoriesActiveIndices.length) {
    let currentEntityInLoop = cloneDeep(
      get(categoriesList, [get(categoriesActiveIndices, [0])])
    )
    // create an array of all parents / grandparents id's
    categoriesActiveIndices.forEach((x, i) => {
      selectedIds.push(
        get(currentEntityInLoop, i === 0 ? ['id'] : ['subCategories', x, 'id'])
      )
      currentEntityInLoop =
        i === 0
          ? currentEntityInLoop
          : get(currentEntityInLoop, ['subCategories', x])
    })

    // create an array of boolean values, true === a parent was explicitly selected
    return some(
      selectedIds.map(x =>
        some(selectedCategories, item => get(item, ['id']) === x)
      ),
      x => x === true
    )
  } else {
    return false
  }
}

/**
 * Function that will check if a descendent (child, grand child, ...) is included in selected categories list
 *
 *
 * @param {{
 *   id: string;
 *   categoriesList: SavedEntity[];
 *   categoriesActiveIndices: number[];
 *   selectedCategories: EntitySmall[];
 * }} {
 *   categoriesList,
 *   categoriesActiveIndices,
 *   selectedCategories,
 *   id,
 * }
 * @returns {{ currentEntityInLoop: SavedEntity; isDescendantSelected: boolean }}
 */
export const setIsDescendantSelected = ({
  categoriesActiveIndices = [],
  categoriesList = [],
  id = '',
  selectedCategories = []
}: {
  categoriesActiveIndices: number[]
  categoriesList: SavedEntity[]
  id: string
  selectedCategories: EntitySmall[]
}): { currentEntityInLoop: SavedEntity; isDescendantSelected: boolean } => {
  const myIndex = categoriesList.findIndex(x => get(x, ['id']) === id)

  if (categoriesActiveIndices.length) {
    // create paths to me
    const myPaths = setPathsToCurrentList(categoriesActiveIndices)

    // @ts-ignore
    const myIndex = get(categoriesList, myPaths, []).findIndex(
      x => get(x, ['id']) === id
    )

    const descendentSelectedIds = setArrayOfSelectedDescendentIds({
      // @ts-ignore
      myChildren: get(
        categoriesList,
        [...myPaths, myIndex, 'subCategories'],
        []
      ),
      selectedCategories
    })

    // create an array of boolean values, true === a parent was explicitly selected
    return {
      // @ts-ignore
      currentEntityInLoop: get(categoriesList, [...myPaths, myIndex], []),
      isDescendantSelected: !!descendentSelectedIds.length
    }
  } else {
    // Top Level, need to go through entire tree to see if children are selected
    const myChildren = get(categoriesList, [myIndex, 'subCategories'], [])

    if (myChildren.length) {
      // entity has children retrieved. need to check each one
      const descendentSelectedIds = setArrayOfSelectedDescendentIds({
        myChildren,
        selectedCategories
      })

      return {
        currentEntityInLoop: get(categoriesList, [myIndex]),
        isDescendantSelected: !!descendentSelectedIds.length
      }
    }

    return {
      currentEntityInLoop: get(categoriesList, [myIndex]),
      isDescendantSelected: false
    }
  }
}

/**
 * Function that will return the number of DIRECT children that are included in the selected categories list
 *
 * @param {*} {
 *   currentEntityInLoop,
 *   selectedCategories = [],
 * }
 * @returns {number}
 */
export const setNumberOfSelectedChildren = ({
  currentEntityInLoop,
  selectedCategories = []
}): number => {
  // find number of direct children that are selected
  const myChildren = get(currentEntityInLoop, ['subCategories'], [])

  if (myChildren.length) {
    const mySelectedChildrenIds =
      myChildren
        .map(x =>
          some(selectedCategories, item => get(item, ['id']) === get(x, ['id']))
        )
        .filter(Boolean) || []

    return mySelectedChildrenIds.length
  }

  return 0
}

/**
 * Function that will remove a given item from the selected categories when the ID matches a selected category
 *
 * @param {{
 *   id: string;
 *   selectedCategories: EntitySmall[];
 * }} {
 *   selectedCategories = [],
 *   id,
 * }
 * @returns {EntitySmall[]}
 */
export const removeItemFromList = ({
  selectedCategories = [],
  id
}: {
  id: string
  selectedCategories: EntitySmall[]
}): EntitySmall[] => {
  const cloneCategories = cloneDeep(selectedCategories)
  const indexToRemove = cloneCategories.findIndex(x => get(x, ['id']) === id)

  if (indexToRemove >= 0) {
    cloneCategories.splice(indexToRemove, 1)
  }

  return cloneCategories
}

/**
 * Function called when a user wants to remove an implicitly selected category from the list.
 * Since the category was not explicitly selected, it's not included in the list of selected categories, nor is it's siblings.
 * So, we want to add all of it's siblings to the selected list and NOT include the subject category. Finally,
 * we want to remove the parent that was explicitly selected.
 *
 * @param {{
 *   categoriesActiveIndices: number[];
 *   categoriesList: SavedEntity[];
 *   id: string;
 *   selectedCategories: EntitySmall[];
 * }} {
 *   selectedCategories = [],
 *   categoriesList = [],
 *   id,
 *   categoriesActiveIndices = ,
 * }
 * @returns {EntitySmall[]}
 */
export const removeAncestorSelectedItem = ({
  categoriesActiveIndices = [],
  categoriesList = [],
  id,
  selectedCategories = []
}): EntitySmall[] => {
  const clonedSelectedCategories = cloneDeep(selectedCategories)
  const clonedCategories = cloneDeep(categoriesList)
  const paths = []
  const parentIds = []

  // Find which parent is selected
  categoriesActiveIndices.forEach((x, i) => {
    if (i === 0) {
      parentIds.push(get(clonedCategories, [x, 'id']))
      paths.push(x, 'subCategories')
    } else {
      paths.push(x, 'subCategories')
      const clonedPaths = cloneDeep(paths)
      // change [0, 'subCategories] to [0, 'id'] for use with get()
      clonedPaths.splice(-1, 1, 'id')
      parentIds.push(get(clonedCategories, clonedPaths))
    }
  })

  // create an array of true/false for if a parent was selected. Should only have one true
  const whichParentIsSelected = parentIds.map(x =>
    some(clonedSelectedCategories, item => get(item, ['id']) === x)
  )

  // Check if immediate parent was the selected item
  const parentIndex = whichParentIsSelected.findIndex(x => x === true)

  if (parentIndex >= 0 && parentIndex === parentIds.length - 1) {
    // immediate parent was selected:
    // we want to remove parent
    const parentId = parentIds[parentIndex]
    const parentIndexToRemove = clonedSelectedCategories.findIndex(
      x => get(x, ['id']) === parentId
    )
    clonedSelectedCategories.splice(parentIndexToRemove, 1)

    // add all other children except the category that is being removed
    paths.splice(-1, 1, 'subCategories')
    const currentListCategories = get(clonedCategories, paths, [])

    // create a list of ids & names of subject entity immediate siblings
    const currentListIds = currentListCategories.map(x => ({
      id: get(x, ['id']),
      name: get(x, ['name'])
    }))

    // remove the subject entity
    const indexToRemove = currentListIds.findIndex(x => get(x, ['id']) === id)
    currentListIds.splice(indexToRemove, 1)

    // @ts-ignore
    return _unionBy(
      parentIndex >= 0 ? clonedSelectedCategories : selectedCategories,
      currentListIds,
      'id'
    )
  } else {
    // parent was not selected, but, one ancestor was, so need to find that parent, remove it, but add the non-related nodes to the selected list
    const selectedParentIndex = whichParentIsSelected.findIndex(x => x === true)
    const parentToRemovePaths = []

    categoriesActiveIndices.forEach((x, i) => {
      if (i === selectedParentIndex) {
        return parentToRemovePaths.push(x)
      } else if (i < selectedParentIndex) {
        return parentToRemovePaths.push(x, 'subCategories')
      }
    })

    // Remove the parent that was selected
    const idToRemove = get(clonedCategories, [...parentToRemovePaths, 'id'])
    const parentIndex = clonedSelectedCategories.findIndex(
      x => get(x, ['id']) === idToRemove
    )
    clonedSelectedCategories.splice(parentIndex, 1)

    // Add all of those children, except the parent to the one we're removing
    const childrenOfPreviouslySelectedParent = cloneDeep(
      get(clonedCategories, [...parentToRemovePaths, 'subCategories'], [])
    )
    const indexToRemove = categoriesActiveIndices[selectedParentIndex + 1]
    let childrenToAdd = childrenOfPreviouslySelectedParent
      .map((x, i) =>
        i !== indexToRemove
          ? { id: get(x, ['id']), name: get(x, ['name']) }
          : null
      )
      .filter(Boolean)

    // Finally, add the directly related children to the category we're originally removing
    const mySiblings = get(
      clonedCategories,
      [
        ...parentToRemovePaths,
        'subCategories',
        get(categoriesActiveIndices, [categoriesActiveIndices.length - 1]),
        'subCategories'
      ],
      []
    )
    // remove my item
    const myIndex = mySiblings.findIndex(x => get(x, ['id']) === id)
    mySiblings.splice(myIndex, 1)
    childrenToAdd = [
      ...childrenToAdd,
      ...mySiblings.map(x => ({
        id: get(x, ['id']),
        name: get(x, ['name'])
      }))
    ]

    // @ts-ignore
    return _unionBy(clonedSelectedCategories, childrenToAdd, 'id')
  }
}

/**
 * Function that will add a given category to the selected list of categories
 * If any children of this category were selected, it will remove all of them
 * Will preserve any other children categories that are not of this category
 *
 * @param {{
 *   categoriesActiveIndices: number[];
 *   categoriesList: SavedEntity[];
 *   id: string;
 *   name: string;
 *   selectedCategories: EntitySmall[];
 * }} {
 *   categoriesActiveIndices = [],
 *   categoriesList = [],
 *   id,
 *   name,
 *   selectedCategories = [],
 * }
 * @returns {EntitySmall[]}
 */
export const addCategoryToListOfSelectedCategoriesRemoveChildren = ({
  categoriesActiveIndices = [],
  categoriesList = [],
  id,
  name,
  selectedCategories = []
}: {
  categoriesActiveIndices: number[]
  categoriesList: SavedEntity[]
  id: string
  name: string
  selectedCategories: EntitySmall[]
}): EntitySmall[] => {
  const clonedSelectedCategories = cloneDeep(selectedCategories)
  const clonedCategoriesList = cloneDeep(categoriesList)
  const pathsToCurrentEntity = setPathsToCurrentList(categoriesActiveIndices)

  const currentListOfCategories = categoriesActiveIndices.length
    ? get(clonedCategoriesList, pathsToCurrentEntity, [])
    : clonedCategoriesList

  // @ts-ignore
  const myIndex = currentListOfCategories.findIndex(x => get(x, ['id']) === id)

  const myChildren = get(
    currentListOfCategories,
    [myIndex, 'subCategories'],
    []
  )

  if (myChildren.length || selectedCategories.length) {
    // need to check EVERY child's id to see if they are selected.
    const descendentSelectedIds = setArrayOfSelectedDescendentIds({
      myChildren,
      selectedCategories
    })

    // Remove each selected child from the selected list
    descendentSelectedIds.forEach(x => {
      const myIndexInSelectedCategories = clonedSelectedCategories.findIndex(
        z => get(z, ['id']) === x
      )
      if (myIndexInSelectedCategories >= 0) {
        clonedSelectedCategories.splice(myIndexInSelectedCategories, 1)
      }
    })

    // All children are removed, add the new category
    return [...clonedSelectedCategories, { id, name }]
  }

  // have not yet retrieved children, therefore, none have been selected
  // just add the new category
  return [...selectedCategories, { id, name }]
}

export const setPathsToCurrentList = (
  categoriesActiveIndices: number[]
): Array<string | number> => {
  const pathsToCurrentEntity = []

  categoriesActiveIndices.forEach(x => {
    pathsToCurrentEntity.push(x, 'subCategories')
  })

  return pathsToCurrentEntity
}

/**
 * Function that will take a list of categories, and return an array of ID's of the selected
 * categories in the list
 *
 * @param {{
 *   myChildren: SavedEntity[];
 *   selectedCategories: EntitySmall[];
 * }} {
 *   myChildren,
 *   selectedCategories,
 * }
 * @returns {string[]}
 */
export const setArrayOfSelectedDescendentIds = ({
  myChildren = [],
  selectedCategories = []
}: {
  myChildren: SavedEntity[]
  selectedCategories: EntitySmall[]
}): string[] => {
  const clonedSelectedCategories = cloneDeep(selectedCategories)
  const childrenIds = []

  const loopThroughChildrenAddIds = (children = []) =>
    children.forEach(x => {
      childrenIds.push(get(x, ['id']))
      if (get(x, ['subCategories'], []).length) {
        loopThroughChildrenAddIds(get(x, ['subCategories'], []))
      }
    })

  loopThroughChildrenAddIds(myChildren)

  // return an array of ids of the selected children
  return childrenIds
    .map(x =>
      some(clonedSelectedCategories, z => get(z, ['id']) === x) ? x : false
    )
    .filter(Boolean)
}

/**
 * Function that will check where user is based off categoriesActiveIndices
 * Add all currently viewed items to selectedCategories
 * Remove all selected descendants
 * Remove all selected ancestors
 * Returns updated selectedCategories list
 *
 * @param {{
 *   categoriesActiveIndices: number[];
 *   categoriesList: SavedEntity[];
 *   selectedCategories: EntitySmall[];
 * }} {
 *   categoriesActiveIndices = [],
 *   categoriesList = [],
 *   selectedCategories = [],
 * }
 * @returns {EntitySmall[]}
 */
export const setAllCurrentlyViewedCategoriesToSelected = ({
  categoriesActiveIndices = [],
  categoriesList = [],
  selectedCategories = []
}: {
  categoriesActiveIndices: number[]
  categoriesList: SavedEntity[]
  selectedCategories: EntitySmall[]
}): EntitySmall[] => {
  const pathToCurrentList = setPathsToCurrentList(categoriesActiveIndices)
  // @ts-ignore
  const currentShownList = (pathToCurrentList || []).length
    ? get(categoriesList, pathToCurrentList, [])
    : categoriesList

  const categoriesToAdd = currentShownList.map(x => ({
    id: get(x, ['id']),
    name: get(x, ['name'])
  }))

  if (!categoriesActiveIndices.length) {
    // top level, remove previously selected categories
    // set all top level categories as selected
    return categoriesToAdd
  }

  // remove any children that were selected
  const removedChildren = removeChildrenFromCategoryList({
    children: currentShownList,
    selectedCategories
  })
  // add all currently viewed to list
  const updatedCategories = _unionBy(categoriesToAdd, removedChildren, 'id')
  // check if any ancestors were selected. If so, need to remove them
  const parentIds = []
  const paths = []
  categoriesActiveIndices.forEach((x, i) => {
    parentIds.push(
      i === 0
        ? get(categoriesList, [x, 'id'])
        : get(categoriesList, [...paths, x, 'id'])
    )
    paths.push(x, 'subCategories')
  })

  const whichParentIsSelected = parentIds.map(x =>
    some(updatedCategories, item => get(item, ['id']) === x)
  )
  const ancestorIndex = whichParentIsSelected.findIndex(x => x === true)

  if (ancestorIndex >= 0) {
    // an ancestor was selected, remove it
    const pathsToRemovedAncestor = []
    categoriesActiveIndices.forEach((x, i) => {
      if (i < ancestorIndex) {
        pathsToRemovedAncestor.push(x, 'subCategories')
      }
    })

    // @ts-ignore
    const ancestorId: string = get(categoriesList, [
      ...pathsToRemovedAncestor,
      'id'
    ])
    const indexToRemove = updatedCategories.findIndex(
      x => get(x, ['id']) === ancestorId
    )

    updatedCategories.splice(indexToRemove, 1)
  }
  return updatedCategories
}

/**
 * Function that will remove ALL descendant from the selected category list
 *
 * @param {*} {children = [], selectedCategories = []}
 * @returns {EntitySmall[]}
 */
export const removeChildrenFromCategoryList = ({
  children = [],
  selectedCategories = []
}): EntitySmall[] => {
  const clonedSelectedCategories = cloneDeep(selectedCategories)
  const loopThroughChildren = myChildren => {
    myChildren.forEach(x => {
      const index = clonedSelectedCategories.findIndex(
        i => get(i, ['id']) === get(x, ['id'])
      )
      if (index >= 0) {
        clonedSelectedCategories.splice(index, 1)
      }

      if (get(x, ['subCategories'], []).length) {
        // keep removing all descendants
        loopThroughChildren(get(x, ['subCategories'], []))
      }
    })
  }
  loopThroughChildren(children)

  return clonedSelectedCategories
}

/**
 * Utility function that will verify that an entity has an ID, if it does not, then it will be removed from the returned object.
 *
 * Example: input = { entity1: { id: 'foo}, entity2: {} } output = { entity1: { id: 'foo} }
 *
 * @param {EntitiesList} entities
 * @param {string[]} keys
 * @param {string[]} verifyingKey
 * @returns {{entity1?: SavedEntity; entity2?: SavedEntity}}
 */
export const normalizeSelectedEntities = (
  entities: EntitiesList,
  keys: string[] = ['entity1', 'entity2'],
  verifyingKey: string = 'id'
): { entity1?: SavedEntity; entity2?: SavedEntity } => {
  if (entities && typeof entities === 'object') {
    const clonedEntities = cloneDeep(entities)
    keys.forEach(key => {
      if (get(entities, [key]) && !get(entities, [key, verifyingKey])) {
        delete clonedEntities[key]
      }
    })
    return clonedEntities
  }

  return {}
}

/**
 * Function that checks if an entity has:
 * Has a universe (views = ['universe']) &&
 * has twitter (entity.twitter = [{...}])
 * OR
 * If universe has been selected (entity.universe = true)
 *
 * @param {SavedEntity} entity
 * @returns {boolean}
 */
export const isUniverseSelected = (entity: SavedEntity): boolean =>
  autoSetUniverse(entity) || get(entity, ['universe'], false)

/**
 * Function that will set the correct query param for if the universe should be included in the call
 *
 * @param {SavedEntity} entity
 * @returns {viewsQueryParam}
 */
export const setUniverseQueryParam = (entity: SavedEntity): viewsQueryParam =>
  isUniverseSelected(entity) ? 'universe' : 'null'
