const version = process.env.API_VERSION || 0
const prefix = process.env.API_ENDPOINT || ''

const endpoints = {
  compare: `${prefix}/v${version}/twitter/compare`,
  clearbit: 'https://logo.clearbit.com',
  historical: `${prefix}/v${version}/twitter/historical_follows`,
  piiAudienceCategories: `${prefix}/v${version}/pii/spend_behavior/categories`,
  piiAudiences: `${prefix}/v${version}/pii/audiences`,
  piiPropertyTrackings: `${prefix}/v${version}/pii/property-trackings`,
  piiSpend: `${prefix}/v${version}/pii/spend`,
  piiSpendBehavior: `${prefix}/v${version}/pii/spend-behaviors`,
  piiSpendUplift: `${prefix}/v${version}/pii/spend-uplift`,
  publicEntities: `${prefix}/v${version}/public-entities/entities`,
  publicEntitiesDefault: `${prefix}/v${version}/public-entities`,
  publicEntitiesBrands: `${prefix}/v${version}/public-entities/brands`,
  publicEntitiesCategories: `${prefix}/v${version}/public-entities/categories`,
  publicEntitiesCollections: `${prefix}/v${version}/public-entities/collections`,
  publicEntitiesCollectionsCategories: `${prefix}/v${version}/public-entities/collections/categories`,
  publicEntitiesExtensionOpportunity: `${prefix}/v${version}/twitter/brands`,
  publicEntitiesGames: `${prefix}/v${version}/public-entities/games`,
  publicEntitiesInfluencers: `${prefix}/v${version}/public-entities/influencers`,
  publicEntitiesLeagues: `${prefix}/v${version}/public-entities/leagues`,
  publicEntitiesOrgs: `${prefix}/v${version}/public-entities/orgs`,
  publicEntitiesRosterOwners: `${prefix}/v${version}/public-entities/roster_owners`,
  publicEntitiesRosters: `${prefix}/v${version}/public-entities/rosters`,
  publicEntitiesSystem: `${prefix}/v${version}/public-entities/system`,
  registrations: `${prefix}/v${version}/auth/registrations`,
  records: `${prefix}/v${version}/pii/records`,
  spendCategories: `${prefix}/v${version}/pii/spend_behavior/categories`,
  spendTypeBins: `${prefix}/v${version}/pii/spend_behavior/general_categories`,
  twitterAdminTracker: `${prefix}/v${version}/twitter/tracking`,
  twitterUser: `${prefix}/v${version}/twitter/twitter_users`,
  universeIds: `${prefix}/v${version}/public-entities/universe/ids`
}

// escape forward slashes
export const toRegex = (path: string): RegExp =>
  new RegExp(path.replace(/\//g, '\\/'))

export default endpoints
