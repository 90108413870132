import { motion } from 'framer-motion';
import { usePortal } from 'hooks/usePortal';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ToastInterface } from 'types';
import classNames from 'utils/classNames';
import Icon from '../icon';
import './toast.css';

const ClearIcon = require('!babel-loader!svg-react-loader?name=ClearIcon!../../../assets/svg/icClear.svg');
const AttentionIcon = require('!babel-loader!svg-react-loader?name=AttentionIcon!../../../assets/svg/icAttention.svg');
const IndicatorIcon = require('!babel-loader!svg-react-loader?name=IndicatorIcon!../../../assets/svg/icIndicator.svg');

const Toast = ({
  action,
  message,
  onAction,
  onDismiss,
  styles,
  timeoutLength = 5000,
  type,
}: ToastInterface) => {
  const target = usePortal('toast-root');
  const [showing, setShowing] = useState(true);
  const [hideTimer, setHideTimer] = useState(null);
  useEffect(() => {
    if (showing && !hideTimer) {
      setHideTimer(
        setTimeout(() => {
          handleDismiss();
        }, timeoutLength),
      );
    } else if (!showing && hideTimer) {
      clearTimeout(hideTimer);
    }

    return () => {
      clearTimeout(hideTimer);
    };
    // eslint-disable-next-line
  }, [showing]);

  const handleDismiss = () => {
    setShowing(false);
  };

  return createPortal(
    <>
      <motion.div
        className={classNames({
          [type]: true,
          toast: true,
        })}
        key="toast"
        initial="leave"
        animate={showing ? 'enter' : 'leave'}
        exit="leave"
        variants={{
          enter: {
            display: 'grid',
            opacity: 1,
            transform: 'translate3d(-50%, 50%, 0)',
          },
          leave: {
            opacity: 0,
            transform: 'translate3d(-50%, 0%, 0)',
          },
        }}
        onAnimationComplete={() => {
          if (!showing && onDismiss) {
            onDismiss();
          }
        }}
      >
        <div className="toastType">
          <Icon
            className="toastTypeIcon"
            glyph={
              type === 'info' || type === 'action' || type === 'default'
                ? IndicatorIcon
                : AttentionIcon
            }
          />
        </div>
        <span className="toastMessage">{message}</span>
        {action ? (
          <div className="toastAction" onClick={onAction}>
            {action}
          </div>
        ) : null}
        <Icon
          glyph={ClearIcon}
          className="toastDismiss"
          onClick={handleDismiss}
        />
      </motion.div>
    </>,
    target,
  );
};

export default Toast;
