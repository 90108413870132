import { spendBehavior } from 'actions/types';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { SpendBehaviorState, SpendCategory } from 'types';

const {
  SPEND_CATEGORIES_AVAILABLE,
  SPEND_TYPE_BINS_AVAILABLE,
  SPEND_BEHAVIOR_FILTER_CHANGE,
  RESET_CATEGORIES,
  RANKING_VIEW_CHANGE,
  SET_RANKING_VIEW,
} = spendBehavior;

const initialState = {
  categories: {},
  categoriesReceived: false,
  initialCategories: {},
  populationSize: 0,
};

const rankingViews = {
  ABOVE_AVERAGE: 'aboveAverage',
  AVERAGE_RANK: 'averageRank',
  TOP_QUARTILE: 'topQuartile',
};

interface InitialCategory {
  category_id: number;
  category_name: string;
  spend_types: InitialSpendType[];
}

interface InitialSpendType {
  spend_type_id: number;
  spend_type_name: string;
  subcategories: InitialSubcategory[];
}

interface InitialSubcategory {
  category_id: number;
  category_name: string;
}

export const normalize = (
  categories: InitialCategory[],
): { [prop: string]: SpendCategory } => {
  const normalized = {};
  const spendCategories = [
    'Total Spend',
    'Purchase Frequency',
    'Spend Likelihood',
  ];

  categories.forEach(category => {
    if (
      category.spend_types.every(s =>
        // @ts-ignore
        spendCategories.includes(s.spend_type_name),
      )
    ) {
      normalized[category.category_id] = {
        id: category.category_id,
        name: category.category_name,
      };

      normalized[category.category_id].spendTypes = {};

      category.spend_types.forEach(spendType => {
        normalized[category.category_id].spendTypes[spendType.spend_type_id] = {
          id: spendType.spend_type_id,
          name: spendType.spend_type_name,
          rankingView: rankingViews.AVERAGE_RANK,
        };

        normalized[category.category_id].spendTypes[
          spendType.spend_type_id
        ].subcategories = {};

        spendType.subcategories.forEach(subcategory => {
          normalized[category.category_id].spendTypes[
            spendType.spend_type_id
          ].subcategories[subcategory.category_id] = {
            hasAppliedFilter: true,
            id: subcategory.category_id,
            name: subcategory.category_name,
          };
        });
      });
    }
  });

  return normalized;
};

export default (state: SpendBehaviorState = initialState, action) => {
  switch (action.type) {
    case SPEND_CATEGORIES_AVAILABLE:
      const categories = normalize(action.payload.categories);
      return {
        ...state,
        categories,
        categoriesReceived: true,
        initialCategories: cloneDeep(categories),
      };

    case SPEND_TYPE_BINS_AVAILABLE: {
      const category = cloneDeep(state.categories[action.payload.category]);
      const bins = get(
        action,
        ['payload', 'spend_behaviors', 'demi_decile_bins'],
        [],
      );

      bins.forEach(bin => {
        category.spendTypes[bin.spend_type_id].subcategories[
          bin.subcategory_id
        ].bins = bin.demi_decile_bins;
      });

      category.populationSize = get(
        action,
        ['payload', 'spend_behaviors', 'population_size'],
        0,
      );
      category.matchedSize = get(
        action,
        ['payload', 'spend_behaviors', 'matched_size'],
        0,
      );
      category.hasBinData = true;

      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.category]: category,
        },
      };
    }

    case SPEND_BEHAVIOR_FILTER_CHANGE: {
      const category = cloneDeep(state.categories[action.payload.categoryId]);
      const spendType = category.spendTypes[action.payload.spendTypeId];
      const subcategories = spendType.subcategories;

      if (action.payload.subcategoryIds.length === 0) {
        // apply all filters
        Object.keys(subcategories).forEach(k => {
          spendType.subcategories[k].hasAppliedFilter = true;
        });
      } else {
        // remove all filters
        Object.keys(subcategories).forEach(k => {
          subcategories[
            k
          ].hasAppliedFilter = !action.payload.subcategoryIds.find(
            id => +id === +k,
          );
        });
      }

      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.categoryId]: category,
        },
      };
    }

    case RANKING_VIEW_CHANGE: {
      const category = cloneDeep(state.categories[action.payload.categoryId]);

      const spendType = category.spendTypes[action.payload.spendTypeId];

      spendType.rankingView = action.payload.ranking;
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.categoryId]: category,
        },
      };
    }

    case SET_RANKING_VIEW: {
      const category = cloneDeep(state.categories[action.payload.categoryId]);

      Object.keys(category.spendTypes).forEach(k => {
        category.spendTypes[k].rankingView =
          get(action, `payload.rankingViews.${k}`, false) ||
          rankingViews.AVERAGE_RANK;
      });

      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.categoryId]: category,
        },
      };
    }

    case RESET_CATEGORIES: {
      return {
        ...state,
        categories: cloneDeep(state.initialCategories),
      };
    }

    default:
      return state;
  }
};
