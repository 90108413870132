/* DataLayer Constants */
export const CACHE_FAVORITE_TILE_DATA = 'CACHE_FAVORITE_TILE_DATA';
export const CANCELLED_PENDING_REQUESTS = 'CANCELLED_PENDING_REQUESTS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const COMPARISON_DATA_RECEIVED = 'COMPARISON_DATA_RECEIVED';
export const ENTITIES = 'ENTITIES';
export const ENTITIES_ALL = 'ENTITIES_ALL';
export const ENTITIES_RECEIVED = 'ENTITIES_RECEIVED';
export const FAVORITE_TILE_DELETED = 'FAVORITE_TILE_DELETED';
export const FAVORITE_TILE_DELETED_FAILURE = 'FAVORITE_TILE_DELETED_FAILURE';
export const FILTER_SD_TILES = 'FILTER_SD_TILES';
export const FORM_VALUES_UPDATED = 'FORM_VALUES_UPDATED';
export const INITIALIZE_LOGIN = 'INITIALIZE_LOGIN';
export const INITIALIZE_PASSWORD_RECOVERY = 'INITIALIZE_PASSWORD_RECOVERY';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const MENU_ITEMS_AVAILABLE = 'MENU_ITEMS_AVAILABLE';
export const NEWS_AND_UPDATES_AVAILABLE = 'NEWS_AND_UPDATES_AVAILABLE';
export const NEWS_AND_UPDATES_FAILURE = 'NEWS_AND_UPDATES_FAILURE';
export const NEW_TILE_AVAILABLE = 'NEW_TILE_AVAILABLE';
export const PASSWORD_RESET_COMPLETE = 'PASSWORD_RESET_COMPLETE';
export const PASSWORD_RESET_INVALID_CODE_FAILURE =
  'PASSWORD_RESET_INVALID_CODE_FAILURE';
export const PASSWORD_RESET_REQUEST_COMPLETE =
  'PASSWORD_RESET_REQUEST_COMPLETE';
export const REQUEST_COMPLETE = 'REQUEST_COMPLETE';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const REQUEST_IN_PROGRESS = 'REQUEST_IN_PROGRESS';
export const RESET_SELECTED_ENTITIES = 'RESET_SELECTED_ENTITIES';
export const SD_DATA_TAGS_REQUEST_FAILURE = 'SD_DATA_TAGS_REQUEST_FAILURE';
export const SD_DATA_TILES_RECEIVED = 'SD_DATA_TILES_RECEIVED';
export const SD_DATA_TILES_REQUEST_FAILURE = 'SD_DATA_TILES_REQUEST_FAILURE';
export const SD_TAGS_AVAILABLE = 'SD_TAGS_AVAILABLE';
export const SD_TEMPLATE_UPDATED = 'SD_TEMPLATE_UPDATED';
export const SD_TILES_AVAILABLE = 'SD_TILES_AVAILABLE';
export const SD_VISUALIZATION_UPDATED = 'SD_VISUALIZATION_UPDATED';
export const SET_SELECTED_VISUALIZATION = 'SET_SELECTED_VISUALIZATION';
export const STORE_PASSWORD_RESET_EMAIL = 'STORE_PASSWORD_RESET_EMAIL';
export const TILE_UPDATE_FAILURE = 'TILE_UPDATE_FAILURE';
export const UPDATE_SELECTED_ENTITIES = 'UPDATE_SELECTED_ENTITIES';
export const UPDATE_SELECTED_ENTITY = 'UPDATE_SELECTED_ENTITY';
export const UPDATE_SESSION_INFO = 'UPDATE_SESSION_INFO';
export const UPDATE_URL_PATH = 'UPDATE_URL_PATH';
export const USER_SD_DATA_TILES_RECEIVED = 'USER_SD_DATA_TILES_RECEIVED';
export const USER_SD_DATA_TILES_REQUEST_FAILURE =
  'USER_SD_DATA_TILES_REQUEST_FAILURE';
export const SESSION_CHECK_COMPLETE = 'SESSION_CHECK_COMPLETE';
export const ANALYTICS = 'ANALYTICS';
export const CLIENTS_AND_USERS_AVAILABLE = 'CLIENTS_AND_USERS_AVAILABLE';
export const USER_CREATED = 'USER_CREATED';
export const CLIENT_CREATED = 'CLIENT_CREATED';
export const GLOBAL_PERMISSIONS_AVAILABLE = 'GLOBAL_PERMISSIONS_AVAILABLE';
export const CLIENT_UPDATED = 'CLIENT_UPDATED';
export const UPDATE_CLIENT_DELETION_STATUS = 'UPDATE_CLIENT_DELETION_STATUS';
export const CLIENTS_DELETED = 'CLIENTS_DELETED';
export const USER_DELETED = 'USER_DELETED';
export const USER_EDITED = 'USER_EDITED';
export const USER_PERMISSIONS_RECEIVED = 'USER_PERMISSIONS_RECEIVED';
export const RAVEN_FOLDER_CREATED = 'RAVEN_FOLDER_CREATED';
export const EMAIL_RESET_LINK_SENT = 'EMAIL_RESET_LINK_SENT';
export const INIT_TILE_STATE = 'INIT_TILE_STATE';
export const SET_ENTITY_UNIVERSE = 'SET_ENTITY_UNIVERSE';
export const UPDATE_USER_PREVIOUS_PATH = 'UPDATE_USER_PREVIOUS_PATH';
export const FANAI_USER = 'fanai_user';
export const FANAI_USER_ADMIN = 'fanai_user_admin';
