import React, { useCallback, useState } from 'react';
import { DropdownItem, Nullable } from 'types';

export default function useSelectInput(
  initValue: Nullable<DropdownItem> = null,
) {
  const [value, setValue] = useState(initValue);
  const onSelect = useCallback(
    (selectedValue: any) => {
      setValue(selectedValue);
    },
    [setValue],
  );
  return [value, onSelect, setValue] as [
    Nullable<DropdownItem>,
    typeof onSelect,
    typeof setValue,
  ];
}
