import * as sort from 'utils/sorting';

export const DEFAULT_POLL_CONFIG = {
  pollInterval: 3500,
  pollUntil: response => {
    return Array.isArray(response)
      ? response.every(r => r.status === 200)
      : response.status === 200;
  },
  timeout: 1000 * 30 * 2, // 1 min
};

export const topLevelEntityTypes = [
  'brands',
  'entities',
  'games',
  'influencers',
  'rosterOwners',
  'sponsors',
];

export const defaultCache = {
  name: 'fanai',
  storeName: 'api_response_cache',
};

export default {
  categoryOrder: new Map([
    [
      'favorite',
      [
        (a, b) => {
          if (a.interaction_count === b.interaction_count) {
            return new Date(a.last_used) < new Date(b.last_used);
          }
          return a.interaction_count < b.interaction_count;
        },
      ],
    ],
    ['new', [sort.ascendingSortFn]],
    ['manager', [sort.ascendingSortFn]],
    // Dont know the algo for suggested as of yet
    // ['suggested', [sort.ascending]],
    [
      'template',
      [
        (a, b) => {
          return a.interaction_count < b.interaction_count;
        },
      ],
    ],
    ['feedback', [sort.ascendingSortFn]],
    ['report', [sort.ascendingSortFn]],
  ]),
  compare2ThingsCategories: {
    entities: {
      endpoint: 'publicEntities',
      id: 'entities',
      name: 'All',
    },
    // tslint:disable-next-line
    brands: {
      endpoint: 'publicEntitiesBrands',
      id: 'brands',
      name: 'Brands',
    },
    games: {
      childrenEndpoints: {
        leagues: 'publicEntitiesLeagues',
        rosters: 'publicEntitiesRosters',
      },
      endpoint: 'publicEntitiesGames',
      id: 'games',
      name: 'Games',
    },
    influencers: {
      endpoint: 'publicEntitiesInfluencers',
      id: 'influencers',
      name: 'Influencers',
    },
    orgs: {
      childrenEndpoints: {
        roster_members: 'publicEntitiesRosterOwners',
        rosters: 'publicEntitiesRosters',
      },
      disableCategoryDropdown: true,
      endpoint: 'publicEntitiesOrgs',
      id: 'orgs',
      name: 'Orgs',
    },
    roster_owners: {
      childrenEndpoints: {
        rosters: 'publicEntitiesRosters',
      },
      endpoint: 'publicEntitiesRosterOwners',
      id: 'roster_owners',
      name: 'Roster Owners',
    },
    sponsors: {
      disableCategoryDropdown: true,
      endpoint: 'publicEntities',
      id: 'sponsors',
      name: 'Sponsors',
    },
  },
  entityTypes: {
    brands: {
      structure: [
        {
          type: 'brands',
        },
      ],
    },
    default: {
      namePrecedence: ['name', 'nickname', 'twitter.handle'],
    },
    entities: {
      structure: [
        {
          type: 'entities',
        },
      ],
    },
    games: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
      structure: [
        {
          title: 'Games',
          type: 'games',
        },
        {
          title: 'Leagues',
          type: 'leagues',
        },
        {
          title: 'Rosters',
          type: 'rosters',
        },
        {
          title: 'Roster Members',
          type: 'roster_members',
        },
      ],
    },
    influencers: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
      structure: [
        {
          type: 'influencers',
        },
      ],
    },
    leagues: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
    },
    orgs: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
      structure: [
        {
          title: 'Orgs',
          type: 'orgs',
        },
        {
          title: 'Roster',
          type: 'rosters',
        },
        {
          title: 'Roster Members',
          type: 'roster_members',
        },
      ],
    },
    players: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
    },
    roster_members: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
    },
    roster_owners: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
      structure: [
        {
          title: 'Roster Owner',
          type: 'roster_owners',
        },
        {
          title: 'Roster',
          type: 'rosters',
        },
        {
          title: 'Roster Members',
          type: 'roster_members',
        },
      ],
    },
    rosters: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
    },
    sponsors: {
      namePrecedence: ['twitter.handle', 'nickname', 'name'],
      structure: [
        {
          type: 'sponsors',
        },
      ],
    },
    teams: { namePrecedence: ['twitter.handle', 'nickname', 'name'] },
  },
  securityGroups: {
    admin: {
      createUsers: 'fanai_user_creator',
    },
  },
  supportedFilters: ['favorite', 'new', 'template'],
};
