import * as React from 'react';
import classNames from 'utils/classNames';
import './spinner.css';

const spinnerPNG = require('assets/png/Spinner.png');

interface SpinnerProps {
  /**
   * value to specify the sizing: 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall'
   */
  size?: string;
  /**
   * optional object for inline styling
   */
  style?: any;
  className?: string;
}

/**
 * @deprecated
 */
const Spinner: React.SFC<SpinnerProps> = ({
  size = 'large',
  style,
  className,
}): JSX.Element => (
  <div
    className={classNames({
      [size]: true,
      spinner: true,
      [className]: !!className,
    })}
    style={style}
  >
    <img src={spinnerPNG} alt="Loading..." />
  </div>
);

export default Spinner;
