import routeConfig from 'config/routes';
import { Breadcrumb } from 'types';
import { trimToLower } from 'utils/strings';

export default (
  routePath: string | string[],
  selectedTile?: { name: string; [key: string]: any },
): Breadcrumb[] => {
  const routeParts = Array.isArray(routePath)
    ? routePath
    : routePath.split('/');

  const newRoute: Breadcrumb[] | [] = routeParts
    .filter(r => r && routeConfig.root.indexOf(r) === -1)
    .map(route => {
      return routeConfig[route]
        ? { name: routeConfig[route], url: route }
        : null;
    })
    .filter(Boolean);

  if (
    newRoute.length &&
    selectedTile &&
    selectedTile.name !== newRoute[newRoute.length - 1].name &&
    (routeParts[routeParts.length - 1] === selectedTile.uid ||
      routeParts[routeParts.length - 1] === trimToLower(selectedTile.name))
  ) {
    newRoute.push({
      name: selectedTile.name,
      url: trimToLower(selectedTile.name),
    });
  }

  return newRoute;
};
