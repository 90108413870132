/**
 * Adds the local timezone offset to a given date
 * @param {Date} date - The date to which to add time
 * @returns {Date} - A date increased by the timezone offset
 */
export function addTzOffset(date: Date): Date {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

/**
 * Subtracts the local timezone offset from a given date
 * @param {Date} date - The date from which to decrease time
 * @returns {Date} - A date decreased by the timezone offset
 */
export function subTzOffset(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

/**
 * Returns a date rounded down to the nearest UTC day start
 * @param {Date} date - The date to round
 * @returns {Date} - The start of the UTC day
 */
export function startOfDayUTC(date: Date): Date {
  const t = date.getTime();
  return new Date(t - (t % 24) * 60 * 60 * 1000);
}

/**
 * Returns the UTC day start of today
 * @returns {Date} - The UTC day start of today
 */
export function startOfTodayUTC(): Date {
  return startOfDayUTC(new Date());
}
