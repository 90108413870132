/**
 * Returns true/false based on every object's `valid` key evaluating to true
 *
 * @export                    - isFormValid
 * @param {object} formValues - Object containing the current state of the form fields
 * @returns                   - boolean
 */
export const isFormValid = (formValues: {}): boolean =>
  Object.keys(formValues).every(key => !!formValues[key].valid);

export const validations = {
  /**
   * Returns true/false based on a given value being empty
   *
   * @export                - empty
   * @param {string} - string to compare against
   * @returns               - boolean
   */
  empty: (value: string): boolean => value === '',
  isURL: (value: string): boolean =>
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/.test(
      value,
    ),
  /**
   * Returns true/false based on a given value being greater than or equal to a given length
   *
   * @export                - minLength
   * @param {string|number} - value to compare against
   * @returns               - boolean
   */
  minLength: (
    value: number | string,
    { length }: { length: number },
  ): boolean => value.toString().length < length,

  /**
   * Returns true/false based on a given value being less than or equal to a given length
   *
   * @export                - maxLength
   * @param {string|number} - value to compare against
   * @returns               - boolean
   */
  maxLength: (
    value: number | string,
    { length }: { length: number },
  ): boolean => value.toString().length > length,

  /**
   * Returns true/false based on a given value being in the format of an email address
   *
   * @export                - email
   * @param {string|number} - value to compare against
   * @returns               - boolean
   */
  email: (value: string): boolean =>
    !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      value,
    ),

  /**
   * Returns true/false based on given value containing minimum 1 number, 1 uppercase, 1 lowercase, 1 symbol and minimum 8 characters
   *
   * @export                - passwordCriteria
   * @param {string|number} - value to compare against
   * @returns               - boolean
   */

  passwordCriteria: (value: string): boolean =>
    !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.{8,})/.test(
      value,
    ),

  savedVisualizations: (value: string): boolean =>
    !/[{}|\\^~\[\]`\.]/g.test(value),

  /**
   *  Returns true/false based on given value being a full name (values separable a string)
   */
  fullName: (value: string): boolean =>
    !(
      value &&
      value.trim().split(' ').length > 1 &&
      value
        .trim()
        .split(' ')
        .every(s => !!s.trim().length)
    ),

  /**
   *  Returns true/false based on given value being a phone number of format XXX-XXX-XXXX
   */
  phoneNumber: (value: string): boolean =>
    !value.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/),
};

/**
 * Returns true/false based on every validation function returning true, and short circuits if any validation function fails
 *
 * @export                   - validate
 * @param {object} formField - Object containing the current state of the form fields
 * @param {object} schema    - Object containing the types of validations to be run against the specific input type
 * @returns                  - boolean
 */
export default (
  formField: { type: string; value: string | number },
  schema: {},
): boolean =>
  schema[formField.type].every(
    ({ type, options = {} }: { type: string; options?: {} }) =>
      !validations[type](formField.value, options),
  );

export const isEmpty = (value: any) =>
  value === null || value === undefined || value === '';
