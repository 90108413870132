import * as Sentry from '@sentry/browser';
import { BrowserOptions } from '@sentry/browser';
import { createLogger } from 'logging';

const logger = createLogger({ name: 'sentry' });

/**
 * Initializes the sentry.io client
 *
 * This function initializes the sentry.io client if a
 * config.dsn value is present.
 *
 * @param {BrowserOptions} config - The sentry.io client config
 */
export default function initSentry(config: BrowserOptions): void {
  try {
    if (config.dsn) {
      logger.info('initializing sentry', config);
      Sentry.init(config);
    } else {
      logger.info('sentry.io is disabled');
    }
  } catch (e) {
    logger.error('failed to initialize sentry', e);
  }
}
