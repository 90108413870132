import classNames from 'utils/classNames';
import React, { FC } from 'react';
import Spinner from 'deprecated/atoms/spinner';
import { analyticsTypes } from 'constants/analytics';
import { ButtonProps } from './index.d';
import { track } from 'actions/analyticsActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import './button.css';
const { BUTTON_CLICK } = analyticsTypes;

/**
 * @deprecated Use atoms/button instead
 */
const Button: FC<ButtonProps> = ({
  className = 'primary',
  type = 'submit',
  loading = false,
  onClick,
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = e => {
    dispatch(
      track({
        event: `${location.pathname}=${props.label}`,
        eventType: BUTTON_CLICK,
      }),
    );

    if (onClick && !props.disabled) {
      onClick(e);
    }
  };

  return (
    <button
      className={classNames({
        [className]: !!className,
        legacyButton: true,
        loading,
      })}
      type={type}
      onClick={e => handleClick(e)}
      {...props}
    >
      {loading && <Spinner size="xxsmall" />}
      {!loading && (
        <>
          {props.label && <span>{props.label}</span>}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
