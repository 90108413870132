import adminState from 'reducers/adminReducer';
import appState from 'reducers/appReducer';
import entityState from 'reducers/compare2ThingsReducer';
import requestState from 'reducers/requestReducer';
import spendBehaviorState from 'reducers/spendBehaviorReducer';
import sdTileState from 'reducers/tileReducer';
import userState from 'reducers/userReducer';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  adminState,
  appState,
  entityState,
  requestState,
  sdTileState,
  spendBehaviorState,
  userState,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
