import LabelCopy from 'copy/labels';
import get from 'lodash/get';
import { ClientDetails, User } from 'types';

/**
 * Util function that will take the user object and return a boolean if the user has the securityGroup of 'fanai_user_admin'
 *
 * @param {User} user
 * @returns {boolean}
 */
export const isFanaiAdmin = (user: User): boolean =>
  get(user, ['role']) === 'admin' ||
  (get(user, ['securityGroups'], []) || []).includes('fanai_user_admin');

/**
 * Util function that will take the user object and return a boolean if the user has the securityGroup of 'fanai_user'
 *
 * @param {User} user
 * @returns {boolean}
 */
export const isFanaiUser = (user: User): boolean =>
  get(user, ['role']) === 'admin' ||
  (get(user, ['securityGroups'], []) || []).includes('fanai_user');

/**
 * Util function that will check if the user is either fanai_user or fanai_user_admin
 *
 * @param {User} user
 * @returns {boolean}
 */
export const setIsFanaiEmployee = (user: User): boolean =>
  isFanaiUser(user) || isFanaiAdmin(user);

/**
 * Util function that will take the {ClientDetails} object and return a boolean if FanAI user
 * @param {ClientDetails} clientDetails
 * @returns {boolean}
 */
export const doClientDetailsBelongToFanAIUser = (
  clientDetails: ClientDetails,
): boolean =>
  clientDetails.securityGroups &&
  clientDetails.securityGroups.includes('fanai_user');
