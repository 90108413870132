import { routeChangeRequested } from 'actions';
import { track } from 'actions/analyticsActions';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { analyticsTypes } from '../../../constants/analytics';
const { INIT } = analyticsTypes;

interface AnalyticsInterface {
  children: ReactElement;
  onRouteChange: (path: string) => void;
  track: (data: null, eventType: string) => void;
}

/**
 * A wrapper component that will initialize a ReactGA session and will dispatch an action every time the user changes the url
 *
 *
 * @param {*} { onRouteChange, children }
 * @returns
 */
const Analytics: React.FC<AnalyticsInterface> = ({
  onRouteChange,
  children,
}) => {
  const { pathname }: { pathname: string } = useLocation();
  const [cachedPathname, setCachedPathname] = useState(pathname);

  // on mount, we initialize the users session with google analytics
  useEffect(() => {
    track(null, INIT);
    onRouteChange(pathname);
    // eslint-disable-next-line
  }, []);

  // on a route change, we dispatch an action that will update google analytics that user changed to a different page
  useEffect(() => {
    if (pathname !== cachedPathname) {
      onRouteChange(pathname);
      setCachedPathname(pathname);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return children;
};

const mapDispatchToProps = dispatch => ({
  onRouteChange: (path: string) => dispatch(routeChangeRequested(path)),
  track: (data, eventType) => dispatch(track(data, eventType)),
});

export default connect(null, mapDispatchToProps)(Analytics);
