import {
  CANCELLED_PENDING_REQUESTS,
  compare2Things,
  constants,
  INITIALIZE_LOGIN,
  INITIALIZE_PASSWORD_RECOVERY,
  LOGGED_OUT,
  LOGIN_USER_SUCCESS,
  PASSWORD_RESET_COMPLETE,
  PASSWORD_RESET_INVALID_CODE_FAILURE,
  PASSWORD_RESET_REQUEST_COMPLETE,
  REQUEST_CANCELLED,
  REQUEST_COMPLETE,
  REQUEST_IN_PROGRESS,
  slideoutCategories,
  UPDATE_SESSION_INFO
} from 'actions/types'

const {
  CATEGORIES_LIST_PATHS_UPDATED,
  CLEAR_ERROR,
  REQUEST_ERROR,
  ROUTE_CHANGE,
  SET_DASHBOARD_HAS_FILTER_PANE,
  SET_DASHBOARD_REQUEST_COUNT,
  SET_POLLING_TOAST,
  TOGGLE_GLOBAL_FILTER_PANE,
  TOGGLE_GLOBAL_SIDEBAR,
  UNIVERSE_COMPONENT_PATHS_UPDATED
} = constants

const {
  BRAND_BY_CATEGORY_ERROR,
  BRAND_BY_CATEGORY_LIST_PATHS_UPDATED,
  BRAND_BY_CATEGORY_LOADING,
  CATEGORIES_ERROR,
  CATEGORIES_LOADING
} = slideoutCategories

const { UNIVERSE_ENTITIES_LOADING } = compare2Things

import { ApplicationState } from 'types'

import { UPDATE_USER_PREVIOUS_PATH } from '../../constants'

export const initialState = {
  brandByCategoryActiveIndices: [],
  brandByCategoryError: null,
  brandByCategoryLoading: false,
  breadcrumbs: [],
  categoriesActiveIndices: [],
  categoriesError: null,
  categoriesLoading: false,
  dashboardHasFilterPane: false,
  dashboardRequestCount: null,
  error: null,
  filterPaneIsOpen: false,
  isShownPollingToast: null,
  loggedIn: false,
  passwordResetComplete: false,
  passwordResetRequestComplete: false,
  pendingPermissions: true,
  requestCache: {},
  sideBarIsOpen: false,
  universeComponentActiveIndices: [],
  userPreviousPath: null
}

export default (state: ApplicationState = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST_COMPLETE:
      return {
        ...state,
        passwordResetRequestComplete: true
      }
    case INITIALIZE_LOGIN:
    case INITIALIZE_PASSWORD_RECOVERY:
      return {
        ...state,
        passwordResetComplete: false,
        passwordResetExpired: false,
        passwordResetRequestComplete: false
      }
    case PASSWORD_RESET_INVALID_CODE_FAILURE:
      return {
        ...state,
        passwordResetExpired: true
      }
    case PASSWORD_RESET_COMPLETE:
      return {
        ...state,
        passwordResetComplete: true,
        passwordResetRequestComplete: false
      }
    case LOGIN_USER_SUCCESS:
    case UPDATE_SESSION_INFO:
      return {
        ...state,
        loggedIn: true
      }
    case LOGGED_OUT:
      return {
        ...state,
        ...initialState,
        pendingPermissions: false
      }
    case REQUEST_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        requestCache: {
          ...state.requestCache,
          ...(action.payload.uid || action.requestId
            ? {
                [action.payload.uid || action.requestId]: {
                  ...action.payload,
                  status: 'PENDING'
                }
              }
            : {})
        }
      }
    case REQUEST_COMPLETE:
      const rCache = { ...state.requestCache }
      if (
        (action.payload && action.payload.uid && rCache[action.payload.uid]) ||
        (action.requestId && rCache[action.requestId])
      ) {
        delete rCache[action.requestId || action.payload.uid]
      }

      const keys = Object.keys(rCache)

      return {
        ...state,
        loading: !!(
          keys.length &&
          keys.some(k => rCache[k].status && rCache[k].status === 'PENDING')
        ),
        requestCache: rCache
      }
    case CANCELLED_PENDING_REQUESTS:
      const requestCache = { ...state.requestCache }
      const requestCacheKeys = Object.keys(requestCache)
      const rc = {}

      // if  the user make some api request then navigates away then
      // remove the loading spinner for prev request that have been cancelled due
      // to the route change
      requestCacheKeys.forEach(
        k => (rc[k] = { ...requestCache[k], status: 'CANCELLED' })
      )

      return {
        ...state,
        loading: false,
        requestCache: rc
      }
    case REQUEST_ERROR: {
      const requestCache = { ...state.requestCache }
      const { error } = action.payload.data || action.payload

      if (action.requestId && requestCache[action.requestId]) {
        delete requestCache[action.requestId]
      }

      if (action.requestId) {
        return {
          ...state,
          error:
            (requestCache[action.requestId] &&
              requestCache[action.requestId].status === 'CANCELLED') ||
            !state.loggedIn
              ? null
              : {
                  ...state.error,
                  ...error
                },
          requestCache
        }
      }

      return {
        ...state,
        error:
          (error.uid &&
            requestCache[error.uid] &&
            requestCache[error.uid].status === 'CANCELLED') ||
          !state.loggedIn
            ? null
            : {
                ...state.error,
                ...error
              },
        requestCache
      }
    }

    case REQUEST_CANCELLED: {
      const requestCache = { ...state.requestCache }

      if (action.requestId && requestCache[action.requestId]) {
        delete requestCache[action.requestId]
      }

      return {
        ...state,
        requestCache
      }
    }
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case ROUTE_CHANGE:
      return {
        ...state,
        breadcrumbs: [...action.payload]
      }
    case TOGGLE_GLOBAL_SIDEBAR:
      return {
        ...state,
        sideBarIsOpen:
          action.payload !== undefined ? action.payload : !state.sideBarIsOpen
      }
    case TOGGLE_GLOBAL_FILTER_PANE:
      return {
        ...state,
        filterPaneIsOpen:
          action.payload !== undefined
            ? action.payload
            : !state.filterPaneIsOpen
      }
    case SET_DASHBOARD_HAS_FILTER_PANE:
      return {
        ...state,
        dashboardHasFilterPane: action.payload
      }
    case UNIVERSE_COMPONENT_PATHS_UPDATED:
      return {
        ...state,
        universeComponentActiveIndices: action.payload
      }
    case CATEGORIES_LIST_PATHS_UPDATED:
      return {
        ...state,
        categoriesActiveIndices: action.payload
      }
    case BRAND_BY_CATEGORY_LIST_PATHS_UPDATED:
      return {
        ...state,
        brandByCategoryActiveIndices: action.payload
      }
    case UNIVERSE_ENTITIES_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CATEGORIES_LOADING:
      return {
        ...state,
        categoriesLoading: action.payload
      }
    case BRAND_BY_CATEGORY_ERROR:
      return {
        ...state,
        brandByCategoryError: action.payload
      }
    case BRAND_BY_CATEGORY_LOADING:
      return {
        ...state,
        brandByCategoryLoading: action.payload
      }
    case CATEGORIES_ERROR:
      return {
        ...state,
        categoriesError: action.payload
      }
    case UPDATE_USER_PREVIOUS_PATH:
      return {
        ...state,
        userPreviousPath: action.payload
      }
    case SET_POLLING_TOAST:
      return {
        ...state,
        // have to check the case where the user changed routes (dashboardRequestCount is reset)
        // so we then want to catch here and not set true, but set to null
        isShownPollingToast:
          action.payload !== true
            ? action.payload
            : state.dashboardRequestCount > 0 && action.payload === true
            ? true
            : null
      }
    case SET_DASHBOARD_REQUEST_COUNT:
      // these operations are used by the Polling Timeout Toast
      // Per: https://app.clubhouse.io/fanai/story/5594/eliminate-polling-timeouts-show-this-is-taking-too-long-toast

      let currCount = state.dashboardRequestCount
      const operation = action.payload
      let toastState = state.isShownPollingToast

      if (operation === 'ADD') {
        currCount += 1
      } else if (operation === 'SUBTRACT') {
        currCount -= 1
      } else {
        // query was reset
        currCount = null
        toastState = null
      }

      // last response resolved, reset values to null
      if (currCount <= 0) {
        toastState = null
        currCount = null
      }

      return {
        ...state,
        dashboardRequestCount: currCount,
        isShownPollingToast: toastState
      }
    default:
      return state
  }
}
