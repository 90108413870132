import React, { useEffect } from 'react';
import useSelector from 'hooks/useSelector';
import { setDashboardHasFilterPane } from 'actions';
import { useDispatch } from 'react-redux';

export default function useMobileMenu() {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.appState.filterPaneIsOpen);

  // Enable the dashboard filter pane while the component is mounted
  useEffect(() => {
    dispatch(setDashboardHasFilterPane(true));
    return () => {
      dispatch(setDashboardHasFilterPane(false));
    };
  }, [dispatch]);
  return isOpen;
}
