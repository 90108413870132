import * as React from 'react';
import classNames from 'utils/classNames';
import './pulseLoader.css';

interface PulseLoadProps {
  style?: any;
  type?: string;
  className?: string;
}

const PulseLoad: React.SFC<PulseLoadProps> = ({
  style = {},
  type,
  className = '',
}) => {
  const classes = className
    .trim()
    .split(' ')
    .reduce((acc, name) => {
      if (name.trim()) {
        acc[name] = true;
      }
      return acc;
    }, {} as Record<string, boolean>);
  return (
    <div
      className={classNames({
        tileLoader: true,
        ...(type ? { [type]: true } : {}),
        ...classes,
      })}
      data-testid="pulseLoader"
      style={style}
    />
  );
};

export default PulseLoad;
