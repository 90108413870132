import localforage from 'localforage';
import { isOlderThan } from 'utils/dates';
import { createLogger } from 'logging';

const logger = createLogger({ name: 'RequestStore' });

interface CachedValue {
  data: any;
  requestId: string;
  timestamp: number;
}
class RequestStore {
  private store: LocalForage;
  private static DEFAULT_EXPIRY_TIME: number = 60 * 60 * 1000 * 6; // 6 hours;
  private static DEFAULT_ERROR_EXPIRY_TIME: number = 60 * 1000 * 5; // 5 min;
  private static NON_CACHEABLE_ERROR_CODES: number[] = [401, 408, 503];

  constructor(config?: LocalForageOptions) {
    this.store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      ...(config && { ...config }),
    });
  }

  public get(key: string): Promise<any> {
    return this.store.getItem(key);
  }

  public set(key: string, value: any): Promise<null | any> {
    if (
      (value.data.error || value.data.status) &&
      RequestStore.NON_CACHEABLE_ERROR_CODES.includes(value.data.status)
    ) {
      return Promise.reject('NON_CACHEABLE_ERROR_CODE');
    }
    return this.store.setItem(key, value);
  }

  public inValidateCache(): Promise<any> {
    return this.store
      .clear()
      .then(() => {
        // Run this code once the database has been entirely deleted.
        return null;
      })
      .catch(err => {
        // This code runs if there were any errors
        return err;
      });
  }

  public inValidateExpiredEntries(expiryTime?: number): Promise<any> {
    // filter out entries that have expired and clear them from the cache
    return this.filter(value => {
      const maxAge =
        expiryTime || value.data.error || value.data.status
          ? RequestStore.DEFAULT_ERROR_EXPIRY_TIME
          : RequestStore.DEFAULT_EXPIRY_TIME;
      if (
        isOlderThan(value.timestamp, maxAge) ||
        ((value.data.error || value.data.status) &&
          RequestStore.NON_CACHEABLE_ERROR_CODES.includes(value.data.status))
      ) {
        // store the key so we can clear
        return true;
      }
    })
      .then(values => {
        return this.clearEntries(values.map(v => v[0]));
      })
      .catch(err => {
        return err;
      });
  }

  public clearEntries = (keys: string[]) => {
    // iterate over keys and clear expired
    return keys.reduce((previousValue, nextValue) => {
      return previousValue.then(() => {
        return this.inValidateCacheAt(nextValue);
      });
    }, Promise.resolve());
  };

  public filter(
    cb: (value: CachedValue) => boolean,
  ): Promise<Array<[string, CachedValue]>> {
    const values: Array<[string, CachedValue]> = [];

    return this.store
      .iterate((value: CachedValue, key: string) => {
        if (cb(value)) {
          values.push([key, value]);
        }
      })
      .then(() => values);
  }

  public inValidateCacheAt(key: string): Promise<any> {
    return this.store
      .getItem(key)
      .then(value => {
        if (value === undefined || value === null) {
          return;
        }

        if (DEBUG_MODE) {
          console.groupCollapsed('invalidating cached entry');
          logger.log(value);
          console.groupEnd();
        }

        return this.store
          .removeItem(key)
          .then(() => {
            return null;
          })
          .catch(err => {
            return err;
          });
      })
      .catch(err => {
        return err;
      });
  }
}

export default RequestStore;
